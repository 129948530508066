import { Checkbox } from '@blueprintjs/core';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectOptions } from './utils';

const CheckListComponent = ({
  readOnly,
  value: currentValue,
  values: unparsedValues,
  fieldHelpers,
}) => {
  const { t } = useTranslation();
  const values = useMemo(() => selectOptions(unparsedValues), [unparsedValues]);
  return (
    <>
      {values.map(({ id, value }) => {
        const checked = !!get(currentValue, id);
        return (
          <Checkbox
            key={id}
            disabled={readOnly}
            checked={checked}
            onChange={async (args) => {
              const checked = args.target.checked;
              await fieldHelpers.setFieldValue(
                `${fieldHelpers.key}.${id}`,
                checked
              );
            }}
          >
            {t(value)}
          </Checkbox>
        );
      })}
    </>
  );
};

export default CheckListComponent;
