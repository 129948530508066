import permissions, { types } from '@hogwarts/permissions';
import {
  FederationDataProviderContainer,
  OrganisationDataProviderContainer,
} from '../../containers/settings/DataProvider';

import React from 'react';
import { Redirect } from 'react-router-dom';
import { Diagnostics } from '../../components';
import { SettingItem } from '../../components/Settings/SettingsLayout';
import { SettingCategory } from '../../components/Settings/SettingsMenu';
import ApiKey from '../../containers/apikey';
import AuditLog from '../../containers/auditLog';
import Policies from '../../containers/federationPolicies';
import FederationUsers from '../../containers/federationUsers';
import FederationUsers2 from '../../containers/federationUsers2';
import OrganisationGroups from '../../containers/organisationGroups';
import OrganisationList from '../../containers/organisationList';
import OrganisationUsers from '../../containers/organisationUsers';
import AttributeEditor from '../../containers/settings/AttributeEditor';
import CheckAccount from '../../containers/settings/CheckAccount';
import CheckInvoices from '../../containers/settings/CheckInvoices';
import CheckOrders from '../../containers/settings/CheckOrders';
import Mfa from '../../containers/settings/MFA';
import Referrals from '../../containers/settings/Referrals';
import FederationScheme from '../../containers/settings/Scheme/federation';
import OrganisationScheme from '../../containers/settings/Scheme/organisation';
import UserPreferences from '../../containers/settings/UserPreferences';
import UserSupport from '../../containers/settings/UserSupport';
import UserGroups from '../../containers/userGroups';
import UserNotifications from '../../containers/userNotifications';
import { isFeatureEnabled } from '../../hooks/useFeature';
import { FederationUsersPreferences } from '../federationUsers/preferences';
import { OrganisationUsersPreferences } from '../organisationUsers/preferences';
import BulkSyncSignInApp from './BulkSyncSignInApp';
import CheckConfig from './CheckConfig';
import CognitaSignoffEditor from './CognitaSignoffEditor';
import IncidentAttributeEditor from './IncidentAttributeEditor';
import IncidentsEnabler from './IncidentsEnabler';
import SignInApp from './SignInApp';
export const USER_CATEGORY = 'user';
export const ORGANISATION_CATEGORY = 'organisation';
export const FEDERATION_CATEGORY = 'federation';
export const INTEGRATION_CATEGORY = 'integrations';
export const SIA_CATEGORY = 'sia';

export interface SettingItem2 extends SettingItem {
  permission?: types.Permission | types.Permission[];
  visible?: boolean | (() => boolean);
  hideNoPermission?: boolean;
  resourceId?: string;
}

interface SettingCategoryExt extends SettingCategory {
  resourceId: string;
}

export const getCategories = (
  organisation: any,
  federation: any
): SettingCategoryExt[] =>
  [
    {
      key: USER_CATEGORY,
      label: 'User',
    },
    {
      key: ORGANISATION_CATEGORY,
      label: 'Organisation',
      resourceId: organisation.id,
    },
    federation && {
      key: FEDERATION_CATEGORY,
      label: 'Federation',
      resourceId: federation.id,
    },
    {
      key: SIA_CATEGORY,
      label: 'Sign In App',
      resourceId: organisation.id,
    },
    {
      key: INTEGRATION_CATEGORY,
      label: 'Add-ons',
      resourceId: organisation.id,
    },
  ].filter(Boolean);

export const itemsFactory = ({
  user,
  organisation,
  federation,
}: {
  user: any;
  organisation: any;
  federation?: any;
}): SettingItem2[] => [
  {
    key: 'notifications',
    category: USER_CATEGORY,
    label: 'Notifications',
    Component: UserNotifications,
  },
  {
    category: USER_CATEGORY,
    label: 'Preferences',
    key: 'preferences',
    Component: UserPreferences,
  },
  {
    category: USER_CATEGORY,
    label: 'API Key',
    key: 'apikey',
    permission: permissions.USER_API_KEY,
    hideNoPermission: true,
    Component: ApiKey,
  },
  {
    category: USER_CATEGORY,
    label: 'Security',
    key: 'mfa',
    visible: () => isFeatureEnabled('security.mfa', organisation),
    Component: Mfa,
  },
  {
    category: USER_CATEGORY,
    label: 'Referral Program',
    key: 'referrals',
    visible: () => isFeatureEnabled('referrals', organisation),
    Component: Referrals,
  },
  {
    category: USER_CATEGORY,
    label: 'Support',
    key: 'support',
    Component: UserSupport,
  },

  {
    category: ORGANISATION_CATEGORY,
    label: 'Users',
    key: 'users',
    permission: [
      permissions.USER_READ,
      permissions.USER_ROLE_CREATE,
      permissions.USER_ROLE_UPDATE,
      permissions.USER_ROLE_DELETE,
    ],
    Component: [OrganisationUsers, OrganisationUsersPreferences],
  },
  // {
  //   category: ORGANISATION_CATEGORY,
  //   label: 'Properties',
  //   key: 'properties',
  //   permission: permissions.ATTRIBUTE_UPDATE,
  //   hideNoPermission: true,
  //   Component: (props) => <OrganisationPropertiesEditor {...props} />,
  // },
  {
    category: ORGANISATION_CATEGORY,
    label: 'Audit Log',
    key: 'auditlog',
    permission: [permissions.AUDITLOG_READ],
    Component: AuditLog,
  },
  {
    category: ORGANISATION_CATEGORY,
    label: 'MIS / Data Import',
    key: 'orgprovider',
    permission: [permissions.PROVIDER_CONFIG_UPDATE],
    visible: () => isFeatureEnabled('providers.organisation', organisation),
    Component: OrganisationDataProviderContainer,
  },

  {
    category: ORGANISATION_CATEGORY,
    label: 'Bulk Import',
    key: 'import',
    permission: [
      permissions.BULK_IMPORT_READ,
      permissions.BULK_IMPORT_UPDATE,
      permissions.BULK_IMPORT_DELETE_ALL,
      permissions.BULK_IMPORT_EXECUTE,
    ],
    visible: () => isFeatureEnabled('functions.bulkimporter', organisation),
    Component: ({ organisation }: any) => (
      // TODO: Show a panel with a button that takes you there!

      <Redirect to={`/${organisation?.key}/import`} />
    ),
  },

  {
    category: ORGANISATION_CATEGORY,
    label: 'Attributes',
    key: 'attributes',
    permission: [permissions.ATTRIBUTE_UPDATE],
    visible: () => isFeatureEnabled('functions.attributeeditor', organisation),
    Component: AttributeEditor,
  },

  {
    category: ORGANISATION_CATEGORY,
    label: 'Templates',
    key: 'templates',
    permission: [permissions.SCHEME_DRAFT_UPDATE, permissions.SCHEME_PUBLISH],
    Component: OrganisationScheme,
  },
  {
    category: ORGANISATION_CATEGORY,
    label: 'Bulk Profile Images',
    key: 'bulkprofileimages',
    permission: [
      permissions.PROFILE_FILE_LIST,
      permissions.PROFILE_FILE_CREATE,
    ],
    Component: ({ organisation }: any) => (
      <Redirect to={`/${organisation?.key}/bulkprofileimages`} />
    ),
    visible: () =>
      isFeatureEnabled('functions.bulkprofileimages', organisation),
  },
  {
    category: ORGANISATION_CATEGORY,
    label: 'Diagnostics',
    key: 'diagnostics',
    permission: [permissions.DIAGNOSTIC_VIEW],
    hideNoPermission: true,
    // @ts-ignore
    Component: (props) => <Diagnostics item={props.organisation} />,
  },

  {
    category: FEDERATION_CATEGORY,
    label: 'Users',
    key: 'federationusers',
    permission: [
      permissions.FEDERATION_USER_CREATE,
      permissions.FEDERATION_USER_UPDATE,
      permissions.FEDERATION_USER_DELETE,
    ],
    visible: () => !!federation,
    Component: [FederationUsers, FederationUsersPreferences],
  },

  {
    category: FEDERATION_CATEGORY,
    label: 'Users (Beta)',
    key: 'federationusers2',
    permission: [
      permissions.FEDERATION_USER_CREATE,
      permissions.FEDERATION_USER_UPDATE,
      permissions.FEDERATION_USER_DELETE,
    ],
    visible: () => {
      return (
        !!federation &&
        isFeatureEnabled('security.federationusers2', organisation)
      );
    },
    Component: FederationUsers2,
  },

  {
    category: FEDERATION_CATEGORY,
    label: 'Organisations',
    key: 'organisations',
    permission: [permissions.ADMIN],
    resourceId: federation?.id,
    visible: !!federation,
    Component: OrganisationList,
  },
  {
    category: FEDERATION_CATEGORY,
    label: 'User Groups',
    key: 'usergroups',
    permission: [
      permissions.FEDERATION_USER_GROUP_CREATE,
      permissions.FEDERATION_USER_GROUP_UPDATE,
      permissions.FEDERATION_USER_GROUP_DELETE,
    ],
    visible: !!federation,
    Component: UserGroups,
  },
  {
    category: FEDERATION_CATEGORY,
    label: 'Organisation Groups',
    key: 'groups',
    permission: [
      permissions.FEDERATION_ORGANISATION_GROUP_CREATE,
      permissions.FEDERATION_ORGANISATION_GROUP_UPDATE,
      permissions.FEDERATION_ORGANISATION_GROUP_DELETE,
    ],
    visible: !!federation,
    Component: OrganisationGroups,
  },
  {
    category: FEDERATION_CATEGORY,
    label: 'Group Permissions',
    key: 'permissions',
    permission: permissions.FEDERATION_ASSIGN_GROUP_PERMISSIONS,
    visible: !!federation,
    Component: Policies,
  },

  {
    category: FEDERATION_CATEGORY,
    label: 'MIS / Data Import',
    key: 'fedprovider',
    permission: [permissions.PROVIDER_CONFIG_UPDATE],
    resourceId: federation?.id,
    visible: () => isFeatureEnabled('providers.federation', organisation),
    Component: FederationDataProviderContainer,
  },

  {
    category: FEDERATION_CATEGORY,
    label: 'Templates',
    key: 'templates',
    permission: [permissions.SCHEME_DRAFT_UPDATE, permissions.SCHEME_PUBLISH],
    Component: FederationScheme,
  },

  {
    category: INTEGRATION_CATEGORY,
    label: 'Risk Assessment',
    key: 'rasignoffs',
    permission: [
      permissions.COGNITA_RA_SIGNOFF,
      permissions.FEDERATION_USER_READ,
    ],
    resourceId: federation?.id,
    visible: () => isFeatureEnabled('cognita.ra_signoff', organisation),
    Component: CognitaSignoffEditor,
  },

  {
    category: INTEGRATION_CATEGORY,
    label: 'Verifile',
    key: 'verifile',
    permission: [permissions.CHECK_ORDER_BILLING],
    // resourceId: organisation?.id,
    visible: () => isFeatureEnabled('checks', organisation),
    Component: [CheckAccount, CheckOrders, CheckInvoices, CheckConfig],
  },
  {
    category: INTEGRATION_CATEGORY,
    label: 'Incident Tracking',
    key: 'incident_tracking',
    visible: () =>
      isFeatureEnabled('timeline.incident_tracking_visible', federation) ||
      isFeatureEnabled('timeline.incident_tracking_visible', organisation),
    Component: [IncidentsEnabler, IncidentAttributeEditor],
  },
  {
    category: SIA_CATEGORY,
    label: 'Configuration',
    key: 'config',
    permission: [permissions.SIA_CONFIGURE],
    visible: () => {
      return isFeatureEnabled('integrations.sia', organisation);
    },
    Component: [SignInApp],
  },
  {
    category: SIA_CATEGORY,
    label: 'Sign In App Upload',
    key: 'bulkpush',
    permission: [permissions.SIA_CONFIGURE],
    visible: () => {
      return (
        isFeatureEnabled('integrations.sia', organisation) &&
        isFeatureEnabled('integrations.sia_bulkpush', organisation) &&
        organisation.siaConnection
      );
    },
    Component: [BulkSyncSignInApp],
  },
];
