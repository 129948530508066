import { Field, Section } from '@hogwarts/utils-schemes';

const RULE_KEY = 'late_date_check';

interface ProfileSectionField extends Section {
  inUse?: boolean;
}

interface ProfileField extends Field {
  inUse?: boolean;
  sectionItem?: ProfileSectionField;
}

interface SchemeProfile {
  fields: ProfileField[];
}

const getFieldLateRule = (field: Field) => {
  if (field.dataType === 'date' && field.validation) {
    for (const ruleKey of Object.keys(field.validation)) {
      const rule = field.validation[ruleKey];
      if (rule?.rule === RULE_KEY) {
        return rule;
      }
    }
  }
  return null;
};

export const getLateFields = (scheme: SchemeProfile) => {
  let fields = [];
  for (const field of scheme.fields) {
    if (!field.inUse || !field.sectionItem?.inUse) {
      continue;
    }
    const rule = getFieldLateRule(field);

    if (rule) {
      fields.push({
        key: field.key,
      });
    }
  }
  return fields;
};
