import {
  ConditionGroup,
  evaluateGroup as evaluateGroupCore,
  Options,
  Variables,
  VariableValues,
} from '@hogwarts/conditionals';
import { assertArgs, cleanObject } from '@hogwarts/utils';
import log from '@scrtracker/logging';
import * as pluggableMaps from './mappers';

export interface DataPack {
  values: Record<string, any>;
  visible: Record<string, boolean>;
  valid: Record<string, boolean>;
}

const executeVariableMapper = (
  variables: Variables,
  data: DataPack,
  options: Options
) => {
  let result = {};

  for (const key of Object.keys(variables || {})) {
    const variable = variables[key];
    let source;
    if (typeof variable !== 'string') {
      source = variable.source;
      if (source) {
        const [sourceType, item] = source;
        const mapper = pluggableMaps[sourceType];
        if (!mapper) {
          return [false, `Unknown map type [${sourceType}]`];
        }
        const [success, value] = mapper(item, data, options);
        if (!success) {
          return [false, value];
        }
        result[key] = value;
      }
    }
  }

  return [true, result];
};

export function evaluateGroup(
  group: ConditionGroup,
  variableValues: VariableValues,
  data: DataPack,
  options: Options
): boolean {
  assertArgs({ group, variableValues, data, options });

  const { enabled, conditions, variables } = group;

  if (enabled === false) {
    return true;
  }

  if (!conditions || (Array.isArray(conditions) && conditions.length === 0)) {
    return true;
  }

  const [success, values] = executeVariableMapper(variables, data, options);

  if (!success) {
    return null;
  }

  try {
    return evaluateGroupCore(
      group,
      {
        ...values,
        ...cleanObject(variableValues),
      },
      options
    );
  } catch (e) {
    log.error('Error', e);
    return null;
  }
}
