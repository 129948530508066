import React, { useMemo } from 'react';
import {
  sortableContainer,
  SortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import styles from './styles.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@blueprintjs/core';

const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

const arrayMove = (array, from, to) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

const DragHandle = sortableHandle(() => (
  <div className={styles.dragHandle}>
    <FontAwesomeIcon className={styles.dragHandleIcon} icon="grip-vertical" />
  </div>
));

const SortableItemFactory = (Component) =>
  SortableElement(({ componentProps }) => {
    return (
      <Card className={'d-flex flex-row m-1 p-2'}>
        <DragHandle />
        <div className={'flex-grow-1 pl-3'}>
          <Component {...componentProps} />
        </div>
      </Card>
    );
  });

const SortableContainer = sortableContainer(({ className, children }) => {
  return <div className={className}>{children}</div>;
});

export const ReorderableList = ({
  Component,
  items,
  onChanged,
  componentProps,
  className,
  containerClassName,
  disabled,
}) => {
  const SortableItem = useMemo(
    () => SortableItemFactory(Component),
    [Component]
  );

  // const [items, setItems] = useState(initialItems);

  const updateItems = (updatedItems) => {
    // setItems(updatedItems);
    if (onChanged) {
      onChanged(updatedItems);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedItems = arrayMove(items, oldIndex, newIndex);
    updateItems(updatedItems);
  };

  const onDeleted = (index) => () => {
    const updatedItems = [...items];
    updatedItems[index] = null;
    updateItems(updatedItems.filter(Boolean));
  };

  return (
    <div className={containerClassName}>
      <SortableContainer
        className={className}
        containerClassName={containerClassName}
        helperClass={styles.sortableHelper}
        useDragHandle
        axis="y"
        lockAxis="y"
        onSortEnd={onSortEnd}
      >
        {items &&
          items.map((item, index) => {
            return (
              <SortableItem
                componentProps={{
                  ...componentProps,
                  onDelete: onDeleted(index),
                  item,
                  disabled,
                }}
                key={item.id}
                index={index}
                disabled={disabled || item.disableSort}
              />
            );
          })}
      </SortableContainer>
    </div>
  );
};
