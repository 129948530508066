const levels = {
  trace: 50,
  debug: 40,
  info: 30,
  warn: 20,
  error: 10,
  fatal: 0,
};

export default levels;
