export default {
  key: 'riskAssessmentClosed2',
  label: 'Determines if the Risk Assessment is Closed or not',
  output: 'boolean',
  fields: [
    {
      key: 'prefix',
      label: 'Field prefix',
      type: 'string',
    },
  ],
  execute: (
    { data }: { data: Record<string, any> },
    params: { prefix: string; requiredCount: number }
  ) => {
    let { prefix: fprefix } = params;
    if (!fprefix) fprefix = 'ra2_';

    let requiredCount = data[`${fprefix}signoff_count`];
    if (!requiredCount || requiredCount < 2) {
      requiredCount = 2;
    }

    const check = (prefix: string, person: string) => {
      if (
        data[`${fprefix}${prefix}_${person}_confirm`] &&
        data[`${fprefix}${prefix}_${person}_confirm_name`] &&
        data[`${fprefix}${prefix}_${person}_confirm_date`]
      ) {
        return true;
      }
      return false;
    };

    const ra_required = data[`${fprefix}required`];

    if (!ra_required) {
      return false;
    }

    const ra_type = data[`${fprefix}type`];
    const ra_user_closed = data[`${fprefix}user_closed`];

    switch (ra_type || '') {
      case 'Disclosure': {
        return check('disclosure', '1') && check('disclosure', '2');
      }
      default: {
        switch (ra_user_closed) {
          case 'yes': {
            const value = check('signoff', '1');
            return value;
          }
          case 'yes3': {
            let signOffCount = 0;
            for (let i = 0; i < requiredCount; i++) {
              if (check('multi', `${i + 1}`)) {
                signOffCount++;
              }
            }
            return signOffCount >= requiredCount;
          }
          default: {
            // its still open!
            return false;
          }
        }
      }
    }
  },
};
