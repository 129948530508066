import { parseDate } from '@hogwarts/validation';

export default {
  key: 'riskAssessmentDate',
  label: 'Creates a new date by getting the max of the other dates',
  output: 'date',
  fields: [
    {
      key: 'durationType',
      label: 'Duration Unit',
      type: 'select',
      values: ['days', 'weeks', 'months', 'years'],
    },
    {
      key: 'duration',
      label: 'Duration',
      type: 'number',
    },
    {
      key: 'prefix',
      label: 'Field prefix',
      type: 'string',
    },
  ],
  execute: (
    { data }: { data: Record<string, any> },
    params: { prefix: string; durationType: string; duration: number }
  ) => {
    const { prefix } = params;
    if (!prefix) return null;

    const dates = [
      ...['you', '1', '2', '3', '4', '5', '6'].map(
        (title) => data[`${prefix}multi_open_${title}_confirm_date`]
      ),
      ...(data[`${prefix}reviews`] || [])
        .filter(Boolean)
        .map((r) => r[`${prefix}review_1_confirm_date`]),
    ];

    let nextReviewDate = dates.reduce((latest, cur) => {
      if (!cur) return latest;
      let date = parseDate(cur);
      if (!date.isValid) {
        return latest;
      }

      date = date.plus({ [params.durationType]: params.duration });
      // date = date.plus({ days: 14 });

      if (latest == null) {
        return date;
      }

      if (date > latest) {
        return date;
      }
      return latest;
    }, null);

    return nextReviewDate && nextReviewDate.toISODate();
  },
};
