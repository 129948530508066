import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@blueprintjs/core';
import { parseBool } from '@hogwarts/validation';

const CheckBox = ({
  fieldHelpers,
  value: rawValue,
  fieldMeta,
  readOnly,
  label,
}) => {
  const { t } = useTranslation();
  const value = parseBool(rawValue);
  if (fieldMeta.error) {
    // show this in red?
  }
  return (
    <Checkbox
      disabled={readOnly}
      checked={!!value}
      onChange={async (args) => {
        const checked = args.target.checked;
        fieldHelpers.setValue(checked);
      }}
      label={t(label)}
    />
  );
};

export default CheckBox;
