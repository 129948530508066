import { calculateProfileRatings } from './calculateProfileRatings';
import { calculateProfileSchemeValidity } from './calculateProfileSchemeValidity';

const combineSchemeValidity = (scheme, validity, meta) => {
  const { values, valid, visible, reasons, errors } = validity;

  const sections = [];
  const fields = [];

  const sectionsKeyed = {};
  const fieldsKeyed = {};

  for (const section of scheme.sections) {
    let updatedSection = {
      ...section,
      fields: [],
      visible: visible[section.key],
      errors: errors[section.key],
    };
    sections.push(updatedSection);
    sectionsKeyed[section.key] = updatedSection;

    for (const field of section.fields) {
      const fieldMeta = meta[field.key];

      let updatedField = {
        ...field,
        sectionItem: updatedSection,
        value: values[field.key],
        valid: valid[field.key],
        reasons: reasons[field.key] || [],
        visible: visible[field.key],
        errors: errors[field.key],
      };

      if (field.dataType === 'array') {
        let childDataArray = values[field.key];

        const arrayFields = [];
        for (const child of field.arrayFields || []) {
          const childValues = [];
          const childMetas = [];
          if (childDataArray && childDataArray.length) {
            for (let n = 0; n < childDataArray.length; n++) {
              childValues.push(childDataArray[n][child.key]);
              if (Array.isArray(fieldMeta) && n < fieldMeta.length) {
                childMetas.push(fieldMeta[n][child.key]);
              }
            }
          }

          const arrayField = {
            ...child,
            values: childValues,
            fieldMetas: childMetas,
            valid: valid[child.key],
            reasons: reasons[child.key] || [],
            visible: visible[child.key],
            errors: errors[child.key],
          };

          arrayFields.push(arrayField);
          fieldsKeyed[arrayField.key] = arrayField;
        }

        updatedField.arrayFields = arrayFields;
      } else {
        updatedField.fieldMeta = fieldMeta;
      }

      updatedSection.fields.push(updatedField);
      fields.push(updatedField);
      fieldsKeyed[field.key] = updatedField;
    }
  }

  // This purposely overwrites the Section and Fields arrays losing the link to the underlying object.
  // This is ok because the section/field now stores the profile data and modifying the underlying
  // scheme could easily result in data being cross-contaminated in the 'organisation.scheme'

  return Object.assign({}, scheme, {
    sections,
    fields,
    // getSection: (key) => {
    //   if (!key) return null;
    //   return sectionsKeyed[key];
    // },
    // getField: (key) => {
    //   if (!key) return null;
    //   return fieldsKeyed[key];
    // },
  });
};

const combineSchemeRatings = (scheme, ratings) => {
  const sections = [];
  for (const section of scheme.sections) {
    sections.push({
      ...section,
      ratings: ratings[section.key],
    });
  }

  return Object.assign({}, scheme, {
    sections,
  });
};

export function calculateProfileScheme(scheme, profile, timezone) {
  const validity = calculateProfileSchemeValidity(scheme, profile, timezone);

  let profileScheme = {
    ...combineSchemeValidity(scheme, validity, profile.meta),
    switchProfileType() {
      throw new Error('Cannot switchProfileType once Profile Applied');
    },
    applyProfileMeta() {
      throw new Error('Cannot applyProfileMeta once Profile Applied');
    },
  };

  const [profileRating, sectionRatings] = calculateProfileRatings(
    validity,
    profileScheme,
    timezone
  );

  profileScheme = combineSchemeRatings(profileScheme, sectionRatings);

  return [profileScheme, validity, profileRating, sectionRatings];
}
