import { Button, Popover, PopoverPosition } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

interface DeletePopoverProps {
  defaultOpen?: boolean;
  buttonComponent: any;
  position?: PopoverPosition;
  message?: string;
  cancelText?: string;
  removeText?: string;
  onRemove?: () => void;
  disabled?: boolean;
}
const DeletePopover = ({
  defaultOpen = false,
  buttonComponent,
  position = 'bottom',
  message,
  cancelText,
  removeText,
  onRemove,
  disabled,
}: DeletePopoverProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <Popover
      style={{ width: '100%' }}
      isOpen={!disabled && isOpen}
      onClose={() => setIsOpen(false)}
      position={position}
    >
      {buttonComponent ? (
        React.cloneElement(buttonComponent, {
          disabled,
          onClick: () => setIsOpen(true),
        })
      ) : (
        <Button
          disabled={disabled}
          icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />}
          intent="danger"
          onClick={() => setIsOpen(true)}
        />
      )}
      <div className={styles.deleteAlert}>
        <p>{t(message ?? 'Are you sure you want to do this?')}</p>
        <div className={styles.deleteAlertButtons}>
          <Button onClick={() => setIsOpen(false)}>
            {t(cancelText ?? 'Cancel')}
          </Button>
          <Button
            intent="danger"
            onClick={() => {
              onRemove();
              setIsOpen(false);
            }}
          >
            {t(removeText ?? 'Remove')}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default DeletePopover;
