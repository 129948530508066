import { DateTime } from 'luxon';

export default {
  key: 'isBefore',
  label:
    'Compares two dates and returns true if the right value is greater than the left value',
  output: 'date',
  fields: [
    {
      key: 'left',
      label: 'Left value',
      type: 'field',
    },
    {
      key: 'right',
      label: 'Right value',
      type: 'field',
    },
  ],
  execute: ({ data }, params) => {
    const { left, right } = params;

    let date1 = DateTime.fromISO(data[left]);
    if (!date1.isValid) {
      return false;
    }

    let date2 = DateTime.fromISO(data[right]);
    if (!date2.isValid) {
      return false;
    }

    return date1 < date2;
  },
};
