import React, { useState } from 'react';
import { Label, InputGroup, Button, Callout } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const PasswordResetRequestPage = ({
  resetError,
  onResetPasswordSubmitted,
  onResetPasswordComplete,
}) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  if (!submitted || resetError) {
    return (
      <>
        {resetError ? (
          <Callout
            className={styles.errorMessage}
            intent="danger"
            title="Error Resetting Password"
          >
            {t(
              'We were unable to reset your password, please try again. If this continues to happen please reach out to help@scrtracker.com.'
            )}
          </Callout>
        ) : (
          <p className={styles.headerMessage}>{t('Reset Password')}</p>
        )}
        <p className={styles.secondaryMessage}>
          {t(
            "Enter your email address below and we'll send you an email with a link to reset your password."
          )}
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitted(true);
            onResetPasswordSubmitted(email);
          }}
        >
          <Label>
            <p className={styles.formLabel}>{t('Email Address')}</p>
            <InputGroup
              type="email"
              large
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Label>
          <div className={styles.rightButton}>
            <Button
              className="mr-1"
              large
              onClick={() => onResetPasswordComplete()}
            >
              {t('Go Back')}
            </Button>
            <Button type="submit" large intent="primary" disabled={!email}>
              {t('Reset Password')}
            </Button>
          </div>
        </form>
      </>
    );
  } else {
    return (
      <>
        <p className={styles.headerMessage}>{t('Reset Email Sent')}</p>
        <p className={styles.secondaryMessage}>
          {t(
            "Your password reset email is on the way. If you haven't received an email in a few minutes then please check your spam folder."
          )}
        </p>
        <div className={styles.rightButton}>
          <Button
            large
            intent="primary"
            onClick={() => onResetPasswordComplete()}
          >
            {t('Go Back')}
          </Button>
        </div>
      </>
    );
  }
};

export default PasswordResetRequestPage;
