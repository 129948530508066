import { Error, Loading } from '@hogwarts/ui-components-core';
import React, { useEffect, useState } from 'react';
import { ConfigContext } from './context';
import { request } from './services';

const Config = ({ children, onConfigLoad }) => {
  const [error, setError] = useState(null);
  const [currentKnownConfig, setKnownConfig] = useState(null);

  useEffect(() => {
    request(`/system/webconfig`, {
      requireAuth: false,
      retries: 3,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const ok = response.status >= 200 && response.status < 300;
        if (!ok) {
          setKnownConfig(null);
          setError(true);
          return;
        }

        let config = response.data;
        if (onConfigLoad) {
          try {
            const modified = onConfigLoad(config);
            if (typeof modified !== 'undefined') {
              config = modified;
            }
          } catch (e) {
            console.error(e);
          }
        }
        setKnownConfig(config);
      })
      .catch((ex) => {
        setError(ex);
      });
  }, [onConfigLoad]);

  if (error) {
    // Could be no internet connection
    return (
      <Error
        icon="error"
        title={'Error loading the configuration.'}
        description={
          'Please check your internet connection and refresh your browser.'
        }
      />
    );
  }

  if (!currentKnownConfig) {
    return <Loading showLogo={true} />;
  }

  return (
    <ConfigContext.Provider value={currentKnownConfig}>
      {children}
    </ConfigContext.Provider>
  );
};

export default Config;
