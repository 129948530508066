export const checkSchemeType = (
  scheme: any,
  versionCheck: boolean = true
): void => {
  if (!scheme) {
    throw new Error('Scheme cannot be null');
  }
  if (!scheme.id) {
    throw new Error('Scheme must have an Id');
  }
  if (versionCheck && !scheme.version)
    throw new Error('Scheme must have a Version');
  if (!scheme.data)
    throw new Error(
      `Scheme [${scheme.id} ${scheme.name}] must have a data property`
    );
};
