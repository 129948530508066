import cn from 'classnames';
import React from 'react';
import styles from './styles.module.css';

const DEFAULT_COLOR = 'blue';
const DEFAULT_TEXT_COLOR = 'white';

interface ProgressBarProps {
  className?: string;
  color?: string;
  textColor?: string;
  round?: boolean;
  style?: React.CSSProperties;
  animated?: boolean;
  border?: boolean;
  value: number;
  minimum?: number;
  children?: React.ReactNode;
}
const ProgressBar = ({
  className,
  color = DEFAULT_COLOR,
  textColor = DEFAULT_TEXT_COLOR,
  round,
  style,
  animated,
  border = true,
  value: initialValue = 0,
  minimum = 10,
  children,
}: ProgressBarProps) => {
  const value = Math.max(0, Math.min(Math.trunc(initialValue), 100));

  const displayText = `${value}%`;

  return (
    <div
      style={style}
      className={cn(styles.progressBarContainer, className, {
        [styles.progressBarContainerBorder]: border,
        [styles.rounded]: round,
      })}
    >
      <div
        className={cn(styles.progressBar, 'progress-bar', {
          'progress-bar-animated': animated,
          'progress-bar-striped': animated,
        })}
        style={{
          backgroundColor: color,
          width: `${value < minimum ? minimum : value}%`,
        }}
      >
        {children ? (
          children
        ) : (
          <span
            style={{
              color: textColor,
            }}
          >
            {displayText}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
