import { InputGroup } from '@blueprintjs/core';
import { useDebounce } from '@hogwarts/ui-components-core';
import React, { useState } from 'react';
import { getErrorIntent } from './utils';

const DEFAULT_DEBOUNCE_TIME = 250;

const TextComponent = ({
  fieldKey,
  readOnly,
  placeHolder,
  password,
  value: controlledValue,
  fieldMeta,
  fieldHelpers,
}) => {
  const [value, setValue] = useState(false);
  let showValue = value === false ? controlledValue : value;
  if (showValue == null) showValue = '';

  const onChangeHandler = useDebounce((value) => {
    fieldHelpers.setValue(value);
    setValue(false);
  }, DEFAULT_DEBOUNCE_TIME);

  const intent = getErrorIntent(fieldMeta);

  return (
    <InputGroup
      id={fieldKey}
      intent={intent}
      onBlur={(event) => {
        let value = event.target.value;
        if (value !== null) {
          fieldHelpers.setValue(value);
          setValue(false);
        }
      }}
      onChange={(event) => {
        let value = event.target.value;
        if (value === '') value = null;
        onChangeHandler(value);
        setValue(value);
      }}
      placeholder={placeHolder}
      value={showValue}
      disabled={readOnly}
      type={password ? 'password' : 'text'}
    />
  );
};

export default TextComponent;
