import { assertArgs } from '@hogwarts/utils';
import { evaluateGroup } from './evaluateGroup';

// TODO: At the moment this only understands grouping.

export const executeQuery = (query, profiles, conditionData, options) => {
  assertArgs({ query, profiles, conditionData, options });
  const groups = [];
  const items = [...profiles];
  for (const group of query.groups) {
    const groupItems = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (!item) continue;
      let pass = true;
      for (const condition of group.conditions) {
        const result = evaluateGroup(
          {
            variables: {
              ...query.variables,
              ...group.variables,
            },
            conditions: [
              {
                ...query.defaults,
                ...condition,
              },
            ],
          },
          {},
          {
            ...conditionData,
            values: item.data,
            visible: item.visible,
            item,
          },
          options
        );
        if (!result) {
          pass = false;
          break;
        }
      }
      if (pass) {
        if (query.settings.singleGroup) {
          items[i] = false;
        }
        groupItems.push(item);
      }
    }
    if (!group.hidden) {
      groups.push({
        key: group.key,
        label: group.label,
        items: groupItems,
      });
    }
  }

  return {
    groups,
  };
};
