import logMain, { Logger } from '@scrtracker/logging';

export const progressFactory = ({
  count,
  label,
  granularity = 1,
  log: customlog,
}: {
  count: number;
  label: string;
  granularity?: number;
  log?: Logger;
}) => {
  let lastKnownValue = 0;
  let lastReported = 0;

  const log: Logger = customlog || logMain;

  const updateValue = (value: number) => {
    let currentProgress = Math.trunc((lastReported / count) * 100);
    let updatedProgress = Math.trunc((value / count) * 100);

    if (updatedProgress - currentProgress >= granularity) {
      log.info(`${label} - [${updatedProgress}%]`);
      lastReported = value;
    }

    lastKnownValue = value;
  };

  return {
    set(value: number) {
      updateValue(value);
    },
    inc() {
      updateValue(lastKnownValue + 1);
    },
    complete() {
      log.info(`${label} - [Complete]`);
    },
    get value() {
      return lastReported;
    },
  };
};
