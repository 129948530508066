import { evaluateGroup } from '../conditionals';
import { initializeSystemsResultBlock } from './utils';

export function calculateProfileRatings(data, scheme, timezone) {
  const profileRating = initializeSystemsResultBlock(scheme.ratingSystems);

  const sections = {};
  for (const section of scheme.sections) {
    if (!section.visible) {
      continue;
    }

    let sectionResult = initializeSystemsResultBlock(scheme.ratingSystems);
    sections[section.key] = sectionResult;

    for (const ratingSystem of scheme.ratingSystems) {
      const system = sectionResult[ratingSystem.key];
      if (!system) {
        throw new Error(`No section result for [${ratingSystem.key}]`);
      }

      for (const field of section.fields) {
        if (!field.visible) {
          continue;
        }
        if (field.dataType === 'none') {
          continue;
        }
        let fieldRating = field.ratings && field.ratings[ratingSystem.key];
        if (!fieldRating?.enabled) {
          fieldRating = null;
        }

        if (fieldRating?.condition) {
          let conditionallyEnabled = evaluateGroup(
            fieldRating.condition,
            {},
            data,
            {
              timezone,
            }
          );

          system.add(!!conditionallyEnabled, field.valid);
        } else {
          system.add(!!fieldRating, field.valid);
        }

        if (Array.isArray(field.arrayFields)) {
          for (const child of field.arrayFields) {
            if (child.dataType === 'none') {
              continue;
            }

            let fieldRating = child.ratings && child.ratings[ratingSystem.key];
            if (!fieldRating?.enabled) {
              fieldRating = null;
            }

            for (let n = 0; n < child.valid.length; n++) {
              if (!child.visible[n]) {
                continue;
              }

              if (fieldRating?.condition) {
                let childData = {
                  ...data,
                };
                for (const datakey of ['valid', 'visible']) {
                  const childFieldItem = data[datakey][child.key][n];
                  // const childFieldItem = get(
                  //   `${datakey}.${field.key}[${n}]${child.key}`,
                  // );
                  childData[datakey] = {
                    ...data[datakey],
                    [child.key]: childFieldItem,
                  };
                }

                let conditionallyEnabled = evaluateGroup(
                  fieldRating.condition,
                  {},
                  childData,
                  {
                    timezone,
                  }
                );

                system.add(!!conditionallyEnabled, child.valid[n]);
              } else {
                system.add(!!fieldRating, child.valid[n]);
              }
            }
          }
        }
      }

      // accumulate the score for the profile
      profileRating[system.systemKey].append(sectionResult[system.systemKey]);
    }
  }
  return [profileRating, sections];
}
