import createPermission from './create';
import resourceTypes from './resourceTypes';

const create = (key: string, description: string) =>
  createPermission(key, description, [
    resourceTypes.ORGANISATION,
    resourceTypes.FEDERATION,
  ]);

const createSystem = (key: string, description: string) =>
  createPermission(key, description, [
    resourceTypes.SYSTEM,
    resourceTypes.ORGANISATION,
    resourceTypes.FEDERATION,
  ]);

const permissions = {
  // This will be automatically included into any Administrator Roles
  // It should never be added to any roles directly
  ADMIN: create('ADMIN', 'Administrator Access'),

  READ: create('READ', 'Allow Read Access'),

  BUILD_CHECK_INVOICES: create(
    'BUILD_CHECK_INVOICES',
    'Build Check Invoices for Check Accounts'
  ),

  ATTRIBUTE_UPDATE: create(
    'ATTRIBUTE_UPDATE',
    'Allows Updating of Attribute values'
  ),

  PROVIDER_CONFIG_UPDATE: create(
    'PROVIDER_CONFIG_UPDATE',
    'Allow updating of Data Provider Configuration'
  ),
  PROVIDER_IMPORT: create('PROVIDER_IMPORT', 'Allow Queuing of an Import'),

  WORKFLOW_CREATE: createSystem('WORKFLOW_CREATE', 'Can create a new Workflow'),
  WORKFLOW_UPDATE: createSystem(
    'WORKFLOW_UPDATE',
    'Allow updating of a Workflow'
  ),
  WORKFLOW_DELETE: createSystem(
    'WORKFLOW_DELETE',
    'Allow deleting of a Workflow'
  ),

  // Insights
  INSIGHTS_READ: create(
    'INSIGHTS_READ',
    'Allow access to Insights information'
  ),

  // Reports
  REPORT_CREATE: create('REPORT_CREATE', 'Allows creation of reports'),
  REPORT_UPDATE: create('REPORT_UPDATE', 'Allows editing of reports'),
  REPORT_DELETE: create('REPORT_DELETE', 'Allows deletion of reports'),

  // Dashboards
  DASHBOARD_CREATE: create('DASHBOARD_CREATE', 'Allows creation of dashboards'),
  DASHBOARD_UPDATE: create('DASHBOARD_UPDATE', 'Allows editing of dashboards'),
  DASHBOARD_DELETE: create('DASHBOARD_DELETE', 'Allow deletion of dashboards'),

  // Schemes
  SCHEME_DRAFT_UPDATE: create(
    'SCHEME_DRAFT_UPDATE',
    'Allow Editing of a draft Scheme'
  ),
  SCHEME_PUBLISH: create(
    'SCHEME_PUBLISH',
    'Allow Publishing of a draft Scheme'
  ),

  // Verifile checks
  CHECK_ORDER_BILLING: create(
    'CHECK_ORDER_BILLING',
    'Allow viewing of check billing'
  ),
  TOGGLE_CHECK_ACCOUNT_PREFERENCES: create(
    'TOGGLE_CHECK_ACCOUNT_PREFERENCES',
    'Allow toggling of check account preferences'
  ),

  SIA_READ: create('SIA_READ', 'Allow SIA reads via the API'),
  SIA_CONFIGURE: create(
    'SIA_CONFIGURE',
    'Allow configuring of SIA integration'
  ),
  SIA_IMPORT: create('SIA_IMPORT', 'Allow importing of data from SIA'),

  // Multi Factor Authentication
  MFA_RESET: create('MFA_RESET', 'Allow resetting of MFA for a User'),

  FEATURE_CONTROL: create(
    'FEATURE_CONTROL',
    'Allow user to control feature toggles.'
  ),
};

export default permissions;
