import { LabelDecorator } from './label';
import { ValidationDecorator } from './validation';

export type Decorator = (
  Component: any,
  decoratorProps?: any
) => (props: any) => JSX.Element;
export type DecoratorRegistry = Record<string, Decorator>;

const defaultDecoratorRegistry: DecoratorRegistry = {
  label: LabelDecorator,
  validation: ValidationDecorator,
};

export default defaultDecoratorRegistry;
