import { structureBuilderFactory } from '@hogwarts/utils-schemes';
import { schemeStructure } from './schemeStructure';

export { schemeStructure, structureBuilderFactory };

export default structureBuilderFactory(schemeStructure, {
  objectsMustBeOwned: true,
  safeMode: true,
  logging: process.env.NODE_ENV !== 'production',
  includeDeleted: false,
});
