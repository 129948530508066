import { Intent, NumericInput } from '@blueprintjs/core';
import { Select } from '@hogwarts/ui-components-core';
import React, { useMemo, useState } from 'react';
// @ts-ignore
import styles from './styles.module.css';

let values = [
  {
    id: 'immediate',
    value: 'Immediate',
  },
  {
    id: 'days',
    value: 'Day(s)',
  },
  {
    id: 'weeks',
    value: 'Week(s)',
  },
  {
    id: 'months',
    value: 'Month(s)',
  },
  {
    id: 'years',
    value: 'Year(s)',
  },
];

interface DurationProps {
  onChange: (duration: string) => void;
  value: string;
  intent?: Intent;
  readOnly?: boolean;
}
const Duration = ({
  onChange,
  value: initialValue,
  intent,
  readOnly,
}: DurationProps) => {
  const [duration, setDuration] = useState(initialValue);

  const [count, type] = useMemo(() => {
    if (typeof duration !== 'string') {
      return [1, 'years'];
    }

    let [durationType, durationCountString] = duration.split(':');

    if (!values.find((v) => v.id === durationType)) {
      durationType = 'years';
    }

    let durationCount = Number.parseInt(durationCountString);
    if (Number.isNaN(durationCount)) {
      durationCount = 1;
    }

    return [durationCount, durationType];
  }, [duration]);

  return (
    <div className={styles.container}>
      <NumericInput
        min={1}
        max={30}
        className={styles.number}
        intent={intent}
        onValueChange={(value) => {
          const newDuration = `${type}:${value}`;
          setDuration(newDuration);
          if (onChange) {
            onChange(newDuration);
          }
        }}
        value={type === 'immediate' ? 0 : count}
        disabled={readOnly || type === 'immediate'}
        fill
      />
      {/* @ts-ignore */}
      <Select
        className={styles.select}
        valueField="id"
        textField="value"
        intent={intent}
        onChange={(item: any) => {
          const newDuration = `${item.id}:${count}`;
          setDuration(newDuration);
          if (onChange) {
            onChange(newDuration);
          }
        }}
        items={values}
        disabled={readOnly}
        fill
        value={type}
      />
    </div>
  );
};

export default Duration;
