import { AnalyticsContext } from '@/context';
import { AppToaster } from '@/utils/toaster';
import { Button, Card, Checkbox } from '@blueprintjs/core';
import { PrismicRichText } from '@prismicio/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { SettingsPanel } from '../SettingsPanel';
import styles from './styles.module.css';

const ReferralsSettings = ({
  state,
  pageContent,
  referralCode,
  onEnableReferralCode,
}) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const referralUrl = `https://www.signincentralrecord.com/r/${referralCode}`;

  return (
    <SettingsPanel title={pageContent?.title} loading={state === 'LOADING'}>
      <div className="d-flex mb-3">
        <div className="mr-3">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            style={{ width: '150px' }}
            src={pageContent?.giftCardImage.url}
            alt={pageContent?.giftCardImage.alt}
          />
        </div>
        <div className="flex-grow-1">
          <PrismicRichText field={pageContent?.content} />
        </div>
      </div>

      {(state === 'DISABLED' || state === 'ENABLING') && (
        <>
          <Checkbox
            checked={agreedTerms}
            disabled={state === 'ENABLING'}
            onChange={() => {
              setAgreedTerms(!agreedTerms);
            }}
          >
            {t('I agree to the Referral Program')}
            <a href="https://go.scrtracker.com/3pIhJOD" target="blank">
              {t(' Terms and Conditions.')}
            </a>
          </Checkbox>
          <Button
            large
            intent="primary"
            loading={state === 'ENABLING'}
            onClick={() => {
              analytics.events.settings.referralsEnabled();
              onEnableReferralCode();
            }}
            disabled={!agreedTerms}
          >
            {t('Enable Referral Code')}
          </Button>
        </>
      )}
      {state === 'ENABLED' && (
        <div className={styles.referralContainer}>
          <Card className={styles.card}>
            <p className={styles.cardHeader}>
              {t('Your Unique Referral Link:')}
            </p>
            <p
              className={styles.referralCode}
              onClick={() => {
                analytics.events.settings.referralsCodeCopied();
                navigator.clipboard.writeText(referralUrl);
                AppToaster.show({
                  message: t(`Copied to Clipboard`),
                  intent: 'success',
                  icon: 'tick',
                });
              }}
            >
              {referralUrl}
            </p>
            <div className={styles.socialButtons}>
              <EmailShareButton
                url={referralUrl}
                subject="I think you’ll enjoy using Sign In Central Record for your Single Central Record"
                body="I recently transitioned from Excel to Sign In Central Record for my Single Central Record management and found it quite useful. They gave me a referral link to share, and I thought you might be interested. If you use it, they'll know I mentioned their service to you. Feel free to reach out if you have any questions about Sign In Central Record—I'm here to help. Have a great day!"
                beforeOnClick={() => {
                  analytics.events.settings.referralsCodeShared({
                    network: 'email',
                  });
                }}
              >
                <EmailIcon round size={32} />
              </EmailShareButton>
              <TwitterShareButton
                url={referralUrl}
                title="Since upgrading to Sign In Central Record for my Single Central Record, I've found it much easier to stay compliant! If your organisation could also benefit from this seamless solution, I'd be happy to refer you via my personalised link."
                hashtags={[
                  'safeguarding',
                  'edutwitter',
                  'sltchat',
                  'sbltwitter',
                  'ukedchat',
                ]}
                beforeOnClick={() => {
                  analytics.events.settings.referralsCodeShared({
                    network: 'twitter',
                  });
                }}
              >
                <TwitterIcon round size={32} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={referralUrl}
                summary="Since upgrading to Sign In Central Record for my Single Central Record, I've found it much easier to stay compliant! If your organization could also benefit from this seamless solution, I'd be happy to refer you via my personalised link."
                beforeOnClick={() => {
                  analytics.events.settings.referralsCodeShared({
                    network: 'linkedin',
                  });
                }}
              >
                <LinkedinIcon round size={32} />
              </LinkedinShareButton>
              <FacebookShareButton
                url={referralUrl}
                quote="Since upgrading to Sign In Central Record for my Single Central Record, I've found it much easier to stay compliant! If your organization could also benefit from this seamless solution, I'd be happy to refer you via my personalised link."
                hashtag="#safeguarding"
                beforeOnClick={() => {
                  analytics.events.settings.referralsCodeShared({
                    network: 'facebook',
                  });
                }}
              >
                <FacebookIcon round size={32} />
              </FacebookShareButton>
            </div>
            <p>
              {t(
                'Terms and Conditions Apply, contact help@signincentralrecord.com for more details.'
              )}
            </p>
          </Card>
        </div>
      )}
    </SettingsPanel>
  );
};

export default ReferralsSettings;
