export default {
  key: 'getProfileLegalName',
  label:
    'Creates a legal name by combining first and last legal names, using first and last names as fallbacks',
  output: 'string',
  fields: [
    {
      key: 'firstname',
      label: 'First Name Field',
      type: 'field',
      dataType: 'string',
    },
    {
      key: 'lastname',
      label: 'Last Name Field',
      type: 'field',
      dataType: 'string',
    },
    {
      key: 'legal_firstname',
      label: 'Legal First Name Field',
      type: 'field',
      dataType: 'string',
    },
    {
      key: 'legal_lastname',
      label: 'Legal Last Name Field',
      type: 'field',
      dataType: 'string',
    },
  ],
  execute: ({ data }, params) => {
    if (!data) return null;
    const firstname = (
      data[params.legal_firstname] ||
      data[params.firstname] ||
      ''
    ).trim();
    const lastname = (
      data[params.legal_lastname] ||
      data[params.lastname] ||
      ''
    ).trim();
    const fullname = `${firstname} ${lastname}`.trim();
    if (fullname === '') return null;
    return fullname;
  },
};
