export const getErrorIntent = (fieldMeta) => {
  const errors = fieldMeta.error;
  const touched = fieldMeta.touched;
  if (!touched || !errors || errors.length === 0) return null;
  if (!Array.isArray(errors)) {
    return null;
  }

  if (errors.find((e) => e.severity === 'warning')) {
    return 'WARNING';
  }
  // if (errors.find(e => e.severity === 'error')) {
  return 'DANGER';
  // }
};

export const getErrorIntentClass = (fieldMeta) => {
  const errors = fieldMeta.error;
  const touched = fieldMeta.touched;

  if (!touched || !errors || errors.length === 0) return null;

  if (errors.find((e) => e.severity === 'warning')) {
    return 'bp3-intent-warning';
  }
  // if (errors.find(e => e.severity === 'error')) {
  return 'bp3-intent-danger';
  // }
};

export const selectOptions = (
  values,
  valueField = 'id',
  textField = 'value'
) => {
  if (typeof values === 'string') {
    return values
      .split('\n')
      .filter(Boolean)
      .map((value) => ({
        [valueField]: value,
        [textField]: value,
      }));
  }

  if (Array.isArray(values)) {
    if (values.length > 0 && typeof values[0] === 'string') {
      return values.map((value) => ({
        [valueField]: value,
        [textField]: value,
      }));
    }

    return values;
  }
};
