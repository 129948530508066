import {
  parseBool,
  parseDate,
  parseNumber,
  parseString,
} from '@hogwarts/validation';

export const convertValue = (dataType: string, value: any, options: any) => {
  if (typeof value === 'undefined') {
    return undefined;
  }

  switch (dataType) {
    case 'none': {
      return undefined;
    }
    case 'string': {
      let result = parseString(value);
      if (typeof result === 'string') {
        result = result.trim();
      }
      if (result === '') return null;
      return result;
    }
    case 'date': {
      if (process.env.NODE_ENV === 'test') {
        if (
          value &&
          value.getTimezoneOffset &&
          value.getTimezoneOffset() !== 0
        ) {
          throw new Error(
            'Using incorrect date constructor, must use Date.UTC during testing'
          );
        }
      }

      // Terrible time here with invalid dates being stored on mongo
      // as epoch time
      if (value instanceof Date) {
        // @ts-ignore
        if (isNaN(value)) {
          value = null;
        }
      }

      let date = parseDate(value, options?.timezone);
      if (date.isValid) {
        switch (options.dateFormat) {
          case 'date': {
            return date.toJSDate();
          }
          case 'string': {
            return date.toISODate();
          }
          default:
          case 'luxon': {
            // leave it alone if its already luxon
            return date;
          }
        }
      }
      // Dont allow this, it breaks all the things
      if (value === true || value === false) {
        return null;
      }

      // TODO: Should we really be storing broken dates?
      return value;
    }
    case 'number': {
      return parseNumber(value);
    }
    case 'boolean': {
      return parseBool(value);
    }
    case 'array': {
      return value;
    }
    default: {
      throw new Error(`Unknown dataType [${dataType}]`);
    }
  }
};
