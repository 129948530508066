import React from 'react';
import Loader from 'react-loader-spinner';
import shieldLogo from '../../assets/logo/SICR-Mark@2x.png';
import styles from './styles.module.css';

interface LoadingProps {
  showLogo?: boolean;
}
const Loading = ({ showLogo }: LoadingProps) => (
  <div className={styles.container}>
    {showLogo && <img className={styles.logo} src={shieldLogo} alt="loading" />}
    <Loader type={'ThreeDots'} color={'#70d955'} height={'50'} width={'50'} />
  </div>
);

export default Loading;
