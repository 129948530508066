import crypto from 'crypto';

export function getHash_MD5(data: string): string {
  return crypto.createHash('md5').update(data).digest('hex');
}

export function getHash_SHA1(data: string): string {
  return crypto.createHash('sha1').update(data).digest('hex');
}

export function getHash_SHA256(data: string): string {
  return crypto.createHash('sha256').update(data).digest('hex');
}
