import React from 'react';
import { ErrorMessage } from 'formik';

import styles from './styles.module.css';

const ErrorText = ({ children: errors }) => {
  return (
    <>
      {errors.map((error, index) => {
        return (
          <p className={styles.validationErrorText} key={index}>
            {error.message}
          </p>
        );
      })}
    </>
  );
};

export const ValidationDecorator = (Component) => (props) => {
  return (
    <>
      <Component {...props} />
      <ErrorMessage name={props.fieldKey} component={ErrorText} />
    </>
  );
};
