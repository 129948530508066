import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@blueprintjs/core';

const LabelComponent = ({ label }) => {
  const { t } = useTranslation();
  return <Label>{t(label)}</Label>;
};

export default LabelComponent;
