import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const LoadingPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Spinner className={styles.loginSpinner} intent="primary" />
      <p className={styles.loggingInMessage}>{t('Logging In...')}</p>
    </>
  );
};

export default LoadingPage;
