import {
  AnalyticsContext,
  ConfigContext,
  OrganisationContext,
} from '@/context';
import { useMutation, usePermission } from '@/hooks';
import { ADD_DOCUMENT, UPDATE_PROFILE_DATA_MUTATION } from '@/mutations';
import { GET_USER_PROFILE_DOCUMENTS, GET_USER_PROFILE_VERSIONS } from '@/queries';
import { getRequest } from '@/services';
import { AppToaster } from '@/utils/toaster';
import { Classes, ProgressBar } from '@blueprintjs/core';
import permissions from '@hogwarts/permissions';
import cn from 'classnames';
import { init } from 'filestack-js';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transformProfileVersionData } from '../../containers/userProfile/utils';
import { UserContext } from '../../context';
import { DELETE_PROFILE_PICTURE } from '../../mutations';
import { GET_USER_PROFILE } from '../../queries';

const renderProgress = (name, amount) => {
  return {
    icon: 'cloud-upload',
    message: (
      <div>
        <p>Uploading {name}</p>
        <ProgressBar
          className={cn({
            [Classes.PROGRESS_NO_STRIPES]: amount >= 100,
          })}
          intent={amount < 100 ? 'primary' : 'success'}
          value={amount / 100}
        />
      </div>
    ),
    timeout: amount < 100 ? 0 : 2000,
  };
};

const getNotifier = (filename, t) => ({
  uploadStarted: () => {
    AppToaster.show(renderProgress(filename, 0), filename);
  },
  uploadProgress: (percentage) => {
    AppToaster.show(renderProgress(filename, percentage), filename);
  },
  uploadSuccess: () => {
    AppToaster.show(
      {
        icon: 'tick',
        intent: 'success',
        message: `${t('Successfully uploaded')} ${filename}`,
      },
      filename
    );
  },
  uploadError: () => {
    AppToaster.show(
      {
        icon: 'error',
        intent: 'danger',
        message: `${t('Failed to upload')} ${filename}`,
      },
      filename
    );
  },
});

const showToastOnError = (message) => {
  AppToaster.show({
    icon: 'error',
    intent: 'danger',
    message
  });
  return null;
};

const getUploadPolicy = async (organisationId, t) => {
  try {
    const request = await getRequest();
    const policyResponse = await request.get(
      `/documents/policy/${organisationId}/upload`
    );

    if (policyResponse.status === 200) {
      return policyResponse.data;
    } else {
      return showToastOnError(t('Error starting upload'));
    }
  } catch (error) {
    return showToastOnError(t('Error starting upload'));
  }
};


const UploadOrgDocument = (Component) => {
  const UploadOrganisationDocumentWrapper = ({ ...props }) => {
    const [documents, setDocuments] = useState([]);
    const config = useContext(ConfigContext);
    const organisation = useContext(OrganisationContext);
    const user = useContext(UserContext);
    const analytics = useContext(AnalyticsContext);
    const { t } = useTranslation();
    const [addDocument] = useMutation(ADD_DOCUMENT, {
      selector: 'addDocument',
    });
    const allowUploadFile = usePermission(
      permissions.ORGANISATION_FILE_CREATE,
      organisation.id
    );

    return (
      <Component
        {...props}
        resetDocuments={() => setDocuments([])}
        onRemoveDocument={(document) => {
          setDocuments(documents.filter((d) => d.id !== document.id));
        }}
        documents={documents}
        allowUpload={allowUploadFile}
        onOpenDocumentPicker={async ({ confidential } = {}) => {
          if (!allowUploadFile) {
            return;
          }

          analytics.events.documents.uploadClicked();
          const policyData = await getUploadPolicy(organisation.id, t);
          if (policyData) {
            const client = init(config.FILESTACK_APIKEY, {
              security: policyData,
              tags: {
                type: 'document',
                organisationId: organisation.id,
              },
            });
            const notifiers = {};
            client
              .picker({
                storeTo: {
                  path: `organisations/${organisation.id}/documents/`,
                },
                maxFiles: 10,
                onFileUploadStarted: (file) => {
                  notifiers[file.filename] = getNotifier(file.filename, t);
                  notifiers[file.filename].uploadStarted();
                },
                onFileUploadProgress: (file, event) => {
                  notifiers[file.filename].uploadProgress(event.totalPercent);
                },
                onFileUploadFinished: async (result) => {
                  try {
                    const tags = [];
                    if (confidential) {
                      tags.push('confidential');
                    }

                    const document = {
                      filename: result.filename,
                      size: result.size,
                      type: result.mimetype ?? 'Unknown',
                      filestackHandle: result.handle,
                      tags,
                    };

                    const storedDocument = await addDocument({
                      variables: {
                        document: {
                          ...document,
                          meta: {
                            uploadedBy: user.id,
                            organisationName: organisation.name,
                          },
                          ownerType: 'ORGANISATION',
                          ownerId: organisation.id,
                        },
                      },
                    });
                    analytics.events.documents.fileUploaded();
                    notifiers[result.filename].uploadSuccess();
                    setDocuments(documents => [...documents, {...document, id: storedDocument?.data?.id}]);
                  } catch (error) {
                    notifiers[result.filename].uploadError();
                  }
                },
                onFileUploadFailed: (file) => {
                  notifiers[file.filename].uploadError();
                },
              })
              .open();
          }
        }}
      />
    );
  };

  return UploadOrganisationDocumentWrapper;
}

const UploadProfilePictureHoc = (Component) =>
function UploadPictureContainer({ profile: profileItem, allowEdit, ...props }) {
  let refetchQueries = [];
  const profile = profileItem || props.item || {};
  const [updatedImage, setUpdatedImage] = useState(profile.image);
  const config = useContext(ConfigContext);
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const { t } = useTranslation();

  const [editProfile] = useMutation(UPDATE_PROFILE_DATA_MUTATION);
  const [deleteProfilePicture] = useMutation(DELETE_PROFILE_PICTURE, {
    refetchQueries: [
      {
        query: GET_USER_PROFILE,
        variables: {
          organisationKey: organisation.key,
          profileId: profile?.id,
        },
      },
    ],
  });

  if (!props.skipProfileVersionRefetch) {
      refetchQueries = [
        {
          query: GET_USER_PROFILE_VERSIONS,
          variables: {
            organisationKey: organisation?.key,
            profileId: profile?.id,
            limit: 15,
            changes: ['profileTypeKey', 'organisation', 'data', 'add'],
            cursor: null,
          },
          selector: 'organisations[0].profiles[0].versions',
          transform: transformProfileVersionData(props.scheme),
        }
      ]

  };
    
  return (
    <Component
      {...props}
      updatedImage={updatedImage}
      profile={profile}
      allowEdit={allowEdit}
      onProfilePictureUpload={async ({ files }) => {
        if (!allowEdit) {
          return;
        }
        if (!files.length) return;
        analytics.events.profile.pictureDropped();

        if (Array.isArray(files) && files.length === 1) {
          const policyData = await getUploadPolicy(organisation.id, t);
          if (policyData) {
            const client = init(config.FILESTACK_APIKEY, {
              security: policyData,
              tags: {
                type: 'profile_picture',
                profileId: profile.id,
              },
            });

            files.forEach(async (file) => {
              if (!file.type.startsWith('image/')) {
                return;
              }
              const notifier = getNotifier(file.name, t);
              notifier.uploadStarted();
              try {
                const result = await client.upload(
                  file,
                  {
                    onProgress: (event) => {
                      notifier.uploadProgress(event.totalPercent);
                    },
                    progressInterval: 100,
                  },
                  {
                    path: `profiles/${profile.id}/profilepic/`,
                  }
                );
                await editProfile({
                  refetchQueries,
                  variables: {
                    profileId: profile.id,
                    data: {
                      picture: result.url,
                    },
                    meta: {
                      picture: {
                        filename: result.filename,
                        size: result.size,
                        type: result.type,
                        filestackHandle: result.handle,
                      },
                    },
                    trace: [
                      {
                        component: 'UploadProfilePictureContainer',
                        event: 'pictureDropped(Multiple)',
                      },
                    ],
                  },
                });
                analytics.events.profile.pictureUpload();
                notifier.uploadSuccess();
                setUpdatedImage(result.url);
              } catch (error) {
                notifier.uploadError();
              }
            });
          }
        }
      }}
      onOpenProfilePicturePicker={async () => {
        if (!allowEdit) {
          return;
        }
        analytics.events.profile.pictureUploadClicked();
        const policyData = await getUploadPolicy(organisation.id, t);
        if (policyData) {
          const client = init(config.FILESTACK_APIKEY, {
            security: policyData,
            tags: {
              type: 'profile_picture',
              profileId: profile.id,
            },
          });
          const notifiers = {};
          client
            .picker({
              storeTo: {
                path: `profiles/${profile.id}/profilepic/`,
              },
              accept: ['image/*'],
              maxFiles: 1,
              onFileUploadStarted: (file) => {
                notifiers[file.filename] = getNotifier(file.filename, t);
                notifiers[file.filename].uploadStarted();
              },
              onFileUploadProgress: (file, event) => {
                notifiers[file.filename].uploadProgress(event.totalPercent);
              },
              onFileUploadFinished: async (result) => {
                try {
                  await editProfile({
                    refetchQueries,
                    variables: {
                      profileId: profile.id,
                      data: {
                        picture: result.url,
                      },
                      meta: {
                        picture: {
                          filename: result.filename,
                          size: result.size,
                          type: result.type,
                          filestackHandle: result.handle,
                        },
                      },
                      trace: [
                        {
                          component: 'UploadProfilePictureContainer',
                          event: 'pictureDropped',
                        },
                      ],
                    },
                  });
                  analytics.events.profile.pictureUpload();
                  notifiers[result.filename].uploadSuccess();
                  setUpdatedImage(result.url);
                } catch (error) {
                  notifiers[result.filename].uploadError();
                }
              },
              onFileUploadFailed: (file) => {
                notifiers[file.filename].uploadError();
              },
            })
            .open();
        }
      }}
      onDeleteProfilePicture={async () => {
        analytics.events.profile.pictureDeleteClicked();

        // Delete profile picture from Filestack and DB
        const deleteSuccess = await deleteProfilePicture({
          variables: {
            profileId: profile.id,
          }
        });

        if(deleteSuccess.data.deleteProfilePicture) setUpdatedImage(null);

        if (!deleteSuccess.data) {
          AppToaster.show({
            icon: 'error',
            intent: 'danger',
            message: t('Error deleting profile picture.'),
          });
        }
      }}
    />
  );
};

const UploadContainerHoC = (Component) =>
  function UploadContainer({ profile, currentDocumentsFilter, ...props }) {
    const config = useContext(ConfigContext);
    const organisation = useContext(OrganisationContext);
    const analytics = useContext(AnalyticsContext);
    const { t } = useTranslation();
    const [addDocument] = useMutation(ADD_DOCUMENT, {
      selector: 'addDocument',
      refetchQueries: [
        {
          query: GET_USER_PROFILE_DOCUMENTS,
          variables: {
            organisationKey: organisation.key,
            profileId: profile.id,
          },
        },
      ],
    });
    const allowUploadFile = usePermission(
      permissions.PROFILE_FILE_CREATE,
      organisation.id
    );
   

    return (
      <Component
        {...props}
        profile={profile}
        allowUpload={allowUploadFile}
        onDocumentsUpload={async ({ sectionKey, files, confidential }) => {
          if (!allowUploadFile) {
            return;
          }
          analytics.events.documents.fileDropped({ sectionKey, confidential });
          if (Array.isArray(files) && files.length > 0) {
            const policyData = await getUploadPolicy(organisation.id, t);
            if (policyData) {
              const client = init(config.FILESTACK_APIKEY, {
                security: policyData,
                tags: {
                  type: 'document',
                  profileId: profile.id,
                },
              });
              files.forEach(async (file) => {
                const notifier = getNotifier(file.name, t);
                notifier.uploadStarted();
                try {
                  const result = await client.upload(
                    file,
                    {
                      onProgress: (event) => {
                        notifier.uploadProgress(event.totalPercent);
                      },
                      progressInterval: 100,
                    },
                    {
                      path: `profiles/${profile.id}/documents/`,
                    }
                  );
                  const tags = [];
                  if (confidential) {
                    tags.push('confidential');
                  }
                  await addDocument({
                    variables: {
                      document: {
                        filename: result.filename,
                        size: result.size,
                        type: result.type ?? 'Unknown',
                        filestackHandle: result.handle,
                        meta: {
                          sectionKey,
                        },
                        tags,
                        ownerType: 'PROFILE',
                        ownerId: profile.id,
                      },
                    },
                  });
                  analytics.events.documents.fileUploaded({
                    sectionKey,
                    confidential,
                  });
                  notifier.uploadSuccess();
                } catch (error) {
                  notifier.uploadError();
                }
              });
            }
          }
        }}
        onOpenDocumentPicker={async ({ confidential } = {}) => {
          if (!allowUploadFile) {
            return;
          }
          analytics.events.documents.uploadClicked();
          const policyData = await getUploadPolicy(organisation.id, t);
          if (policyData) {
            const client = init(config.FILESTACK_APIKEY, {
              security: policyData,
              tags: {
                type: 'document',
                profileId: profile.id,
              },
            });
            const notifiers = {};
            client
              .picker({
                storeTo: {
                  path: `profiles/${profile.id}/documents/`,
                },
                maxFiles: 10,
                onFileUploadStarted: (file) => {
                  notifiers[file.filename] = getNotifier(file.filename, t);
                  notifiers[file.filename].uploadStarted();
                },
                onFileUploadProgress: (file, event) => {
                  notifiers[file.filename].uploadProgress(event.totalPercent);
                },
                onFileUploadFinished: async (result) => {
                  try {
                    const tags = [];
                    if (confidential) {
                      tags.push('confidential');
                    }
                    await addDocument({
                      variables: {
                        document: {
                          filename: result.filename,
                          size: result.size,
                          type: result.mimetype ?? 'Unknown',
                          filestackHandle: result.handle,
                          meta: {},
                          ownerType: 'PROFILE',
                          ownerId: profile.id,
                          tags,
                        },
                      },
                    });
                    analytics.events.documents.fileUploaded();
                    notifiers[result.filename].uploadSuccess();
                  } catch (error) {
                    notifiers[result.filename].uploadError();
                  }
                },
                onFileUploadFailed: (file) => {
                  notifiers[file.filename].uploadError();
                },
              })
              .open();
          }
        }}
      />
    );
  };
  
export const UploadOrganisationDocument = (Component) => UploadOrgDocument(Component);
export const UploadProfilePicture = (Component) => UploadProfilePictureHoc(Component);
export const UploadProfileDocument = (Component) =>
UploadProfilePictureHoc(UploadContainerHoC(Component));
