import { isObject, uppercaseFirstLetter } from '@hogwarts/utils';
import { get, sortBy } from 'lodash';
import { Layer, Options, Structure } from './types';

import log from '@scrtracker/logging';
import { checkSchemeType } from '../utils';
import { reservedKey } from './utils';

// import * as yup from 'yup';
// import { parseDate } from '@hogwarts/validation';

// export const getFieldValidation = (field) => {
//   let validation;
//   if (field.validation) {
//     return field.validation;
//   }
//   switch (field.type) {
//     case 'number': {
//       validation = yup.number();
//       break;
//     }
//     case 'string': {
//       validation = yup.string();
//       break;
//     }
//     case 'boolean': {
//       validation = yup.boolean();
//       break;
//     }
//     case 'date': {
//       // TODO: Date parser?
//       validation = yup.lazy((value) => {
//         const date = parseDate(value);
//         return date.isValid;
//       });
//       break;
//     }
//     case 'object': {
//       validation = yup.mixed();
//       break;
//     }
//     default: {
//       return null;
//     }
//   }

//   if (field.oneOf) {
//     validation = validation.oneOf(field.oneOf);
//   }

//   if (field.nullable) {
//     validation = validation.nullable();
//   }
//   if (field.required) {
//     validation = validation.required();
//   }

//   return validation;
// };

const sameLayerAndVariant = (layer1: Layer, layer2: Layer): boolean => {
  return layer1.id === layer2.id && layer1.variant === layer2.variant;
};

const getItemShapes = (
  structureItem: Structure,
  itemRoot: string[],
  keyedData: any
) => {
  switch (structureItem.type) {
    // dynamic items based on the keys (e.g. fields, sections)
    case 'objects-dynamic': {
      return Object.keys(itemRoot || {}).map((key) => ({
        key,
        structure: structureItem.structure,
      }));
    }
    // lookup the keys from a different collection
    // same structure for each (e.g. ratings)
    case 'objects-join': {
      const foreignCollection = keyedData[structureItem.joinRef];
      if (!foreignCollection) {
        throw new Error(`Unknown Join to [${structureItem.joinRef}`);
      }
      return Object.keys(foreignCollection).map((key) => ({
        key,
        structure: structureItem.structure,
      }));
    }
    // fixed items, known keys but different structures
    // e.g. features list
    case 'objects-known': {
      return structureItem.structure.map((s) => ({
        key: s.key,
        structure: s.structure,
      }));
    }
    default: {
      throw new Error(`Unsupported Object type [${structureItem.type}]`);
    }
  }
};

const buildItems = (
  rootPath2: string[],
  structureItem2: any,
  initialLayers: Layer[],
  options: Options,
  keyedData: any,
  allOrphans: any[],
  allLogs: any[]
) => {
  const rootPath: string[] = [...rootPath2, structureItem2.key];
  let result = null;

  const layers: Layer[] = [];
  let finalLayer;
  for (const layer of initialLayers) {
    layers.push(layer);
    finalLayer = layer;
  }

  for (let layerIndex = 0; layerIndex < layers.length; layerIndex++) {
    const layer = layers[layerIndex];
    let parentLayer = null;
    if (layerIndex > 0) {
      parentLayer = layers[layerIndex - 1];
    }

    const itemRoot = get(layer.data, rootPath);

    if (!result) {
      result = {};
    }

    let itemShapes = getItemShapes(structureItem2, itemRoot, keyedData);

    // e.g. each fieldKey in a fields block
    for (let itemShape of itemShapes) {
      const log2item = (item) => (level, message) => {
        if (!options.logging) return;
        if (!item.log) {
          item.log = [];
        }
        const logItem = {
          layer: layer.id,
          level,
          message,
          path: [...rootPath, itemShape.key].join('.'),
        };
        item.log.push(logItem);
        allLogs.push(logItem);
      };

      // grab the item data for this layer
      let itemLayerData = get(itemRoot, itemShape.key);

      // make a note to see if the value actually exists for orphan checking later
      const itemLayerDataExists = typeof itemLayerData !== 'undefined';
      if (!itemLayerDataExists) {
        // What this is doing is checking to see if something like "label" exists yet
        // As we create a set of default meta data and information for every property, regardless
        // of if its included in any of the layers, we dont want to be doing any updates
        // if it simply doesnt exist.
        // Once we reach the final layer (including patch layers), we dont skip anything,
        // and ensure all the meta data is created

        if (layer.id !== finalLayer.id) {
          continue;
        }

        itemLayerData = {};
      }

      // try and find the item (e.g. the field) on the current result
      let itemResult = get(result, itemShape.key);

      // if the item hasn't yet been created, then set it up
      let owner = false;
      let owned = false;
      if (!itemResult) {
        owner = true;
        owned = layer.id === finalLayer.id;
        itemResult = {
          key: itemShape.key,
          lock: {},
          meta: {
            // if this is a default value, then set the owner to null
            // rather than make assumptions about ownership
            // Example might be when you have a "field.rating.dfe" but
            // its not actually set anywhere, however we still create
            // the item (objects-join) but theres technically not an owner
            owner: itemLayerDataExists ? layer.id : null,
            ownerVariant: itemLayerDataExists ? layer.variant || null : null,
            // owned is when we are the current owner.
            // feels badly named but its done to match editor implementation for now.
            owned,
          },
        };

        // First sighting of this component item, check its owned
        if (
          itemLayerDataExists &&
          options.objectsMustBeOwned &&
          !itemLayerData.owner
        ) {
          // Its not got an owner so its an Orphan
          // We skip the item and create a log entry
          // Could be that parent has deleted it so we could purge these
          allOrphans.push({
            layer: layer.id,
            layerVariant: layer.variant,
            path: [...rootPath, itemShape.key].join('.'),
          });
          continue;
        }

        if (itemLayerData.deleted) {
          if (layer.patch) {
            // Adding a deleted item in a patch? Just remove it
            continue;
          }

          // Apart from patching, this is the only time you can delete it
          // so we only check for it here.
          itemResult.deleted = true;
          itemResult.meta.deletedLayer = layer.id;
        }

        result[itemShape.key] = itemResult;
        // set(result, itemShape.key, itemResult);
      } else if (itemLayerData.deleted && !layer.patch) {
        // if the item is already deleted, and we're not patching
        // add a warning
        // TODO: Could we add hints on how to rectify these problems?
        // like have an "unset:path" hint?
        // Giving a sort of cleanup mechanism in the editor, or on save etc?
        log2item(itemResult)(
          'warn',
          'Attempted to delete item when not the owner'
        );
      } else if (layer.patch) {
        // Patch layers contain the same ID but have the ability to bypass locks
        // and restore/delete items that are owned in its layer being patched

        if (!itemResult.deleted && itemLayerData.deleted === true) {
          // Are we trying to delete the item in a patch?
          // check that the patch layer is the owner
          if (itemResult.meta.owner === layer.id) {
            // allow it!
            itemResult.deleted = true;
            itemResult.meta.deletedLayer = layer.id;
            itemResult.meta.deletedLayerVariant = layer.variant;
          } else {
            // dont allow deleting in a ptch if we're not the owner
            log2item(itemResult)(
              'warn',
              'Attempted to delete item in patch layer when not the owner'
            );
          }
        } else if (itemResult.deleted && itemLayerData.deleted === false) {
          // Are we trying to restore a deleted item?

          // restore the item but only if its deleted in this layer
          if (itemResult.meta.owner === layer.id) {
            itemResult.deleted = false;
            itemResult.meta.restored = true;
          } else {
            log2item(itemResult)(
              `warn`,
              'Attempted to restore item in patch that is not owned by layer'
            );
          }
        }
      }

      for (let field of itemShape.structure) {
        if (!field.key) {
          throw new Error('Item does not have a Key set');
        }
        if (options.safeMode && reservedKey(field.key)) {
          throw new Error(`Key [${field.key}] is reserved`);
        }

        // Try grabbing the existing meta data for this field
        let fieldMeta = itemResult.meta[field.key];
        if (!fieldMeta) {
          // if doesnt exist yet, then set it up
          itemResult.meta[field.key] = fieldMeta = {
            path: [...rootPath, itemShape.key, field.key].join('.'),
            locked: false,
            lockpath:
              field.lockable !== false
                ? [...rootPath, itemShape.key, 'lock', field.key].join('.')
                : null,
            log: [],
          };
        }

        const log = (
          level: 'info' | 'warn' | 'error' | 'verbose',
          message: string
        ) => {
          if (!options.logging) return;
          const logItem = {
            layer: layer.id,
            level,
            message,
          };
          fieldMeta.log.push(logItem);
          allLogs.push({
            ...logItem,
            fieldMeta,
          });
        };

        if (typeof field.runtime === 'function') {
          const runtime = field.runtime(itemLayerData);
          if (runtime) {
            field = {
              ...field,
              ...runtime,
            };
          }
        }

        let allowLockBypass = false;
        if (
          layer.patch &&
          fieldMeta.locklayer &&
          sameLayerAndVariant(fieldMeta.locklayer, layer)
        ) {
          // If this is patch, then it can override the above layer
          // even if there is a lock, but only if the immediate layer above locked it
          // as this is the layer we are patching
          allowLockBypass = true;
        }

        let proposedValue = itemLayerData[field.key];

        if (owner && typeof proposedValue === 'undefined') {
          proposedValue = field.defaultValue;
        }

        const locked = itemResult.lock[field.key];
        let proposedLock =
          field.locked || (itemLayerData.lock && itemLayerData.lock[field.key]);

        if (field.lockable === false && proposedLock === true) {
          log('warn', 'Attempt to lock field that is not lockable');
          proposedLock = null;
        }

        // Are we trying to turn off the lock?
        if (locked && proposedLock === false) {
          if (!allowLockBypass) {
            log('warn', `Trying to disable the lock when its locked`);
            proposedLock = null;
          } else {
            // Turn off the lock and delete the meta data
            itemResult.lock[field.key] = false;
            delete fieldMeta.locklayer;
          }
        }

        if (!locked && proposedLock === true) {
          // lock the field
          itemResult.lock[field.key] = true;

          // Records at what layer the item got its original lock
          fieldMeta.locklayer = {
            id: layer.id,
            variant: layer.variant,
          };

          // Record that it is now locked if this isnt the bottom layer
          fieldMeta.locked = !sameLayerAndVariant(layer, finalLayer);
        }

        if (typeof proposedValue === 'undefined') {
          switch (field.type) {
            case 'objects-dynamic':
            case 'objects-known':
            case 'objects-join': {
              proposedValue = {};
              break;
            }
          }
        }

        if (typeof proposedValue === 'undefined') {
          if (owner && field.required) {
            log('error', 'Initial value is missing');
          }
          continue;
        } else if (!allowLockBypass && locked) {
          log('warn', `Trying to override but is locked`);
        } else if (itemResult[field.key] === proposedValue) {
          // tried to override but value matches parent so dont set it.
          log('info', 'Matches parent value');
          // however, it is actually set by this layer, so update that
          fieldMeta.layer = layer.id;
          continue;
        } else if (field.locked && !owner) {
          log(
            'info',
            'Trying to override a fixed lock field when not the owner'
          );
          continue;
        } else {
          switch (field.type) {
            case 'objects-dynamic':
            case 'objects-known':
            case 'objects-join': {
              const childItem = buildItems(
                [...rootPath, itemShape.key],
                field,
                layers,
                {
                  ...options,
                  objectsMustBeOwned: false,
                },
                keyedData,
                allOrphans,
                allLogs
              );

              itemResult[field.key] = childItem || {};
              fieldMeta.layer = layer.id;
              break;
            }
            default: {
              // So slow:
              // We could generate the validation schema seperately
              // given a structure, and then run it against the
              // final output?

              // const validationSchema = getFieldValidation(field);
              // if (validationSchema) {
              //   try {
              //     if (!validationSchema.isValidSync(proposedValue)) {
              //       fieldMeta.log.push({
              //         layer: layer.id,
              //         level: 'error',
              //         message: 'Invalid value',
              //       });
              //     }
              //   } catch (ex) {
              //   }
              // }
              // fieldMeta.validationSchema = validationSchema;

              // log('verbose', 'Contains a usable value');

              itemResult[field.key] = proposedValue;
              fieldMeta.layer = layer.id;
              break;
            }
          }
        }
      }
    }
  }

  return result;
};

const singularStructureItemName = (structureKey) => {
  let result = uppercaseFirstLetter(structureKey);
  if (result.endsWith('s')) {
    return result.substring(0, result.length - 1);
  }
  return result;
};

const isVisibleInArray = (structureItem, item, options: Options) => {
  if (item.deleted === true && !options.includeDeleted) {
    return false;
  }

  if (
    typeof structureItem.postFilter === 'function' &&
    !structureItem.postFilter(item)
  ) {
    return false;
  }

  return true;
};

const buildArrayData = (
  structure: Structure[],
  object: any,
  options: Options
) => {
  const arrayCache = {};

  for (const structureItem of structure) {
    switch (structureItem.type) {
      case 'objects-known':
      case 'objects-join':
      case 'objects-dynamic': {
        // Define a lazy property that can iterate over the items
        Object.defineProperty(object, structureItem.key, {
          enumerable: true,
          get: function () {
            // try and grab it if we've built it before
            if (arrayCache && arrayCache[structureItem.key]) {
              return arrayCache[structureItem.key];
            }

            // otherwise, build it
            const keyedItems = this.data[structureItem.key];
            let itemList = [];
            for (const itemKey of Object.keys(keyedItems)) {
              const item = keyedItems[itemKey];

              if (!isVisibleInArray(structureItem, item, options)) {
                continue;
              }

              itemList.push(item);
            }

            // sort it
            if (structureItem.sort) {
              itemList = sortBy(itemList, structureItem.sort);
            }

            // store it
            arrayCache[structureItem.key] = itemList;

            // return it
            return itemList;
          },
        });

        const itemName = singularStructureItemName(structureItem.key);

        Object.defineProperty(object, `get${itemName}`, {
          enumerable: true,
          writable: false,
          value: function (key: string) {
            if (!key) return null;
            const items = this[structureItem.key] as { key: string }[];
            if (!items) return null;
            return items.find((item) => item.key === key);
          },
        });
      }
    }
  }
};

const populateLookups = (
  structure: Structure[],
  object: any,
  options: Options
) => {
  for (const structureItem of structure) {
    if (!structureItem.lookups?.length) continue;

    for (const lookup of structureItem.lookups) {
      const items = object.data[structureItem.key];

      const foreignItems = object.data[lookup.ref];
      if (!foreignItems) {
        log.warn(
          `Referencing unknown foreign collection [${lookup.ref}] in structure [${structureItem.key}]`
        );
        continue;
      }

      const itemSortList = [];

      // Initialise the item, ensure that the lookup is always at least an empty array
      for (const item of object[structureItem.key]) {
        if (lookup.parentFilter && !lookup.parentFilter(item)) {
          continue;
        }
        item[lookup.key] = [];
        itemSortList.push(item);
      }

      for (const foreignItemKey of Object.keys(foreignItems)) {
        const foreignItem = foreignItems[foreignItemKey];

        if (
          lookup.ignoreArrayFilter !== true &&
          !isVisibleInArray(structureItem, foreignItem, options)
        ) {
          continue;
        }

        if (foreignItem.deleted === true && !options.includeDeleted) {
          continue;
        }

        let foreignKey = foreignItem[lookup.fk];

        if (isObject(foreignKey)) {
          throw new Error('ForeignKey should not be an object');
        }

        if (foreignKey == null) continue;

        const parentItem = items[foreignKey];
        if (!parentItem) {
          log.warn(
            `Referencing unknown item [${structureItem.key}.${foreignKey}] from [${lookup.key}.${foreignItem.key}]`
          );
          continue;
        }

        const childItems = parentItem[lookup.key];
        if (childItems) {
          childItems.push(foreignItem);
        }
      }

      if (lookup.sort) {
        for (const item of itemSortList) {
          // writes directly to the item
          item[lookup.key] = sortBy(item[lookup.key], lookup.sort);
        }
      }
    }
  }
};

const postProcessItems = (structure: Structure[], object) => {
  for (const structureItem of structure) {
    if (typeof structureItem.postProcess !== 'function') {
      continue;
    }
    for (const item of object[structureItem.key]) {
      structureItem.postProcess(item, object.data);
      // Object.freeze(item);
      // Object.freeze(item.meta);
    }
  }
};

export const buildKeyedData =
  (structure: Structure[], options: Options) => (layers: Layer[]) => {
    if (!Array.isArray(layers)) {
      throw new Error(
        'SchemeHelper only takes Array type of layers for composition'
      );
    }

    for (const layer of layers) {
      checkSchemeType(layer, false);
    }

    let keyedData = {};
    const allOrphans = [];
    const allLogs = [];

    for (const structureItem of structure) {
      if (reservedKey(structureItem.key)) {
        throw new Error(
          `Invalid Key [${structureItem.key}] used on Structure Item`
        );
      }

      if (!keyedData[structureItem.key]) {
        keyedData[structureItem.key] = {};
      }

      const items = buildItems(
        [],
        structureItem,
        layers,
        {
          objectsMustBeOwned: true,
          safeMode: true,
          ...options,
          ...structureItem.options,
        },
        keyedData,
        allOrphans,
        allLogs
      );
      if (items) {
        keyedData[structureItem.key] = items;
      }
    }

    const result = {
      get data() {
        return keyedData;
      },
    };

    buildArrayData(structure, result, options);
    populateLookups(structure, result, options);
    postProcessItems(structure, result);

    return result;
  };

const checkLayerIdsForPatch = (layers) => {
  let previouslayer;
  for (const layer of layers) {
    if (layer.patch) {
      if (!previouslayer) {
        throw new Error('Cannot patch at Root');
      }

      if (previouslayer.id !== layer.id) {
        throw new Error(`Patch layer must match parent layer Id [${layer.id}]`);
      }

      if (previouslayer.version !== layer.version) {
        throw new Error(
          `Patch layer Version must match parent layer Id [${layer.version}]`
        );
      }
    }
    previouslayer = layer;
  }
};

export const structureBuilderFactory =
  (structure: Structure[], options: Options) =>
  (layers: Layer[]): any => {
    if (!layers) return null;

    checkLayerIdsForPatch(layers);

    options = {
      logging: true,
      objectsMustBeOwned: true,
      safeMode: true,
      includeDeleted: false,
      ...options,
    };

    return buildKeyedData(structure, options)(layers);
  };
