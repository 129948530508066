export interface Price {
  value: number;
  currency: string;
}

export const add = (price1: Price, price2: Price): Price => {
  if (price1.currency !== price2.currency) {
    throw new Error('Currency does not match');
  }

  return {
    currency: price1.currency,
    value: price1.value + price2.value,
  };
};

export const sub = (price1: Price, price2: Price): Price => {
  if (price1.currency !== price2.currency) {
    throw new Error('Currency does not match');
  }

  return {
    currency: price1.currency,
    value: price1.value - price2.value,
  };
};

export const mul = (price1: Price, value: number): Price => {
  return {
    currency: price1.currency,
    value: price1.value * value,
  };
};

export const ZERO: Price = Object.freeze({
  value: 0,
  currency: 'GBP',
});

export const lessThan = (price1: Price, comparison: Price): boolean => {
  if (price1.currency !== comparison.currency) {
    throw new Error('Currency does not match');
  }

  return price1.value < comparison.value;
};

export const lessThanOrEqual = (price1: Price, comparison: Price): boolean => {
  if (price1.currency !== comparison.currency) {
    throw new Error('Currency does not match');
  }

  return price1.value <= comparison.value;
};

export const format = (price: Price): string => {
  switch (price.currency) {
    case 'GBP': {
      return `£ ${(price.value / 100).toLocaleString('en-GB', {
        minimumFractionDigits: 2,
        useGrouping: true,
      })}`;
    }
    default: {
      throw new Error(`Unsupported Currency [${price.currency}]`);
    }
  }
};
