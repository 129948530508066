import log from '@scrtracker/logging';

export interface Registry<T> {
  register(key: string, item: T): T;
  get(key: string): T;
  getAll(): T[];
  clear(): void;
}

export function registryFactory<T>(optionalName: string = ''): Registry<T> {
  let registry: Record<string, T> = {};
  const result: Registry<T> = {
    register(key: string, item: T): T {
      if (!key) {
        log.debug('Key must be specified', {
          key,
          item,
        });
        throw new Error('Key must be specified');
      }

      key = key.toLowerCase();

      log.info(
        `Registering Item [${key}] in registry ${optionalName}`.trimRight()
      );

      if (registry[key]) {
        throw new Error(
          `Item [${key}] is already registered in registry ${optionalName}`.trimRight()
        );
      }

      registry[key] = item;

      return item;
    },
    get(key: string): T {
      key = key.toLowerCase();

      const item = registry[key];
      if (!item) {
        log.debug(
          `Failed to find item [${key}] in registry ${optionalName}`.trimRight()
        );
        return null;
      }
      return item;
    },
    getAll(): T[] {
      return Object.keys(registry).reduce(
        (prev, key) => [...prev, registry[key]],
        []
      );
    },
    clear(): void {
      registry = {};
    },
  };
  return result;
}
