import { parseDuration } from '@hogwarts/conditionals';
import { parseDate } from '@hogwarts/validation';
import { DateTime } from 'luxon';

const RULE_KEY = 'expiring_date_check';
const DEFAULT_DURATION = 'years:1';

export const getFieldExpiryRule = (field: any) => {
  if (field.dataType === 'date' && field.validation) {
    for (const ruleKey of Object.keys(field.validation)) {
      const rule = field.validation[ruleKey];
      if (rule?.rule === RULE_KEY) {
        return rule;
      }
    }
  }
  return null;
};

export const getExpiryDate = (
  duration: string,
  value: any,
  timezone?: string
) => {
  if (!value) {
    return null;
  }

  let fieldDateValue = parseDate(value);

  if (timezone) {
    fieldDateValue = fieldDateValue
      .setZone(timezone)
      .setZone('utc', { keepLocalTime: true });
  }

  if (!fieldDateValue.isValid) {
    return DateTime.invalid('Could not parse value');
  }

  const duration1 = parseDuration(duration || DEFAULT_DURATION);

  // const comparison = DateTime.utc()
  //   .minus(duration)
  //   .startOf('day')

  return fieldDateValue.plus(duration1).startOf('day');
};

export const hasExpired = (duration: string, value: any, timezone?: string) => {
  const expiryDate = getExpiryDate(duration, value, timezone);
  return expiryDate < DateTime.utc();
};

export const getExpiringFields = (scheme: any) => {
  let fields = [];
  for (const field of scheme.fields) {
    if (!field.inUse || !field.sectionItem?.inUse) {
      continue;
    }
    const rule = getFieldExpiryRule(field);
    if (rule) {
      fields.push({
        key: field.key,
        duration: rule.duration || DEFAULT_DURATION,
      });
    }
  }
  return fields;
};
