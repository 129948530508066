import {
  Condition,
  ConditionGroup,
  traverseCondition,
} from '@hogwarts/conditionals';

import { isObject } from '@hogwarts/utils';

export const fieldPatcher = (updatedVariableName) => (condition) => {
  let result = { ...condition };
  for (const key of Object.keys(condition)) {
    let value = condition[key];

    if (Array.isArray(value)) {
      let parts = [];
      for (const part of value) {
        if (part === 'field$') {
          parts.push(updatedVariableName);
        } else if (isObject(part)) {
          let partResult = {};
          for (const key2 of Object.keys(part)) {
            const partValue = part[key2];
            if (partValue === 'field$') {
              partResult[key2] = updatedVariableName;
            } else {
              partResult[key2] = partValue;
            }
          }
          parts.push(partResult);
        } else {
          parts.push(part);
        }
      }
      result[key] = parts;
    } else if (value === 'field$') {
      result[key] = updatedVariableName;
    }
  }
  return result;
};

const durationPatcher =
  (updatedVariableName: string) => (condition: Condition) => {
    const result = { ...condition };

    if (Array.isArray(result.comparison) && result.comparison?.[1].duration) {
      result.comparison = [
        result.comparison[0],
        {
          ...result.comparison[1],
          duration: updatedVariableName,
        },
      ];
    }

    return result;
  };

export const validationConditionBuilder = (scheme, ruleKey) => {
  // Generate a condition that runs for each of the fields
  // THOUGHT: Do we need a column list?

  let index = 0;

  const rule = scheme.getValidationRule(ruleKey);
  if (!rule) {
    throw new Error(`Invalid Rule Key [${ruleKey}]`);
  }

  if (rule.type !== 'condition') {
    throw new Error('Rule must be a Condition Type');
  }

  let allConditions = [];

  const allVariables: Record<string, any> = {};
  const setVariable = (key: string, value: any) => {
    const existing = allVariables[key];
    if (typeof existing !== 'undefined' && existing !== value) {
      console.log('existing', existing);
      throw new Error(`Variable [${key}] already defined with different value`);
    }
    allVariables[key] = value;
  };

  for (const field of scheme.fields) {
    if (!field.validation) {
      continue;
    }
    for (const validationKey of Object.keys(field.validation)) {
      const validation = field.validation[validationKey];

      if (!validation) continue;

      const { type, rule: actualRuleKey, ...params } = validation;

      if (type === 'rule' && actualRuleKey === ruleKey) {
        const { field$: fieldVariable, ...otherVariables } =
          rule.condition.variables;
        const fieldVariableName = `${field.key}$`;
        const durationVariableName = `duration${index++}$`;
        const { variables, conditions, ...otherConditionData } =
          traverseCondition(rule.condition, (condition) => {
            return durationPatcher(durationVariableName)(
              fieldPatcher(fieldVariableName)(condition)
            );
          }) as ConditionGroup;

        // If the rule has params, then for each param, turn
        // that into a variable using the value off the rule
        if (rule.params && params) {
          for (const paramKey of Object.keys(rule.params)) {
            let definedParam = rule.params[paramKey];
            let value = params[paramKey];
            if (value == null) {
              value = definedParam.value;
            }
            otherVariables[`${paramKey}$`] = {
              type: definedParam.type,
              value,
            };
          }
        }

        for (const variableKey of Object.keys(otherVariables)) {
          if (variableKey === 'duration$') {
            setVariable(durationVariableName, otherVariables[variableKey]);
          } else {
            setVariable(variableKey, otherVariables[variableKey]);
          }
        }

        setVariable(fieldVariableName, {
          type: fieldVariable,
          source: ['field', field.key],
        });

        if (validation.ignoreEmpty) {
          conditions.push({
            when: fieldVariable,
            comparison: 'isNotNull',
          });
        }

        if (conditions.length === 1) {
          allConditions.push(conditions[0]);
        } else {
          allConditions.push({
            operator: 'and',
            enabled: true,
            conditions: conditions,
            ...otherConditionData,
          });
        }
      }
    }
  }

  if (!allConditions.length) {
    throw new Error(`No relevant fields found for [${ruleKey}]`);
  }

  return {
    conditions: allConditions,
    variables: allVariables,
    operator: 'or',
    enabled: true,
  };
};
