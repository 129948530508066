/*
  systemKey: string,
  initial?: {
    required: number,
    valid: number,
    count: number,
  },
*/
export function createRating(systemKey?: string, initial = {}) {
  // if (!system) {
  //   throw new Error('System not specified');
  // }
  const rating = {
    systemKey,
    required: 0,
    valid: 0,
    count: 0,
    ...initial,
  };
  return {
    append(other) {
      rating.count += other.count;
      rating.required += other.required;
      rating.valid += other.valid;
      return this;
    },
    add(required, valid) {
      rating.count++;
      if (required) {
        rating.required++;
        if (valid) {
          rating.valid++;
        }
      }

      return this;
    },
    get systemKey() {
      return rating.systemKey;
    },
    get required() {
      return rating.required;
    },
    get valid() {
      return rating.valid;
    },
    get count() {
      return rating.count;
    },
    get score() {
      if (this.count === 0) {
        return 0;
      }
      if (this.required === 0) {
        return 1;
      }
      const score = this.valid / this.required;
      return score;
    },
    get ready() {
      return this.score >= 1;
    },
  };
}

export const emptyRating = (system) => createRating(system);

export const initializeSystemsResultBlock = (systems) => {
  return systems.reduce((prev, system) => {
    return {
      ...prev,
      [system.key]: createRating(system.key),
    };
  }, {});
};
