import { max, min } from '@hogwarts/utils';

import { DateInput } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import React from 'react';
import { parseDate } from '@hogwarts/validation';
import styles from './styles.module.css';

interface DatePickerProps {
  currentDate: string;
  onChange: (date: Date) => void;
  inputProps?: {
    className: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  };
  fill?: boolean;
  shortcuts?: boolean;
  large?: boolean;
  readonly?: boolean;
  placeholder?: string;
  showActionsBar?: boolean;
  highlightCurrentDay?: boolean;
  canClearSelection?: boolean;
}

const dateFormatter = {
  // note that the native implementation of Date functions differs between browsers
  formatDate: (date: Date): string => {
    return (
      date &&
      date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    );
  },
  parseDate: (dateStr: string): Date | null => {
    const timezone = DateTime.now().zoneName;
    const date = parseDate(dateStr, timezone);
    if (date.isValid) {
      return date.toJSDate();
    }
    return null;
  },
};

export const DatePicker = (props: DatePickerProps) => {
  let minDate = new Date('1900-01-01');
  let maxDate = new Date('2099-12-31');

  const value = dateFormatter.parseDate(props.currentDate);
  if (value) {
    // This allows for when the entry is outside of the
    // range, and stops the component exploding.
    minDate = min([value, minDate]);
    maxDate = max([value, maxDate]);
  }

  return (
    <div className="bp3-input-group">
      <span className="bp3-icon bp3-icon-calendar" />
      <DateInput
        className={styles.datePicker}
        {...dateFormatter}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        {...props}
      />
    </div>
  );
};
