import { Duration } from '@hogwarts/ui-components-core';
import React from 'react';
import { getErrorIntent } from './utils';

const DurationComponent = ({
  fieldHelpers,
  fieldMeta,
  readOnly,
  value: currentValue,
}) => {
  if (fieldMeta.error) {
    // show this in red?
  }
  return (
    <Duration
      value={currentValue}
      intent={getErrorIntent(fieldMeta)}
      onChange={(value) => {
        fieldHelpers.setValue(value);
      }}
      readOnly={readOnly}
    />
  );
};

export default DurationComponent;
