import Login from '@hogwarts/ui-components-login';
import { useSinglePrismicDocument } from '@prismicio/react';
import SliceZone from 'next-slicezone';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import authenticationService from '../../services/authentication';
import resolver from '../../sm-resolver';

const LoginContainer = ({ loading }) => {
  const history = useHistory();
  const [loginState, setLoginState] = useState(
    authenticationService.requiresSSO()
      ? 'SSO'
      : authenticationService.requiresMfa()
      ? 'MFA'
      : 'LOG-IN'
  );
  const [error, setError] = useState(null);
  const [lastCredentials, setLastCredentials] = useState(null);

  const [pageContent] = useSinglePrismicDocument('login-page');

  return (
    <Login
      state={loading ? 'LOADING' : loginState}
      error={error}
      lastCredentials={lastCredentials}
      onLogin={async (credentials) => {
        setLoginState('LOGGING-IN');
        setError(null);
        setLastCredentials(credentials);
        const loginError = await authenticationService.login(credentials);
        if (loginError) {
          setError(loginError);
          setLoginState('LOG-IN');
        }
      }}
      onResetPasswordRequest={() => {
        setLoginState('PASSWORD-RESET-REQUEST');
        setError(null);
      }}
      onResetPasswordRequestSubmitted={async (email) => {
        setError(null);
        const resetSuccess = await authenticationService.resetPasswordRequest(
          email
        );
        if (!resetSuccess) {
          setError('Unable to reset password.');
        }
      }}
      onResetPasswordRequestComplete={() => {
        setLoginState('LOG-IN');
        setError(null);
      }}
      onVerifyToken={async (token) => {
        setLoginState('LOGGING-IN');
        try {
          const verified = await authenticationService.verifyMfaToken(token);
          if (verified) {
            history.go(0);
          } else {
            setError('INVALID-TOKEN');
            setLoginState('MFA');
          }
        } catch (error) {
          setError('ERROR');
          setLoginState('MFA');
        }
      }}
      onLogout={() => {
        authenticationService.logout();
      }}
    >
      {pageContent?.data?.slices?.length > 0 && (
        <SliceZone slices={pageContent.data.slices} resolver={resolver} />
      )}
    </Login>
  );
};

export default LoginContainer;
