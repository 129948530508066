import * as yup from 'yup';

// const mapperTypes = Object.keys(mappers);
import { isVariable } from '../utils';
// import * as mappers from '../mappers';


const variableTypes = ['string', 'boolean', 'date', 'number', 'array', 'any'];
const operators = ['and', 'or', 'nand', 'nor'];

const methodSchema = yup.lazy((item) => {
  if (Array.isArray(item)) {
    return yup.array().required();
  }
  return yup.string();
});

const conditionSchema = yup
  .object({
    when: yup.mixed().required(),
    compute: methodSchema,
    comparison: methodSchema,
  })
  .noUnknown();

const variableType = yup.lazy((item) => {
  if (typeof item === 'string') {
    return yup.string().oneOf(variableTypes).required();
  }
  return yup.object({
    type: yup.string().oneOf(variableTypes).required(),
    source: yup.array().min(1).max(2),
  });
});

const variables = yup.lazy((item) => {
  if (!item) return yup.object();
  return yup
    .object(
      Object.keys(item).reduce((prev, key) => {
        if (!isVariable(key)) return prev;
        return {
          ...prev,
          [key]: variableType,
        };
      }, {})
    )
    .noUnknown();
});

const conditionBlock = yup.object({
  conditions: yup.array(
    yup.lazy((item) => {
      if (item.conditions) {
        return conditionBlock;
      }
      return conditionSchema;
    })
  ),
  operator: yup.string().oneOf(operators),
  enabled: yup.boolean(),
});

export const validationSchema = yup
  .object({
    variables,
  })
  .concat(conditionBlock)
  .noUnknown();

export const isValid = (group) => {
  if (!group) {
    throw new Error('Group not provided to validate');
  }

  let result;
  try {
    result = !!validationSchema.validateSync(group, {
      strict: true,
      abortEarly: false,
      context: {
        // variables,
        group,
      },
    });

    return [true];
  } catch (e) {
    // console.log(scheme);
    // console.log(e);
    // console.log(e && e.message);
    result = e.errors || [e.message];
    return [false, result];
  }
};
