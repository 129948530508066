import React, { useMemo } from 'react';
import { Select } from '@hogwarts/ui-components-core';
import { getErrorIntent, selectOptions } from './utils';

const SelectComponent = ({
  fieldKey,
  readOnly,
  fieldMeta,
  fieldHelpers,
  value,
  values: unparsedValues,
  ...rest
}) => {
  const textField = rest.textField || 'value';
  const valueField = rest.valueField || 'id';
  const values = useMemo(
    () => selectOptions(unparsedValues, valueField, textField),
    [unparsedValues]
  );
  return (
    <Select
      id={fieldKey}
      valueField={valueField}
      textField={textField}
      intent={getErrorIntent(fieldMeta)}
      onChange={(item) => {
        fieldHelpers.setValue(item[valueField]);
      }}
      items={values}
      disabled={readOnly}
      large={false}
      fill
      value={value}
      {...rest}
    />
  );
};

export default SelectComponent;
