// @ts-nocheck
import { AnalyticsContext, OrganisationContext } from '@/context';
import { ADD_GROUP_TO_REPORT, DELETE_REPORT_GROUP } from '@/mutations';
import { Button, NonIdealState, Tag } from '@blueprintjs/core';
import { Error, Loading } from '@hogwarts/ui-components-core';
import { currentDateForTimezone, parseDate } from '@hogwarts/validation';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  ProfileListItem,
  ProfileRatings,
} from '../../../../containers/profileListItem';
import {
  useBuildReport,
  useDateFormatter,
  useMutation,
  usePermission,
  useQuery,
  useTransformProfiles,
} from '../../../../hooks';
import {
  DELETE_REPORT,
  UPDATE_REPORT,
  UPDATE_REPORT_OPTIONS,
  UPDATE_REPORT_OPTIONS_Type,
} from '../../../../mutations';
import {
  DATASOURCE_QUERY_EXPIRY_DATES,
  DATASOURCE_QUERY_INSIGHT_GROUPS,
  GET_REPORTS,
} from '../../../../queries';

import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import permissions from '@hogwarts/permissions';
import { StoredReport } from '@hogwarts/scheme-profiles';
import cn from 'classnames';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PageHeader } from '../../../../components';
import AddReportGroup from '../../../../components/Insights/AddReportGroup';
import FilterableInfiniteListView from '../../../../components/Insights/FilterableInfiniteListView';
import { getExportTypeProps } from '../../utils';
import ProfilesFilterBar from '../profiles-grid/filterBar';
import styles from './styles.module.css';
import { useEditReportForm } from './useEditReportForm';

const NoRows = () => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon="zoom-out"
      title={t('No matching Profiles found')}
      description={t<string>(
        "Your filter conditions didn't match any profiles"
      )}
    />
  );
};

const dataSources = {
  expiryDates: {
    query: DATASOURCE_QUERY_EXPIRY_DATES,
    selector: 'data.queryExpiryDates.result',
  },
};

interface ChecksProps {
  profile: {
    expiringFields: Record<
      string,
      {
        label: string;
        fields: {
          key: string;
          label: string;
          expired: boolean;
          expiringSoon: boolean;
          expiryDate: string;
        }[];
      }
    >;
  };
}
const Checks = ({ profile }: ChecksProps) => {
  const formatDate = useDateFormatter();

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {Object.keys(profile.expiringFields).map((sectionKey) => {
          const section = profile.expiringFields[sectionKey];

          return [
            <tr key={`section-${sectionKey}`}>
              <td colSpan={2}>
                <div className={'mr-2 font-weight-bold'}>{section.label}</div>
              </td>
            </tr>,
            ...section.fields.map((field) => (
              <tr key={`field-${field.key}`}>
                <td>
                  <div className={'ml-2 mr-2'}>{field.label}</div>
                </td>
                <td>
                  {field.expired ? (
                    <Tag intent="danger" fill>
                      {`Expired: ${formatDate.medium(field.expiryDate)}`}
                    </Tag>
                  ) : (
                    <Tag
                      intent={field.expiringSoon ? 'warning' : 'primary'}
                      fill
                    >
                      {`Expiring: ${formatDate.medium(field.expiryDate)}`}
                    </Tag>
                  )}
                </td>
              </tr>
            )),
          ];
        })}
      </tbody>
    </table>
  );
};

const itemComponents = {
  Profile: ({ item }: any) => {
    const transform = useTransformProfiles();
    const organisation = useContext(OrganisationContext);
    const history = useHistory();

    const [profile] = transform([item]);
    return (
      <ProfileListItem
        profile={profile}
        showAvatar
        iconPack={organisation.attributes?.avatarIconPack}
        onSelected={(profileId) => {
          analytics.events.dashboard.widget.expiredChecksByProfile.profileSelected();
          history.push(`/${organisation.key}/profiles/${profileId}`);
        }}
      >
        <ProfileRatings ratings={profile.ratings} />
      </ProfileListItem>
    );
  },
  ProfileExpiryCheck: ({ item }: any) => {
    // id
    // cacheKey
    // name
    // typeKey
    // tags
    // fieldKey
    // originalDate
    // expirationDate

    const organisation = useContext(OrganisationContext);
    const analytics = useContext(AnalyticsContext);
    const transform = useTransformProfiles();
    const history = useHistory();

    const profile = transform([item])[0];

    // urgggh
    const days = 14;

    const expirationField = organisation.scheme.getField(profile.fieldKey);
    const expirationDate = parseDate(profile.expirationDate);
    const currentDate = currentDateForTimezone(organisation.timezone);

    const expired = expirationDate < currentDate;
    const expiringSoon =
      !expired && days && expirationDate.minus({ days }) < currentDate;

    const profile2 = {
      ...profile,
      expiringFields: {
        [expirationField.section]: {
          key: expirationField.section,
          label: expirationField.sectionItem?.label,
          fields: [
            {
              key: profile.fieldKey,
              sectionKey: expirationField?.section,
              sectionLabel: expirationField?.sectionItem?.label,
              label: expirationField?.label,
              expiryDate: expirationDate,
              expiringSoon,
              expired,
            },
          ],
        },
      },
    };

    return (
      <ProfileListItem
        profile={profile2}
        showAvatar
        iconPack={organisation.attributes?.avatarIconPack}
        onSelected={(profileId) => {
          analytics.events.dashboard.widget.expiredChecksByProfile.profileSelected();
          history.push(`/${organisation.key}/profiles/${profileId}`);
        }}
      >
        <Checks profile={profile2} />
      </ProfileListItem>
    );
  },
};

export const GroupItem = ({
  group,
  active,
  onGroupSelected,
  onGroupDelete,
  loading,
  groupsEditable,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-row mb-1 align-items-center">
      <Button
        className={styles.tabButton}
        alignText="left"
        active={active}
        intent={active ? 'primary' : null}
        minimal
        icon={group.icon}
        onClick={() => onGroupSelected(group)}
      >
        {t(group.label)}
      </Button>

      <div className="d-flex align-items-center">
        <Tag
          className={styles.tabBadge}
          alignText="center"
          round
          minimal
          intent="primary"
        >
          {group.count}
        </Tag>
      </div>

      {groupsEditable && (
        <Button
          onClick={() => onGroupDelete(group)}
          small
          minimal
          show={!active}
          intent="danger"
          className="ml-1"
          loading={loading}
          disabled={group.key === 'expired'}
        >
          <FontAwesomeIcon icon={['fas', 'trash']} />
        </Button>
      )}
    </div>
  );
};

const Groups = ({
  selected,
  groups,
  onGroupSelected,
  className,
  insight,
  loading,
  currentFilter,
  canEdit,
}) => {
  const { t } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const organisation = useContext(OrganisationContext);
  const [groupsEditable, setGroupsEditable] = useState(false);
  const [deleteReportGroup] = useMutation(DELETE_REPORT_GROUP, {
    refetchQueries: [
      {
        query: DATASOURCE_QUERY_INSIGHT_GROUPS,
        variables: {
          ...currentFilter,
          reportId: insight?.id,
          organisationKey: organisation?.key,
        },
      },
    ],
  });

  const [addGroupToReport] = useMutation(ADD_GROUP_TO_REPORT, {
    selector: 'addGroupToReport',
    refetchQueries: [
      {
        query: DATASOURCE_QUERY_INSIGHT_GROUPS,
        variables: {
          ...currentFilter,
          reportId: insight?.id,
          organisationKey: organisation?.key,
        },
      },
    ],
  });

  const handleGroupDelete = async (group) => {
    analytics.events.insights.deleteReportGroupClicked({
      reportId: insight.id,
      groupKey: group.key,
    });
    await deleteReportGroup({
      variables: {
        reportId: insight.id,
        groupKey: group.key,
      },
    });
  };

  const handleAddInsightGroup = async (
    relativeDate,
    dateComparisonOperator
  ) => {
    const variables = {
      today$: { type: 'date', value: 'now' },
      next$: { type: 'date', source: ['field', 'expiry.expires'] },
    };

    let condition = null;

    if (!relativeDate || !dateComparisonOperator) {
      return;
    }

    condition = {
      variables,
      conditions: [
        {
          when: 'next$',
          comparison: [
            dateComparisonOperator!.key,
            {
              range: relativeDate!.key,
            },
          ],
        },
      ],
    };

    // only take key, label and condition from groups
    const modifiedGroups = groups.map(
      ({ key, label, condition: existingCondition }) => ({
        key,
        label,
        condition: existingCondition,
      })
    );

    const newGroup = {
      key: `${dateComparisonOperator?.key}-${relativeDate?.key}`,
      label: `${dateComparisonOperator?.label} ${relativeDate?.label}`,
      condition,
    };

    analytics.events.insights.addReportGroupClicked({
      reportId: insight.id,
      groups: [...modifiedGroups, newGroup],
    });

    const addGroup = await addGroupToReport({
      variables: {
        reportId: insight.id,
        groups: [...modifiedGroups, newGroup],
      },
    });

    return addGroup;
  };

  return (
    <div className={cn('d-flex flex-column ml-3 mr-3', className)}>
      {groups?.map((group, index) => {
        const active = group === selected;
        return (
          <GroupItem
            group={group}
            active={active}
            onGroupSelected={onGroupSelected}
            onGroupDelete={handleGroupDelete}
            loading={loading}
            groupsEditable={groupsEditable}
          />
        );
      })}
      {canEdit & (groups.length > 0) ? (
        <div className="d-flex flex-row mb-1 align-items-center">
          <Button
            className={styles.editButton}
            alignText="left"
            icon={groupsEditable ? 'remove' : 'edit'}
            onClick={() => {
              analytics.events.insights.editReportGroupsClicked();
              setGroupsEditable(!groupsEditable);
            }}
          >
            {groupsEditable ? t('Finish Editing') : t('Edit')}
          </Button>
        </div>
      ) : null}
      {groupsEditable || groups.length === 0 ? (
        <AddReportGroup
          groups={groups}
          insight={insight}
          loading={loading}
          onAddInsightGroup={handleAddInsightGroup}
        />
      ) : null}
    </div>
  );
};

const ListGroup = ({ insight }: { insight: StoredReport }) => {
  const { t } = useTranslation();
  const apollo = useApolloClient();
  const organisation = useContext(OrganisationContext);
  const analytics = useContext(AnalyticsContext);
  const history = useHistory();

  const [currentFilter, setCurrentFilter] = useState(insight.options);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const hasPermission = usePermission(
    permissions.REPORT_UPDATE,
    insight.ownerId
  );

  const canEdit = !!(hasPermission && insight.options?.preventEditing !== true);

  const canExport = usePermission(permissions.PROFILE_PRINT, organisation.id);

  const [
    exportReport,
    {
      component: BuildingReportComponent,
      props: reportProps,
      disabled: reportDisabled,
    },
  ] = useBuildReport();

  const ItemComponent = useMemo(
    () => itemComponents[insight.options.itemComponent || 'profile'],
    [insight]
  );

  const [updateReport] = useMutation(UPDATE_REPORT, {
    selector: 'updateReport',
    refetchQueries: [
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const [deleteReport] = useMutation(DELETE_REPORT, {
    refetchQueries: [
      {
        query: GET_REPORTS,
        variables: {
          organisationKey: organisation.key,
        },
      },
    ],
  });

  const {
    loading,
    error,
    data: groups,
  } = useQuery(DATASOURCE_QUERY_INSIGHT_GROUPS, {
    variables: {
      ...currentFilter,
      reportId: insight?.id,
      organisationKey: organisation?.key,
    },
    skip: !insight || !organisation,
    selector: 'queryInsightGroups.result',
    transform: (groups) => {
      return groups;
    },
    onCompleted: (data) => {
      const groups = data?.queryInsightGroups?.result;

      if (groups?.length) {
        setSelectedGroup(groups[0]);
      }
    },
  });

  const [persistReportFilters] = useMutation<UPDATE_REPORT_OPTIONS_Type>(
    UPDATE_REPORT_OPTIONS,
    {
      variables: {
        reportId: insight?.id,
        reportOptions: {},
      },
    }
  );

  const [editReportForm] = useEditReportForm({
    onDelete: async () => {
      await deleteReport({
        variables: {
          reportId: insight?.id,
        },
      });
      history.push(`/${organisation.key}/insights`);
    },
    onUpdate: async (values: any) => {
      const options: InsightOptions = {
        ...insight.options,
        ...values.options,
      };
      await updateReport({
        variables: {
          ...values,
          options,
          reportId: insight.id,
        },
      });
      setCurrentFilter({
        ...currentFilter,
        ...options,
      });
    },
  });

  const onFilterHandler = (filters) => {
    const ratingFilter = [];
    for (const filterKey of Object.keys(filters)) {
      if (filterKey.startsWith('ratingSystem_')) {
        ratingFilter.push({
          key: filterKey.split('_')[1],
          include: filters[filterKey][0],
        });
      }
    }
    setCurrentFilter({
      ...currentFilter,
      profileTypes: filters.profileTypes ?? [],
      tags: filters.tags ?? [],
      ratings: ratingFilter,
    });
  };

  const actions = useMemo(() => {
    const exportTypes = Array.isArray(insight.exportType)
      ? insight.exportType
      : insight.exportType !== null
      ? [insight.exportType]
      : [];

    const exportActions = exportTypes.map((exportType) => ({
      // Intent, Icon + Text
      ...getExportTypeProps(exportType, canExport),
      allowed: !reportDisabled,
      onClick: () => {
        analytics.events.insights.exportViewClicked();
        return exportReport(insight.id, exportType, currentFilter);
      },
    }));

    if (canEdit) {
      const editAction = {
        icon: 'edit',
        text: t('Edit'),
        allowed: !reportDisabled,
        onClick: () => {
          analytics.events.insights.editDetail();
          return editReportForm({
            initialValues: {
              ...insight,
              options: {
                ...insight.options,
              },
            },
          });
        },
      };

      exportActions.unshift(editAction);
    }
    return exportActions;
  }, [
    canExport,
    currentFilter,
    exportReport,
    insight,
    reportDisabled,
    analytics.events.insights,
    t,
    canEdit,
    editReportForm,
  ]);

  const persistOptions = (options) => {
    if (!canEdit) return;
    return persistReportFilters({
      variables: {
        reportOptions: {
          ...insight.options,
          filters: options.filters,
        },
      },
    });
  };

  const fetchMore = useCallback(
    async (skip, limit) => {
      if (!organisation || !selectedGroup || !insight) return;

      const { query, selector } = dataSources[insight.sourceType];

      const variables = {
        ...currentFilter,
        organisationKey: organisation.key,
        groupCondition: selectedGroup.condition,

        skip,
        limit,
      };

      let result = await apollo.query({
        query,
        fetchPolicy: 'no-cache',
        variables,
      });
      result = get(result, selector);
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilter, insight.sourceType, selectedGroup]
  );

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <>
      <PageHeader noWrapper actions={actions} />
      <BuildingReportComponent {...reportProps} />
      <div className="container-lg d-flex flex-column flex-grow-1">
        <div className={styles.filterBar}>
          <ProfilesFilterBar
            initialFilters={currentFilter}
            onFilter={onFilterHandler}
          />
        </div>

        <div className="d-flex flex-grow-1">
          <Groups
            className={styles.groupContainer}
            selected={selectedGroup}
            onGroupSelected={setSelectedGroup}
            groups={groups}
            insight={insight}
            loading={loading}
            currentFilter={currentFilter}
            canEdit={canEdit}
          />
          <FilterableInfiniteListView
            className="flex-grow-1"
            border
            fetchMore={fetchMore}
            component={ItemComponent}
            emptyComponent={NoRows}
            spreadRowItem={false}
            persistOptions={persistOptions}
            defaultFilters={insight.options?.filters}
          />
        </div>
      </div>
    </>
  );
};

export default ListGroup;
