import { get } from 'lodash';

type TokenSelector = {
  condition: any;
  value: any;
};
type Token = {
  key: string;
  value: any;
  selectors?: TokenSelector[];
};

type TemplateSelector = {
  condition?: any;
  value: string;
};
type Template = {
  tokens?: Token[];
  selectors: TemplateSelector[];
};

type Options = {
  removeUnused: boolean;
};

const getKeys = (template: string) => {
  let index = 0;
  let keys = [];
  while (true) {
    let start = template.indexOf('{{', index);
    if (start === -1) {
      break;
    }
    let end = template.indexOf('}}', start);
    if (end === -1) {
      break;
    }
    let result = template.substring(start + 2, end);
    index = end;
    const [key, format] = result.split(':');
    keys.push({
      key,
      format: format || null,
    });
  }
  return keys;
};

type FormatValueHandler = (value: any, format: any) => any;

export const applyTemplateFactory = (
  evaluateCondition,
  formatValue: FormatValueHandler
) => {
  const parseTemplateString = (
    template: string,
    props: any,
    options: Options
  ) => {
    const templateKeys = getKeys(template);
    let result = template;
    for (const { key, format } of templateKeys) {
      let value = get(props, key);
      if (value != null) {
        // if (Array.isArray(value)) {
        //   const items = [];
        //   for (const item of value) {
        //     items.push(__build(item));
        //   }
        //   value = items.join('\n');
        // }
        // if (typeof value === 'object' && value.template) {
        //   value = __build(value);
        // }
        if (format) {
          value = formatValue(value, format);
        }
      }

      if (value == null && options?.removeUnused) {
        // missing data. for moment we just put in
        // a blank value
        value = '';
      }

      if (value != null) {
        result = result.replace(
          `{{${key}${format ? `:${format}` : ''}}}`,
          value
        );
      }
    }
    return result;
  };

  const calculateTokens = (tokens: Token[], props: any, options: Options) => {
    if (!tokens) return {};

    let tokenValues = {};
    for (const token of tokens) {
      const { value, selectors } = token;

      if (selectors) {
        for (const selector of selectors) {
          if (selector.condition != null) {
            if (
              evaluateCondition(selector.condition, props, tokenValues) !== true
            ) {
              continue;
            }
            tokenValues[token.key] = parseTemplateString(
              selector.value,
              props,
              options
            );
          }
        }
      }

      if (typeof tokenValues[token.key] === 'undefined') {
        tokenValues[token.key] = parseTemplateString(value, props, options);
      }
    }

    return tokenValues;
  };

  return (templateBody: string | Template, props?: any, options?: Options) => {
    if (templateBody == null) {
      return null;
    }

    if (typeof templateBody === 'string') {
      return parseTemplateString(templateBody, props, options);
    }

    const { tokens, selectors } = templateBody;

    const tokenValues = calculateTokens(tokens, props, options);

    if (selectors) {
      for (const selector of selectors) {
        if (selector.condition != null) {
          if (
            evaluateCondition(selector.condition, props, tokenValues) !== true
          ) {
            continue;
          }
        }

        return parseTemplateString(
          selector.value,
          {
            ...props,
            ...tokenValues,
          },
          options
        );
      }
    }

    return null;
  };
};
