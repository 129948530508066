import { ConditionGroup } from '@hogwarts/conditionals';
import { DateTime } from 'luxon';
import { applyTemplateFactory } from '@hogwarts/templating';
import { evaluateGroup } from '../conditionals';
import { parseDate } from '@hogwarts/validation';

const formatValue = (value: any, format: string) => {
  switch (format) {
    case 'longdate': {
      format = 'DATE_FULL';
      break;
    }
  }

  if (format.startsWith('DATE_')) {
    let date2 = parseDate(value);
    if (date2.isValid) {
      const formatted = date2
        .setLocale('en-GB')
        .toLocaleString(DateTime[format]);
      return formatted;
    }
    return null;
  }
  return value;
};

const evaluateCondition = (
  conditionGroup: ConditionGroup,
  values: any,
  tokenValues: any
) => {
  const { tags, profileTypeKey, typeKey, ...rest } = values;

  const data = {
    tags,
    typeKey: typeKey || profileTypeKey,
    valid: {},
    values: rest,
    visible: Object.keys(rest).reduce(
      (prev, key) => ({
        ...prev,
        [key]: true,
      }),
      {}
    ),
  };

  return evaluateGroup(conditionGroup, tokenValues, data, {
    timezone: 'utc',
  });
};

export const applyTemplate = applyTemplateFactory(
  evaluateCondition,
  formatValue
);

export const applyProfileTemplate = (template, profile) => {
  let visibleData = Object.keys(profile.visible).reduce((prev, fieldKey) => {
    if (!profile.visible[fieldKey]) return prev;
    const fieldKeyExpiry = `${fieldKey}_expiry`;
    if (profile.data[fieldKeyExpiry]) {
      return {
        ...prev,
        [fieldKey]: profile.data[fieldKey],
        [fieldKeyExpiry]: profile.data[fieldKeyExpiry],
      };
    }
    return { ...prev, [fieldKey]: profile.data[fieldKey] };
  }, {});

  visibleData = {
    ...visibleData,
    tags: profile.tags,
    typeKey: profile.typeKey || profile.profileTypeKey,
  };

  return applyTemplate(template, visibleData, {
    removeUnused: true,
  });
};
