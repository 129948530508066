import { mergeConditions } from '@hogwarts/conditionals';
import { assertArgs, keys, replaceTokens } from '@hogwarts/utils';
import { Field, Issue, Scheme } from '@hogwarts/utils-schemes';
import { emptyValue } from '@hogwarts/validation';
import log from '@scrtracker/logging';
import { DateTime } from 'luxon';
import { evaluateGroup } from '../conditionals';
import { getExpiryDate } from '../expiringChecks';
import { dataTypeValidation } from './dataTypeValidation';
import { ratingsValidation } from './ratingsValidation';

type RuleHandlerResult = any;
type RuleHandler = (
  value: any,
  rule: any,
  data: any,
  timezone: string
) => RuleHandlerResult;
type RuleHandlers = {
  [key: string]: RuleHandler;
};

const ruleHandlers: RuleHandlers = {
  function: (value, rule, data, timezone) => {
    const x = rule.execute(value);
    return x;
  },
  regex: (value, rule, data, timezone) => {
    const { pattern } = rule;
    if (!pattern) return true;
    const regex = new RegExp(pattern, 'gi');
    const result = regex.test(value);
    return !!result;
  },
  condition: (value, rule, data, timezone) => {
    // Need to get the params
    // Also any overides?

    let params = rule.paramValues;
    let otherVariables = {};
    // If the rule has params, then for each param, turn
    // that into a variable using the value off the rule
    if (rule.params) {
      for (const paramKey of Object.keys(rule.params)) {
        let definedParam = rule.params[paramKey];
        let value = params && params[paramKey];
        if (value == null) {
          value = definedParam.value;
        }
        otherVariables[`${paramKey}$`] = {
          type: definedParam.dataType,
          value,
        };
      }
    }

    let result;
    try {
      if (value == null) {
        // condition will fail with null
        // so just short circuit it
        return true;
      }

      // invert the condition
      // so a pass means its invalid
      result = !evaluateGroup(
        mergeConditions(rule.condition, { variables: otherVariables }),
        {
          field$: value,
        },
        data,
        {
          timezone,
        }
      );
    } catch (e) {
      log.debug(e.message);
      return null;
    }

    return result;
  },
};

const processMessage = (
  value: any,
  rule: any,
  message: string,
  timezone: string
) => {
  if (!message) return null;

  if (rule.key === 'expiring_date_check') {
    const expiryDate = getExpiryDate(rule.duration, value, timezone);
    // TODO: Format date as per user settings
    return replaceTokens(message, {
      expiryDate: expiryDate.toLocaleString(DateTime.DATE_MED),
    });
  }

  return message;
};

type Success = [true, Issue[]?];
type Fail = [false, Issue[]];
type Result = Success | Fail;

const runValidationRules = (
  scheme: Scheme,
  field: Field,
  value: any,
  data: any,
  timezone: string
): Result => {
  let rules = [];
  const dataTypeRule = dataTypeValidation[field.dataType];
  if (!dataTypeRule) {
    log.warn(`No built-in rule for DataType [${field.dataType}]`);
  }
  rules.push(dataTypeRule);

  if (field.ratings) {
    const required = Object.keys(field.ratings).reduce((prev, key) => {
      if (prev) return true;
      return !!field.ratings[key].enabled;
    }, false);

    if (required) {
      // log.debug(`[${field.key}]: Is a rated field`);
      const ratingsRule = ratingsValidation[field.dataType];
      if (!ratingsRule) {
        log.warn(`No built-in ratings rule for DataType [${field.dataType}]`);
      }
      rules.push(ratingsRule);
    }
  }

  if (field.validation) {
    for (const validationRule of keys(field.validation, {
      includeNulls: false,
    })) {
      if (!validationRule?.type) continue;
      switch (validationRule.type) {
        case 'rule': {
          const baseRule = scheme.getValidationRule(validationRule.rule);
          if (baseRule) {
            const { key, rule, type, ...paramValues } = validationRule;

            rules.push({
              ...validationRule,
              ...baseRule,
              paramValues,
            });
          }
          break;
        }
        default: {
          rules.push(validationRule);
          break;
        }
      }
    }
  }
  rules = rules.filter((r) => r && !r.deleted);

  let valid = true;
  const issues: Issue[] = [];
  for (const rule of rules) {
    if (rule.enabled === false) {
      // log.debug(`[${field.key}]: Rule [${rule.key}] is not enabled, skipping`);
      continue;
    }

    if (rule.ignoreEmpty && emptyValue(value)) {
      continue;
    }

    const handler = ruleHandlers[rule?.type];
    const ruleResult = handler ? handler(value, rule, data, timezone) : null;

    // log.debug(`[${field.key}]: Rule [${rule.key}] result: [${ruleResult}]`);
    if (ruleResult !== true) {
      if (rule.failInput === true || rule.failRatings === true) {
        valid = false;
      }
      if (rule.message) {
        issues.push({
          severity: rule.severity,
          message: processMessage(value, rule, rule.message, timezone),
          failInput: rule.failInput === true,
          failRatings: rule.failRatings === true,
          dismissable: rule.dismissable === true,
        });
      }
    } else if (ruleResult === true && rule.whenValid?.message) {
      issues.push({
        severity: 'information',
        failInput: false,
        failRatings: false,
        dismissable: false,
        ...rule.whenValid,
        message: processMessage(value, rule, rule.whenValid?.message, timezone),
      });
    }
  }

  return [valid, issues];
};

// this is for scoring and if
// the value passes scoring IF its required.
export function calculateFieldValidity(
  scheme: Scheme,
  field: Field,
  value: any,
  data: any,
  timezone: string
): Result {
  assertArgs({ field, data, timezone });

  // log.debug(`Calculating Field Validity [${field.key}]`);

  if (typeof data !== 'object') {
    throw new Error('Expected data to be an object');
  }

  if (!field.key) throw new Error(`Invalid Field. Has no key`);
  if (!field.dataType) throw new Error(`Invalid Field. Has no dataType`);

  if (field.dataType === 'none') {
    // log.debug(`[${field.key}]: No data type, returning true`);
    return [true];
  }

  const [valid, issues] = runValidationRules(
    scheme,
    field,
    value,
    data,
    timezone
  );

  return [valid, issues];
}
