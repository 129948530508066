import ReferralsSettings from '@/components/Settings/Referrals';
import { UserContext } from '@/context';
import { useMutation } from '@/hooks';
import { ENABLE_REFERRAL_CODE } from '@/mutations';
import { GET_CURRENT_USER } from '@/queries';
import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useSinglePrismicDocument } from '@prismicio/react';

const ReferralsSettingsContainer = () => {
  const user = useContext(UserContext);
  const [componentState, setComponentState] = useState('LOADING');

  useEffect(() => {
    if (user.referralCode) {
      setComponentState('ENABLED');
    }
  }, [user]);

  const [pageContent] = useSinglePrismicDocument('settings-referral-program');

  useEffect(() => {
    if (!pageContent) {
      setComponentState('LOADING');
    } else {
      setComponentState(user.referralCode ? 'ENABLED' : 'DISABLED');
    }
  }, [pageContent, user.referralCode]);

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    // Note, this is vital to ensure cache is being updated
    fetchPolicy: 'network-only',
  });
  const [enableReferralCode] = useMutation(ENABLE_REFERRAL_CODE, {
    selector: 'enableReferralCode',
    fetchPolicy: 'no-cache',
  });
  return (
    <ReferralsSettings
      state={componentState}
      pageContent={pageContent?.data}
      referralCode={user.referralCode}
      onEnableReferralCode={async () => {
        setComponentState('ENABLING');
        await enableReferralCode();
        getCurrentUser();
      }}
    />
  );
};

export default ReferralsSettingsContainer;
