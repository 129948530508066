import React from 'react';
import { PrismicRichText } from '@prismicio/react';
import { AnchorButton, Card } from '@blueprintjs/core';

import styles from './styles.module.css';

const AnnouncementBlock = ({ slice }) => {
  const htmlSerialiser = {
    image: ({ node, key }) => {
      const img = (
        <img
          src={node.url}
          alt={node.alt ?? undefined}
          data-copyright={node.copyright ? node.copyright : undefined}
          className={styles.contentImage}
        />
      );

      return (
        <p key={key} className="block-img">
          {img}
        </p>
      );
    },
  };

  return (
    <Card elevation={2}>
      <span className="title">
        {slice.primary.title && <PrismicRichText field={slice.primary.title} />}
      </span>
      {slice.primary.content && (
        <PrismicRichText
          field={slice.primary.content}
          components={htmlSerialiser}
        />
      )}
      {slice.primary.buttonLabel && slice.primary.buttonLink && (
        <div className={styles.buttonContainer}>
          <AnchorButton
            large
            intent={slice.primary.buttonIntent}
            href={slice.primary.buttonLink}
            target={slice.primary.buttonTargetBlank ? '_blank' : ''}
          >
            {slice.primary.buttonLabel}
          </AnchorButton>
        </div>
      )}
    </Card>
  );
};

export default AnnouncementBlock;
