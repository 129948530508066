import { assertArgs } from '@hogwarts/utils';
import { calculate } from '@hogwarts/calculated-functions';
import { convertValue } from './convertValue';
import { emptyValue } from '@hogwarts/validation';

type Options = {
  dateFormat: 'luxon' | 'date' | 'string';
  timezone?: string;
};

type Field = {
  key: string;
  dataType: string;
  enabled?: boolean;
  arrayFields?: Field[];
  forcedValue?: any;
  calculation?: any;
  defaultValue?: any;
  visible?: boolean;
};

type Section = {
  enabled?: boolean;
  fields: Field[];
};

type Scheme = {
  sections: Section[];
};

const getValue = (field: Field, profile: any, options: Options) => {
  let value = profile.data[field.key];
  if (field.forcedValue != null) {
    value = field.forcedValue;
  } else if (field.calculation) {
    // dont waste energy calculating it.
    // this will be calculated in the UI anyway
    // and on save, should it get shown.
    if (field.visible === false) {
      return undefined;
    }
    value = calculate(profile, field.calculation);
  }

  if (typeof value === 'undefined') {
    if (!emptyValue(field.defaultValue)) {
      value = field.defaultValue;
    } else {
      return undefined;
    }
  }

  if (field.dataType == null) {
    throw new Error(`No Data Type specified on field [${field.key}]`);
  }

  if (emptyValue(value)) {
    return null;
  }

  return convertValue(field.dataType, value, options);
};

const getExpiredValue = (field: Field, profile: any, options: Options) => {
  let value = profile.data[`${field.key}_expiry`];
  if (emptyValue(value)) {
    return null;
  }
  return convertValue(field.dataType, value, options);
};

const parseFieldArray = (
  profile: any,
  fields: Field[],
  fieldData: any[],
  options: Options
) => {
  // no fields defined?
  if (!Array.isArray(fields)) return [];
  // data is corrupt?
  if (!Array.isArray(fieldData)) return [];
  const result = [];
  for (const rowData of fieldData) {
    const row = {};
    if (rowData) {
      for (const field of fields) {
        if (field.enabled === false) continue;
        const value = getValue(
          field,
          {
            ...profile,
            data: rowData,
          },
          options
        );
        if (typeof value !== 'undefined') {
          row[field.key] = value;
          const expired = getExpiredValue(field, profile, options);
          if (expired != null) {
            row[`${field.key}_expiry`] = expired;
          }
        }
      }
    }
    result.push(row);
  }
  return result;
};

export const reduceProfile = (
  scheme: Scheme,
  profile: any,
  options: Options
) => {
  assertArgs({ scheme });
  if (!profile?.data) return {};

  const profile2 = {
    id: profile.id,
    data: profile.data || {},
    attributes: profile.attributes || {},
    meta: profile.meta || {},
  };

  if (!scheme.sections) {
    return {};
  }
  const result = {};
  for (const section of scheme.sections) {
    if (section.enabled === false) continue;
    for (const field of section.fields) {
      if (field.enabled === false) continue;

      let value = getValue(field, profile2, options);
      if (field.dataType === 'array') {
        value = parseFieldArray(profile2, field.arrayFields, value, options);
      }
      if (typeof value !== 'undefined') {
        result[field.key] = value;
        const expired = getExpiredValue(field, profile, options);
        if (expired != null) {
          result[`${field.key}_expiry`] = expired;
        }
      }
    }
  }

  return result;
};

export const reduceProfileData = (
  scheme: Scheme,
  profileData: any,
  profileAttributes?: any,
  profileMeta?: any,
  options?: Options
) => {
  return reduceProfile(
    scheme,
    {
      data: profileData || {},
      attributes: profileAttributes || {},
      meta: profileMeta || {},
    },
    options
  );
};
