import React, { useState } from 'react';
import { Label, InputGroup, Button, Callout } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const InvalidResetPage = ({ resetError, onResetPassword, onResetEnd }) => {
  const { t } = useTranslation();

  if (resetError === 'INVALID-ID') {
    return (
      <>
        <p className={styles.headerMessage}>{t('Invalid Reset Code')}</p>
        <p className={styles.secondaryMessage}>
          {t(
            'We cannot verify the reset code provided. This normally means that your code has expired. Please click below to send another reset code.'
          )}
        </p>
        <p className={styles.secondaryMessage}>
          {t(
            "If you continue to experience this issue please contact help@scrtracker.com and we'll be able to help you further."
          )}
        </p>
        <div className={styles.rightButton}>
          <Button large intent="warning" onClick={() => onResetPassword()}>
            {t('Reset Password')}
          </Button>
        </div>
      </>
    );
  } else if (resetError === 'INVALID-URL') {
    return (
      <>
        <p className={styles.headerMessage}>{t('Invalid Reset URL')}</p>
        <p className={styles.secondaryMessage}>
          {t(
            'There is a problem with the password reset link you followed. If you copied this from your email please try again.'
          )}
        </p>
        <p className={styles.secondaryMessage}>
          {t(
            "If you continue to experience this issue please contact help@scrtracker.com and we'll be able to help you further."
          )}
        </p>
        <div className={styles.rightButton}>
          <Button large onClick={() => onResetEnd()}>
            {t('Go Back')}
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <p className={styles.headerMessage}>{t('Unknown Error')}</p>
        <p className={styles.secondaryMessage}>
          {t('Something has gone wrong, please try again.')}
        </p>
        <p className={styles.secondaryMessage}>
          {t(
            "If you continue to experience this issue please contact help@scrtracker.com and we'll be able to help you further."
          )}
        </p>
        <div className={styles.rightButton}>
          <Button large className="mr-1" onClick={() => onResetEnd()}>
            {t('Go Back')}
          </Button>
          <Button large intent="warning" onClick={() => onResetPassword()}>
            {t('Reset Password')}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      {resetError ? (
        <Callout
          className={styles.errorMessage}
          intent="danger"
          title="Error Setting Password"
        >
          {t(resetError)}
        </Callout>
      ) : (
        <p className={styles.headerMessage}>{t('Set New Password')}</p>
      )}
      <p className={styles.secondaryMessage}>
        {t(
          'To complete your password reset please enter a new password below.'
        )}
      </p>
    </>
  );
};

export default InvalidResetPage;
