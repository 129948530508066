import { assertArgs } from '@hogwarts/utils';
import log from '@scrtracker/logging';
import { ConditionalDataError, ConditionalStructureError } from './errors';
import evaluateCondition from './evaluateCondition';
import {
  Condition,
  ConditionGroup,
  Operator,
  Options,
  VariableValues,
  Variables,
} from './types';
import { convertVariables, isConditionGroup } from './utils';
import { isValid } from './validation';

const evaluateConditions = (
  values: Variables,
  conditions: (Condition | ConditionGroup)[],
  operator: Operator,
  options: Options
): boolean => {
  assertArgs({ values, conditions, options, timezone: options.timezone });

  if (!Array.isArray(conditions)) {
    throw new ConditionalStructureError('Conditions must be an array');
  }

  if (operator == null) {
    operator = 'and';
  }

  const and = operator === 'and' || operator === 'nand';
  const not = operator === 'nand' || operator === 'nor';
  const or = operator === 'or' || operator === 'nor';

  let answer: boolean = true;
  for (let condition of conditions) {
    if (!condition) {
      throw new ConditionalStructureError('Condition was null');
    }

    let result: boolean;
    // check for sub group
    if (isConditionGroup(condition)) {
      result = evaluateConditions(
        values,
        condition.conditions,
        condition.operator,
        options
      );
    } else if (typeof condition.when !== 'undefined') {
      result = evaluateCondition(condition, values, options);
    } else {
      continue;
    }

    if (result === false) {
      answer = false;
      if (and) {
        break;
      }
    } else if (result === true) {
      if (or) {
        answer = true;
        break;
      }
    } else {
      log.debug('Result came back as non boolean', {
        result,
      });
      throw new ConditionalDataError(
        'Condition evaluation came back as non boolean'
      );
    }
  }

  if (not) {
    return !answer;
  }

  return answer;
};

export default (
  group: ConditionGroup,
  variableValues: VariableValues,
  options: Options
) => {
  if (process.env.NODE_ENV === 'test') {
    const [valid, reasons] = isValid(group);
    if (!valid) {
      log.debug('Invalid Condition', {
        groupStr: JSON.stringify(group, null, 2),
      });
      throw new ConditionalStructureError(reasons);
    }
  }

  assertArgs({ group, variableValues, options });
  if (!group) {
    return null;
  }

  let { conditions, variables, enabled, operator } = group;

  // has to be explicitly set to off
  // otherwise its assumed its on
  if (enabled === false) {
    return true;
  }

  // if theres no conditions, then why try and run it
  if (Array.isArray(conditions) && conditions.length === 0) {
    return true;
  }

  if (!variables) variables = {};

  let [success, convertedVariables] = convertVariables(
    variables,
    variableValues,
    options
  );
  if (!success) {
    log.debug('Failed to convert variables');
    return null;
  }

  for (const key of Object.keys(variables)) {
    if (typeof convertedVariables[key] === 'undefined') {
      log.warn(`Variable ${key} is undefined`);
      return null;
    }
  }

  const answer = evaluateConditions(
    convertedVariables,
    conditions,
    operator,
    options
  );

  return answer;
};
