const getProfileTypeParents = (schemes: any[]) => {
  const profileTypes = {};
  for (const scheme of schemes) {
    for (const profileTypeKey of Object.keys(scheme.data.profileTypes || {})) {
      if (!profileTypes[profileTypeKey]) {
        profileTypes[profileTypeKey] =
          scheme.data.profileTypes[profileTypeKey].parent || null;
      }
    }
  }
  return profileTypes;
};

const getProfileTypeOrder = (schemes: any, profileTypeKey?: string) => {
  if (!profileTypeKey) return [];

  const profileTypes = getProfileTypeParents(schemes);

  let parentKey = profileTypeKey;
  let result = [];
  while (parentKey) {
    result.unshift(parentKey);
    parentKey = profileTypes[parentKey];
  }

  return result;
};

const bindScheme = (scheme, schemePatch, profileTypeKeys) => {
  const layers = [];

  const { profileTypeSchemes, ...schemeData } = scheme.data || {};

  // Push the main data block
  layers.push({
    ...scheme,
    data: schemeData,
  });

  // Push the patch main data block
  const { profileTypeSchemes: profileTypePatchSchemes, ...schemePatchData } =
    schemePatch?.data || {};
  if (schemePatch) {
    layers.push({
      ...schemePatch,
      data: schemePatchData,
      patch: true,
    });
  }

  // For each profile type key (follows the hierachy)
  for (const profileTypeKey of profileTypeKeys) {
    // main scheme block
    const profileTypeLayerData =
      profileTypeSchemes && profileTypeSchemes[profileTypeKey];
    layers.push({
      ...scheme,
      variant: profileTypeKey,
      data: profileTypeLayerData || {},
    });

    // apply patch
    if (schemePatch) {
      const patchProfileTypeLayerData =
        profileTypePatchSchemes && profileTypePatchSchemes[profileTypeKey];
      layers.push({
        ...scheme,
        variant: profileTypeKey,
        data: patchProfileTypeLayerData || {},
        patch: true,
      });
    }
  }

  return layers;
};

export const weavePatchSchemes = (schemes: any[], profileTypeKey?: string) => {
  const layers = [];

  const profileTypeKeys = getProfileTypeOrder(schemes, profileTypeKey);

  const groupedSchemes = [];
  for (const scheme of schemes) {
    if (scheme.patch) {
      groupedSchemes[groupedSchemes.length - 1].patchScheme = scheme;
    } else {
      groupedSchemes.push({
        scheme,
      });
    }
  }

  for (const { scheme, patchScheme } of groupedSchemes) {
    layers.push(...bindScheme(scheme, patchScheme, profileTypeKeys));
  }

  return layers;
};
