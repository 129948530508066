import { Button } from '@blueprintjs/core';
import { Select } from '@hogwarts/ui-components-core';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { DatePicker } from '../../DatePicker';
import { getErrorIntentClass } from './utils';

export const relatives = [
  // Relative date intervals:
  {
    group: true,
    label: 'Relative date intervals',
  },
  {
    key: 'lastyear',
    label: 'last year',
  },
  {
    key: 'lastquarter',
    label: 'last quarter',
  },
  {
    key: 'lastmonth',
    label: 'last month',
  },
  {
    key: 'lastweek',
    label: 'last week',
  },

  {
    key: 'thisyear',
    label: 'this year',
  },
  {
    key: 'thisquarter',
    label: 'this quarter',
  },
  {
    key: 'thismonth',
    label: 'this month',
  },
  {
    key: 'thisweek',
    label: 'this week',
  },

  {
    key: 'nextweek',
    label: 'next week',
  },
  {
    key: 'nextmonth',
    label: 'next month',
  },
  {
    key: 'nextquarter',
    label: 'next quarter',
  },
  {
    key: 'nextyear',
    label: 'next year',
  },

  {
    group: true,
    label: 'Relative dates',
  },

  {
    key: '3yearsago',
    label: '3 years ago',
  },
  {
    key: '2yearsago',
    label: '2 years ago',
  },
  {
    key: '1yearago',
    label: '1 year ago',
  },

  {
    key: '11monthsago',
    label: '11 months ago',
  },
  {
    key: '10monthsago',
    label: '10 months ago',
  },
  {
    key: '9monthsago',
    label: '9 months ago',
  },
  {
    key: '8monthsago',
    label: '8 months ago',
  },
  {
    key: '7monthsago',
    label: '7 months ago',
  },

  {
    key: '6monthsago',
    label: '6 months ago',
  },
  {
    key: '5monthsago',
    label: '5 months ago',
  },
  {
    key: '4monthsago',
    label: '4 months ago',
  },
  {
    key: '3monthsago',
    label: '3 months ago',
  },
  {
    key: '2monthsago',
    label: '2 months ago',
  },
  {
    key: '1monthago',
    label: '1 month ago',
  },
  {
    key: '3weeksago',
    label: '3 weeks ago',
  },
  {
    key: '2weeksago',
    label: '2 weeks ago',
  },
  {
    key: '1weekago',
    label: '1 week ago',
  },

  {
    key: 'yesterday',
    label: 'yesterday',
  },
  {
    key: 'beforetoday',
    label: 'before today',
  },

  {
    key: 'today',
    label: 'today',
  },

  {
    key: 'todayorlater',
    label: 'today or later',
  },
  {
    key: 'tomorrow',
    label: 'tomorrow',
  },
  {
    key: 'tomorroworlater',
    label: 'tomorrow or later',
  },
  {
    key: 'in1week',
    label: 'in 1 week',
  },
  {
    key: 'in2weeks',
    label: 'in 2 weeks',
  },
  {
    key: 'in3weeks',
    label: 'in 3 weeks',
  },
  {
    key: 'in1month',
    label: 'in 1 month',
  },
  {
    key: 'in2months',
    label: 'in 2 months',
  },
  {
    key: 'in3months',
    label: 'in 3 months',
  },
  {
    key: 'in4months',
    label: 'in 4 months',
  },
  {
    key: 'in5months',
    label: 'in 5 months',
  },
  {
    key: 'in6months',
    label: 'in 6 months',
  },

  {
    key: 'in7months',
    label: 'in 7 months',
  },
  {
    key: 'in8months',
    label: 'in 8 months',
  },
  {
    key: 'in9months',
    label: 'in 9 months',
  },
  {
    key: 'in10months',
    label: 'in 10 months',
  },
  {
    key: 'in11months',
    label: 'in 11 months',
  },

  {
    key: 'in1year',
    label: 'in 1 year',
  },
  {
    key: 'in2years',
    label: 'in 2 years',
  },
  {
    key: 'in3years',
    label: 'in 3 years',
  },

  {
    group: true,
    label: 'Academic year',
  },
  {
    key: 'lastacademicyear',
    label: 'last academic year',
  },
  {
    key: 'thisacademicyear',
    label: 'this academic year',
  },
  {
    key: 'nextacademicyear',
    label: 'next academic year',
  },
];

const modes = [
  {
    key: 'relative-minus',
    label: 'Relative',
    Component: ({ readOnly, value, fieldHelpers, ...props }) => {
      // TODO: If value === 'relative'
      // show a text box as well for the number
      // of days (also inline)

      if (value === 'relative_days') {
        // show a textbox for number of days
      }

      return (
        <Select
          valueField="id"
          textField="value"
          // intent={getErrorIntent(fieldMeta)}
          onChange={(item) => {
            fieldHelpers.setValue({
              range: item.key,
              range_source: 'value',
              value: undefined,
              value_source: undefined,
            });
          }}
          items={relatives}
          disabled={readOnly}
          large={false}
          fill
          value={value?.range}
          showEmpty={false}
          {...props}
          groupKey={null}
        />
      );
    },
  },
  {
    key: 'value',
    label: 'Exact',
    Component: ({
      value: currentValue,
      readOnly,
      fieldMeta,
      large,
      placeholder,
      fieldHelpers,
    }) => {
      return (
        <DatePicker
          inputProps={{
            className: cn(large && 'bp3-large', getErrorIntentClass(fieldMeta)),
          }}
          large
          highlightCurrentDay
          fill
          shortcuts
          showActionsBar
          onChange={(date) => {
            let text = null;
            if (date) {
              text = date.toISOString().substring(0, 10);
            }
            fieldHelpers.setValue({
              range: 'exact',
              range_source: 'value',
              value: text,
              value_source: 'value',
            });
          }}
          placeholder={placeholder}
          disabled={readOnly}
          currentDate={currentValue?.value}
        />
      );
    },
  },
  {
    key: 'field',
    label: 'Field',
    Component: ({ readOnly, value, values, fieldHelpers, ...props }) => {
      return (
        <Select
          // id={fieldKey}
          valueField="id"
          textField="value"
          // intent={getErrorIntent(fieldMeta)}
          onChange={(item) => {
            fieldHelpers.setValue({
              range: 'exact',
              range_source: 'value',
              value: item.key,
              value_source: 'field',
            });
          }}
          items={values}
          disabled={readOnly}
          large={false}
          fill
          value={value?.value}
          {...props}
        />
      );
    },
  },
];

const RelativeDateComponent = ({
  value: currentValue,
  fieldMeta,
  fieldHelpers,
  readOnly,
  ...props
}) => {
  const [modeIndex, setMode] = useState(() => {
    if (!currentValue) return 0;

    if (
      currentValue.range === 'exact' &&
      currentValue.value_source === 'field'
    ) {
      return modes.findIndex((m) => m.key === 'field');
    }

    if (
      currentValue.range === 'exact' &&
      currentValue.value_source === 'value'
    ) {
      return modes.findIndex((m) => m.key === 'value');
    }

    if (currentValue.range !== 'exact') {
      return modes.findIndex((m) => m.key === 'relative-minus');
    }

    return 0;
  });

  const cycleMode = useCallback(() => {
    let nextMode = (modeIndex + 1) % modes.length;
    setMode(nextMode);
  }, [modeIndex]);

  let currentMode = modes[modeIndex];

  return (
    <div className={'d-flex flex-row w-100'}>
      <div className="flex-grow-1">
        <currentMode.Component
          value={currentValue}
          fieldMeta={fieldMeta}
          fieldHelpers={fieldHelpers}
          readOnly={readOnly}
          {...props}
        />
      </div>
      <Button disabled={readOnly} small className={'m-1'} onClick={cycleMode}>
        {currentMode.label}
      </Button>
    </div>
  );
};

export default RelativeDateComponent;
