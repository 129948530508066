import { currentDateForTimezone, parseDate } from '@hogwarts/validation';
import { DateTime, DurationLike } from 'luxon';

import { assertArgs } from '@hogwarts/utils';

// Added a year as had issue where the/max didnt place nice in Chrome in some timezones by a few seconds
const YEAR_MS = 31536000000;
export const MIN_DATE = DateTime.fromMillis(-8640000000000000 + YEAR_MS);
export const MAX_DATE = DateTime.fromMillis(8640000000000000 - YEAR_MS);
// SQL min date is 1753-01-01
// Mongo suggests Year 0 to 9999 max.

export const quarterDates = (date): [any, any] => {
  const quarter = date.quarter;
  let firstMonth = 1 + (quarter - 1) * 3;
  let lastMonth = 3 + (quarter - 1) * 3;

  const startDate = date.set({ month: firstMonth }).startOf('month');
  const endDate = date.set({ month: lastMonth }).endOf('month');

  return [startDate, endDate];
};

export function parseDuration(duration: string): DurationLike {
  if (!duration) return null;

  const [key, value] = duration.split(':');

  switch (key) {
    case 'immediate': {
      return {
        days: 0,
      };
    }
    case 'days':
    case 'weeks':
    case 'months':
    case 'years': {
      break;
    }
    default: {
      return null;
    }
  }

  if (!value) {
    return null;
  }

  let number = Number.parseInt(value);
  if (Number.isNaN(number)) {
    return null;
  }

  return {
    [key]: number,
  };
}

export function getDateRange(
  range: string,
  options: {
    value?: string;
    from?: string;
    duration?: string;
    timezone?: string;
  }
): [any?, any?] {
  const { value, from, duration, timezone } = options;

  assertArgs({ range, timezone });

  let date = currentDateForTimezone(timezone);

  switch (range) {
    case 'exact': {
      date = parseDate(value);
      if (!date.isValid) {
        // error!
        return [];
      }
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'relative-plus': {
      // take todays date and modify it with the attached duration
      date = parseDate(from);

      if (!date.isValid) {
        // error!
        return [];
      }
      let d = parseDuration(duration);
      if (!d) {
        // error!
        return [];
      }
      date = date.plus(d);

      return [date.startOf('day'), date.endOf('day')];
    }

    case 'relative-minus': {
      // take todays date and modify it with the attached duration
      date = parseDate(from);
      if (!date.isValid) {
        // error!
        return [];
      }
      let d = parseDuration(duration);
      if (!d) {
        // error!
        return [];
      }
      date = date.minus(d);
      return [date.startOf('day'), date.endOf('day')];
    }

    // Relative Date Intervals
    case 'lastyear': {
      date = date.minus({ year: 1 });
      return [date.startOf('year'), date.endOf('year')];
    }
    case 'thisyear': {
      return [date.startOf('year'), date.endOf('year')];
    }
    case 'nextyear': {
      date = date.plus({ year: 1 });
      return [date.startOf('year'), date.endOf('year')];
    }

    case 'lastquarter': {
      date = date.minus({ months: 3 });
      return quarterDates(date);
    }
    case 'thisquarter': {
      return quarterDates(date);
    }
    case 'nextquarter': {
      date = date.plus({ months: 3 });
      return quarterDates(date);
    }
    case 'lastmonth': {
      date = date.minus({ months: 1 });
      return [date.startOf('month'), date.endOf('month')];
    }
    case 'thismonth': {
      return [date.startOf('month'), date.endOf('month')];
    }
    case 'lastweek': {
      date = date.minus({ days: 7 });
      return [date.startOf('week'), date.endOf('week')];
    }
    case 'thisweek': {
      return [date.startOf('week'), date.endOf('week')];
    }
    case 'nextweek': {
      date = date.plus({ days: 7 });
      return [date.startOf('week'), date.endOf('week')];
    }
    case 'nextmonth': {
      date = date.plus({ months: 1 });
      return [date.startOf('month'), date.endOf('month')];
    }

    // Relative dates:
    case '3yearsago': {
      date = date.minus({ years: 3 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '2yearsago': {
      date = date.minus({ years: 2 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '1yearago': {
      date = date.minus({ years: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case '11monthsago': {
      date = date.minus({ months: 11 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '10monthsago': {
      date = date.minus({ months: 10 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '9monthsago': {
      date = date.minus({ months: 9 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '8monthsago': {
      date = date.minus({ months: 8 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '7monthsago': {
      date = date.minus({ months: 7 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case '6monthsago': {
      date = date.minus({ months: 6 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '5monthsago': {
      date = date.minus({ months: 5 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '4monthsago': {
      date = date.minus({ months: 4 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '3monthsago': {
      date = date.minus({ months: 3 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '2monthsago': {
      date = date.minus({ months: 2 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '1monthago': {
      date = date.minus({ months: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case '3weeksago': {
      date = date.minus({ weeks: 3 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '2weeksago': {
      date = date.minus({ weeks: 2 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case '1weekago': {
      date = date.minus({ weeks: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'beforetoday': {
      date = date.minus({ days: 1 });
      return [MIN_DATE, date.endOf('day')];
    }

    case 'yesterday': {
      date = date.minus({ days: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'today': {
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'todayorlater': {
      return [date.startOf('day'), MAX_DATE];
    }

    case 'beforetomorrow': {
      return [MIN_DATE, date.endOf('day')];
    }

    case 'tomorrow': {
      date = date.plus({ days: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'tomorroworlater': {
      date = date.plus({ days: 1 });
      return [date.startOf('day'), MAX_DATE];
    }

    case 'in3days': {
      date = date.plus({ days: 3 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'in1week': {
      date = date.plus({ weeks: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in2weeks': {
      date = date.plus({ weeks: 2 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in3weeks': {
      date = date.plus({ weeks: 3 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'in1month': {
      date = date.plus({ months: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in2months': {
      date = date.plus({ months: 2 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in3months': {
      date = date.plus({ months: 3 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in4months': {
      date = date.plus({ months: 4 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in5months': {
      date = date.plus({ months: 5 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in6months': {
      date = date.plus({ months: 6 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'in7months': {
      date = date.plus({ months: 7 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in8months': {
      date = date.plus({ months: 8 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in9months': {
      date = date.plus({ months: 9 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in10months': {
      date = date.plus({ months: 10 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in11months': {
      date = date.plus({ month: 11 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'in1year': {
      date = date.plus({ years: 1 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in2years': {
      date = date.plus({ years: 2 });
      return [date.startOf('day'), date.endOf('day')];
    }
    case 'in3years': {
      date = date.plus({ years: 3 });
      return [date.startOf('day'), date.endOf('day')];
    }

    case 'thisacademicyear': {
      if (date.month < 9) {
        date = date.minus({ years: 1 });
      }
      date = date.set({ month: 9 });
      return [date.startOf('month'), date.plus({ months: 11 }).endOf('month')];
    }

    case 'lastacademicyear': {
      date = date.minus({ years: 1 });
      if (date.month < 9) {
        date = date.minus({ years: 1 });
      }
      date = date.set({ month: 9 });
      return [date.startOf('month'), date.plus({ months: 11 }).endOf('month')];
    }

    case 'nextacademicyear': {
      date = date.plus({ years: 1 });
      if (date.month < 9) {
        date = date.minus({ years: 1 });
      }
      date = date.set({ month: 9 });
      return [date.startOf('month'), date.plus({ months: 11 }).endOf('month')];
    }

    default: {
      throw new Error(`Invalid Range Type [${range}]`);
    }
  }
}
