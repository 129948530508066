// import log from '@scrtracker/logging';
import { assertArgs, isObjectLike } from '@hogwarts/utils';
import { ConditionalStructureError } from './errors';
import * as methods from './methods';
import {
  Comparison,
  ComparisonMethod,
  Compute,
  Condition,
  Options,
  VariableValues,
} from './types';
import { getMethod, replaceVariable, replaceVariables } from './utils';

const executeMethod = (
  methodDescriptor: Compute | Comparison,
  left: unknown,
  values: VariableValues,
  options: Options
): unknown => {
  assertArgs({ methodDescriptor, values, options });

  let [methodName, methodParams] = getMethod(methodDescriptor);
  methodParams = replaceVariables(methodParams, values);

  const func = methods[methodName];
  if (!func) {
    throw new ConditionalStructureError(
      `Invalid method name of [${methodName}]`
    );
  }

  let method: ComparisonMethod, parameters: any;
  if (typeof func === 'function') {
    // no parameters required
    method = func;
    parameters = {};
  } else {
    method = func.method;
    parameters = func.parameters;
  }

  if (typeof method !== 'function') {
    throw new ConditionalStructureError(`Invalid method for [${methodName}]`);
  }
  // go through the required variables for the method
  if (!parameters) {
    throw new ConditionalStructureError(
      `Parameters not specified for [${methodName}]`
    );
  }
  if (typeof parameters === 'function') {
    parameters = parameters(methodParams);
  }
  for (const key of Object.keys(parameters)) {
    if (isObjectLike(parameters[key])) {
      // eslint-disable-next-line
      const { type, required, description } = parameters[key];
      if (required !== false) {
        if (!methodParams.hasOwnProperty(key)) {
          throw new ConditionalStructureError(
            `[${key}] not specified for function [${methodName}]`
          );
        }
      }
      continue;
    }

    if (!methodParams.hasOwnProperty(key)) {
      throw new ConditionalStructureError(
        `[${key}] not specified for function [${methodName}]`
      );
    }
  }

  const result = method(left, methodParams, options);

  return result;
};

const executeComputeStep = (
  left: unknown,
  condition: Condition,
  values: VariableValues,
  options: Options
): unknown => {
  const { compute } = condition;
  if (compute) {
    return executeMethod(compute, left, values, options);
  }
  return left;
};

const executeComparisonStep = (
  left: unknown,
  condition: Condition,
  values: VariableValues,
  options: Options
): boolean => {
  let { comparison } = condition;
  if (!comparison) {
    comparison = 'isTrue';
  }
  return !!executeMethod(comparison, left, values, options);
};

const getWhen = (condition: Condition, values: VariableValues) => {
  let { when } = condition;

  if (typeof when === 'undefined') {
    throw new ConditionalStructureError(`Missing when on condition`);
  }

  if (typeof when === 'string') {
    return replaceVariable(when, values);
  }
  return when;
};

export default (
  condition: Condition,
  values: VariableValues,
  options: Options
): boolean => {
  let left = getWhen(condition, values);

  left = executeComputeStep(left, condition, values, options);

  return executeComparisonStep(left, condition, values, options);
};
