import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Login from '@hogwarts/ui-components-login';
import authenticationService from '../../services/authentication';

const LoginCallbackContainer = () => {
  const history = useHistory();

  useEffect(() => {
    authenticationService.parseHash().finally(() => {
      history.replace('/');
    });
  }, [history]);

  return <Login state={'LOGGING-IN'} />;
};

export default LoginCallbackContainer;
