import React, { useState } from 'react';
import { Label, InputGroup, Button, Callout } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const MFAPage = ({ verifyError, onVerifyToken, onLogout }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState('');

  return (
    <>
      {verifyError ? (
        <Callout
          className={styles.errorMessage}
          intent="danger"
          title="Error Verifying Token"
        >
          {verifyError === 'INVALID-TOKEN'
            ? t('Token not valid, please try again.')
            : t(
                'An unknown error occurred, please try again. If this continues to happen please contact support.'
              )}
        </Callout>
      ) : (
        <p className={styles.headerMessage}>{t('Multi-Factor Token')}</p>
      )}
      <p className={styles.secondaryMessage}>
        {t(
          'Your account has Multi-Factor Authentication enabled. To continue with log in please enter the token provided by your authenticator app.'
        )}
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onVerifyToken(token);
        }}
      >
        <Label>
          <p className={styles.formLabel}>{t('Token')}</p>
          <InputGroup
            large
            autoFocus
            autoComplete="one-time-code"
            value={token}
            onChange={(e) => {
              setToken(e.target.value);
            }}
          />
        </Label>
        <div className={styles.fullButton}>
          <Button type="submit" large intent="primary" disabled={!token}>
            {t('Continue')}
          </Button>
        </div>
        <div className={styles.fullButton}>
          <Button
            large
            onClick={() => {
              onLogout();
            }}
          >
            {t('Log Out')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default MFAPage;
