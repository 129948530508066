import * as types from './types';
import knownResourceTypes from './resourceTypes';

function create(
  key: string,
  description: string,
  resourceTypes: string[]
): types.Permission {
  for (const resourceType of resourceTypes) {
    if (!knownResourceTypes[resourceType]) {
      throw new Error(`Invalid Resource Type Specified '${resourceType}'`);
    }
  }

  return {
    key,
    description,
    resourceTypes,
  };
}

export default create;
