import { DateTime } from 'luxon';

export default {
  key: 'addDuration',
  label: 'Creates a new date by adding a Duration to an existing date',
  output: 'date',
  fields: [
    {
      key: 'source',
      label: 'Source Field',
      type: 'field',
      dataType: 'date',
    },
    {
      key: 'durationType',
      label: 'Duration Unit',
      type: 'select',
      values: ['days', 'weeks', 'months', 'years'],
    },
    {
      key: 'duration',
      label: 'Duration',
      type: 'number',
    },
  ],
  execute: ({ data }, params) => {
    let date = DateTime.fromISO(data[params.source]);
    if (!date.isValid) {
      return null;
    }
    date = date.plus({ [params.durationType]: params.duration });
    return date.toISODate();
  },
};
