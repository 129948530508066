import { Structure } from './types';
import { removeReservedKeys, reservedKey } from './utils';

type Options = {
  includeNull?: boolean;
  safeMode?: boolean;
};

const getItemShapes = (
  structureItem: Structure,
  leftItem: Record<string, unknown>,
  rightItem: Record<string, unknown>
) => {
  switch (structureItem.type) {
    case 'objects-dynamic': {
      // So this is driven by object keys
      // need to collate the left + right keys

      return Object.keys({
        ...leftItem,
        ...rightItem,
      })
        .filter(removeReservedKeys)
        .map((key) => ({
          key,
          structure: structureItem.structure,
        }));
    }

    case 'objects-known': {
      // This is driven by a known list and has their own structure
      return structureItem.structure.map((s) => ({
        key: s.key,
        structure: s.structure,
      }));
    }

    case 'objects-join': {
      // On a patch, we cant use the joinRef as its likely the keys are in the parent
      // So we just grab everything.
      const foreignCollection = {
        ...leftItem,
        ...rightItem,
      };
      return Object.keys(foreignCollection)
        .filter(removeReservedKeys)
        .map((key) => ({
          key,
          structure: structureItem.structure,
        }));
    }

    default: {
      throw new Error(`Unknown Type [${structureItem.type}]`);
    }
  }
};

const isObjectType = (type: string): boolean => {
  switch (type) {
    case 'objects-join':
    case 'objects-dynamic':
    case 'objects-known': {
      return true;
    }
  }
  return false;
};

const isNotEmpty = (value, includeNull) => {
  if (typeof value === 'undefined') {
    return false;
  }
  if (value === null) {
    return includeNull;
  }
  return true;
};

const isFalsy = (value: any) => {
  return value === 0 || value === false || Number.isNaN(value) || value === '';
};

const mergeStructureItem = (
  structureItem: Structure,
  leftItem1: Record<string, any>,
  rightItem1: Record<string, any>,
  options?: Options
) => {
  let structureItemResult;

  if (options.safeMode && reservedKey(structureItem.key)) {
    throw new Error(`Key [${structureItem.key}] is reserved`);
  }

  const leftItem = leftItem1 && leftItem1[structureItem.key];
  const rightItem = rightItem1 && rightItem1[structureItem.key];

  if (isObjectType(structureItem.type)) {
    const itemShapes = getItemShapes(structureItem, leftItem, rightItem);

    const { objectsMustBeOwned } = structureItem.options || {};

    for (const itemShape of itemShapes) {
      const left = leftItem && leftItem[itemShape.key];
      const right = rightItem && rightItem[itemShape.key];

      // Example: We are trying to null out a section from a patch
      if (right === null && options.includeNull) {
        structureItemResult = {
          ...structureItemResult,
          [itemShape.key]: null,
        };
        continue;
      }

      if (left == null && right == null) continue;

      let itemShapeResult: any = {};

      let lock;
      for (const itemShapeStructureItem of itemShape.structure) {
        // Lock Mechanism
        if (!isObjectType(itemShapeStructureItem.type)) {
          const leftLock = left?.lock && left.lock[itemShapeStructureItem.key];
          const rightLock =
            right?.lock && right.lock[itemShapeStructureItem.key];

          if (typeof rightLock === 'boolean') {
            lock = {
              ...lock,
              [itemShapeStructureItem.key]: rightLock,
            };
          } else if (typeof leftLock === 'boolean') {
            lock = {
              ...lock,
              [itemShapeStructureItem.key]: leftLock,
            };
          }
        }

        const mergeStructureItemResult = mergeStructureItem(
          itemShapeStructureItem,
          left,
          right,
          options
        );
        if (isNotEmpty(mergeStructureItemResult, options?.includeNull)) {
          itemShapeResult = {
            ...itemShapeResult,
            [itemShapeStructureItem.key]: mergeStructureItemResult,
          };
        }
      }

      if (lock && Object.keys(lock).length) {
        itemShapeResult.lock = lock;
      }

      if (right?.deleted != null) {
        itemShapeResult.deleted = right?.deleted;
      } else if (left?.deleted != null) {
        itemShapeResult.deleted = !!left?.deleted;
      }

      if (objectsMustBeOwned !== false) {
        if (right?.owner != null) {
          itemShapeResult.owner = right?.owner;
        } else if (left?.owner != null) {
          itemShapeResult.owner = left?.owner;
        }
      }

      if (Object.keys(itemShapeResult).length) {
        structureItemResult = {
          ...structureItemResult,
          [itemShape.key]: itemShapeResult,
        };
      }
    }
  } else if (rightItem === null) {
    return null;
  } else {
    switch (structureItem.type) {
      case 'string': {
        if (typeof rightItem === 'string') {
          structureItemResult = rightItem;
        } else if (typeof leftItem === 'string') {
          structureItemResult = leftItem;
        }
        break;
      }
      case 'number': {
        if (typeof rightItem === 'number') {
          structureItemResult = rightItem;
        } else if (typeof leftItem === 'number') {
          structureItemResult = leftItem;
        }
        break;
      }
      case 'boolean': {
        if (typeof rightItem === 'boolean') {
          structureItemResult = rightItem;
        } else if (typeof leftItem === 'boolean') {
          structureItemResult = leftItem;
        }
        break;
      }
      case 'any': {
        if (isFalsy(rightItem)) {
          structureItemResult = rightItem;
        } else if (rightItem !== null) {
          structureItemResult = rightItem || leftItem;
        }
        break;
      }
      case 'date':
      case 'object': {
        if (rightItem !== null) {
          structureItemResult = rightItem || leftItem;
        }
        break;
      }
      default: {
        throw new Error(`Unknown Item Type [${structureItem.type}]`);
      }
    }
  }

  return structureItemResult;
};

export const schemePatchFactory =
  (structure: Structure[], globalOptions?: Options) =>
  (leftSchemeData: any, rightSchemeData: any, options?: Options): any => {
    let result = {};

    for (const rootStructureItem of structure) {
      const itemResult = mergeStructureItem(
        rootStructureItem,
        leftSchemeData,
        rightSchemeData,
        {
          ...globalOptions,
          ...options,
        }
      );

      if (isNotEmpty(itemResult, options?.includeNull)) {
        result = {
          ...result,
          [rootStructureItem.key]: itemResult,
        };
      }
    }

    return result;
  };
