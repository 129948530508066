import { DateTime } from 'luxon';
import { formats } from './dateFormats';

export const currentDateForTimezone = (timezone: string): DateTime => {
  let dateUtc = DateTime.utc();

  if (!timezone) {
    return dateUtc;
  }

  // bit confusing, but what we are doing here, is getting the current date/time
  // of the target zone and stripping out the timezone
  // For example, if its midnight during UK summer, its actually 1am, so this
  //  will give us a datetime object at 1am but in UTC - so we can compare this to our
  //  utc dates

  // Another example, if its midnight at UTC, then in America, New york its actually
  // 8pm the previous day. So this will return yesterday at 8pm, but with a utc timezone
  // This prevents us having to change the field date which should be midnight UTC,
  // so we are just moving the window
  let date = dateUtc
    .setZone(timezone)
    .setZone('utc', { keepLocalTime: true })
    .startOf('day');

  return date;
};

export type DateParser = (value: any, timezone?: string) => DateTime;
export const parseDate = (value: any, timezone?: string): DateTime => {
  let zone = timezone || 'utc';
  if (typeof zone !== 'string') {
    throw new Error(`Invalid Timezone [${timezone}]`);
  }
  if (DateTime.isDateTime(value)) {
    return value.setZone(timezone || 'utc');
  }
  if (value == null) {
    return DateTime.invalid('Value given to parseDate was null');
  }
  if (value instanceof Date) {
    return DateTime.fromJSDate(value, { zone });
  }
  if (typeof value === 'number') {
    return DateTime.fromMillis(value).setZone(zone);
  } else if (typeof value === 'string') {
    const trimmed = value.trim();

    let date = DateTime.fromISO(value, { zone });
    if (date.isValid) {
      return date;
    }

    for (const format of formats) {
      date = DateTime.fromFormat(trimmed, format, { zone });
      if (date.isValid) {
        return date;
      }
    }
    return DateTime.invalid(
      `Value '${value}' given to parseDate did not match any known formats`
    );
  } else {
    return DateTime.invalid('Value given to parseDate was not a string');
  }
};

export const formatDate = (
  dateString: any,
  format?: any,
  timezone?: string
) => {
  const date = parseDate(dateString, timezone);
  if (date.isValid) {
    return date.toLocaleString(DateTime.DATE_MED);
  }
  return dateString;
};
