import { Label } from '@blueprintjs/core';
import { InfoPopover, Markdown } from '@hogwarts/ui-components-core';
import React from 'react';

export const LabelDecorator = (Component, decoratorProps) => (fieldProps) => {
  const { fieldKey, label, help } = {
    ...fieldProps,
    ...decoratorProps,
  };
  return (
    <>
      <div className="d-flex">
        {label && (
          <Label
            style={{
              marginBottom: '5px',
            }}
            htmlFor={fieldKey}
          >
            {label}
          </Label>
        )}
        {help?.type === 'info' && (
          <InfoPopover
            className="ml-1"
            modifiers={{
              preventOverflow: { enabled: false },
            }}
            content={<Markdown content={help.description} />}
          />
        )}
      </div>
      <Component {...decoratorProps} {...fieldProps} />
    </>
  );
};
