export const isCentralTeam = (organisation: any): boolean => {
  if (!organisation) {
    return false;
  }
  const shared = organisation.attributes?.shared;
  if (typeof shared === 'string' && shared.startsWith('YES')) {
    return true;
  }
  return (
    // Legacy setting (Bool)
    shared === true
  );
};

export const DEFAULT_LOCATION = 'GB';
export const DEFAULT_TIMEZONE = 'Europe/London';

export const fieldConverter = (fieldName: string): string => {
  fieldName = fieldName.trim().toLowerCase();
  if (fieldName.startsWith('data.')) {
    throw new Error('Illegal attempt to Query Data Block');
  }
  if (fieldName.startsWith('expiry.')) {
    return fieldName;
  }
  switch (fieldName) {
    case 'tags':
    case 'profileTypeKey':
    case 'createdAt':
    case 'updatedAt':
    case 'location':
    case 'deleted':
    case 'deletedAt':
    case 'typeKey': {
      return fieldName;
    }
    default: {
      return `query.${fieldName}`;
    }
  }
};
