import { deepClone, veryCleanObject } from '@hogwarts/utils';
import { isEqual, set } from 'lodash';

import uuid from 'uuid/v4';
import schemePatcher from '../schemePatcher';
import { deleteItem } from './schemeHelperFactory';

const defaultSection = () =>
  deepClone({
    lock: {
      color: true,
      icon: true,
    },
    owner: true,
    enabled: true,
    icon: '/icons/additional.png',
    color: '#778ca3',
    order: 0,
  });

const defaultField = () =>
  deepClone({
    lock: {
      inputType: true,
      label: false,
    },
    owner: true,
    defaultValue: null,
    readOnly: false,
    enabled: true,
    order: 0,
  });

const defaultProfileType = () =>
  deepClone({
    owner: true,
    enabled: true,
    avatar: '20',
    color: '#778ca3',
    order: 100000,
  });

export const getUniqueKey = (prefix?: string) =>
  `${prefix || ''}${uuid().replace(/-/g, '')}`;

export const schemeEditorFactory = (
  scheme: any,
  currentChangeset: any,
  profileTypeKey: string
) => {
  if (!scheme.isSchemeHelper) {
    throw new Error('Must supply scheme as a helper');
  }

  let changeSet = deepClone(currentChangeset);

  const applyChangeSet = (
    force: boolean = false,
    setHasChanges: boolean = false
  ) => {
    if (!force && isEqual(currentChangeset, changeSet)) {
      return {};
    }
    const patchedScheme = scheme.updateDraft(changeSet, {
      profileTypeKey,
      includeDeleted: true,
      skipAsterix: true,
    });

    if (setHasChanges) {
      return {
        hasChanges: true,
        changeSet,
        scheme: patchedScheme,
      };
    }

    return {
      changeSet,
      scheme: patchedScheme,
    };
  };

  const restoreDeleteItemPatch = (path, deleting) => {
    let patch = {};
    let patchPath = `${path}.deleted`;
    if (deleting) {
      set(patch, patchPath, true);
    } else {
      set(patch, patchPath, false);
    }
    return patch;
  };

  return {
    addSection({ key, label, order }) {
      if (!key) key = getUniqueKey();
      let patch = {};
      set(patch, `sections.${key}`, {
        ...defaultSection(),
        label: label || '(new section)',
        order: order || 0,
      });
      return this.applyPatch(patch, true);
    },
    addField({
      key,
      section: sectionKey,
      dataType,
      inputType,
      inputMeta,
      label,
      appearAfterKey,
    }) {
      if (!key) key = getUniqueKey();
      let patch = {};

      const appearAfter = appearAfterKey && scheme.getField(appearAfterKey);

      let appearAfterOrder = appearAfter?.order || 0;
      const section = scheme.getSection(sectionKey);

      if (!section) {
        return;
      }

      for (const field of section.fields.filter(
        (f) => f.order > appearAfterOrder
      )) {
        set(patch, `fields.${field.key}.order`, field.order + 20);
      }

      appearAfterOrder += 10;

      set(patch, `fields.${key}`, {
        ...defaultField(),
        section: sectionKey,
        dataType,
        inputType,
        inputMeta,
        order: appearAfterOrder,
        label: label || `New ${inputType}`,
      });

      return this.applyPatch(patch, true);
    },
    addArrayField({
      key,
      parentKey,
      dataType,
      inputType,
      inputMeta,
      label,
      appearAfterKey,
    }) {
      if (!key) key = getUniqueKey();
      let patch = {};

      const parentField = scheme.getField(parentKey);

      const appearAfter =
        appearAfterKey &&
        parentField.arrayFields.find((f) => f.key === appearAfterKey);

      let appearAfterOrder = appearAfter?.order || 0;

      for (const childField of parentField.arrayFields.filter(
        (f) => f.order > appearAfterOrder
      )) {
        set(patch, `fields.${childField.key}.order`, childField.order + 20);
      }

      appearAfterOrder += 10;

      set(patch, `fields.${key}`, {
        ...defaultField(),
        parent: parentKey,
        // section: sectionKey,
        dataType,
        inputType,
        inputMeta,
        order: appearAfterOrder,
        label: label || `New Child ${inputType}`,
      });

      return this.applyPatch(patch, true);
    },
    addProfileType({
      key,
      parent,
      label,
      description,
      avatar,
      enabled,
      color,
    }) {
      if (!key) key = getUniqueKey();
      let patch = {};
      set(patch, `profileTypes.${key}`, {
        ...defaultProfileType(),
        label: label || '(new profile type)',
        ...veryCleanObject({
          parent,
          description,
          avatar,
          enabled,
          color,
        }),
      });
      return this.applyPatch(patch, true);
    },
    deleteProfileType(profileTypeKey) {
      let patch;
      let path = `profileTypes.${profileTypeKey}`;
      const profileType = scheme.getProfileType(profileTypeKey);
      if (!profileType || !profileType.meta.owned) {
        return null;
      }
      if (profileType.meta.ownerVariant === 'draft') {
        changeSet = deleteItem(changeSet, 'profileTypes', profileTypeKey);
        return this.refresh();
      }
      patch = restoreDeleteItemPatch(path, true);
      return this.applyPatch(patch, true);
    },
    restoreProfileType(profileTypeKey) {
      let path = `profileTypes.${profileTypeKey}`;
      const patch = restoreDeleteItemPatch(path, false);
      return this.applyPatch(patch, true);
    },
    addRatingSystem() {},
    addTag() {},
    restoreField(fieldKey) {
      let path = `fields.${fieldKey}`;
      const patch = restoreDeleteItemPatch(path, false);
      return this.applyPatch(patch, true);
    },
    deleteField(fieldKey) {
      let patch;
      let path = `fields.${fieldKey}`;
      const field = scheme.getField(fieldKey);
      if (!field || !field.meta.owned) {
        return null;
      }
      if (field.meta.ownerVariant === 'draft') {
        changeSet = deleteItem(changeSet, 'fields', fieldKey);
        return this.refresh();
      }
      patch = restoreDeleteItemPatch(path, true);
      return this.applyPatch(patch, true);
    },
    restoreSection(fieldKey) {
      let path = `sections.${fieldKey}`;
      const patch = restoreDeleteItemPatch(path, false);
      return this.applyPatch(patch, true);
    },
    deleteSection(sectionKey) {
      let patch;
      let path = `sections.${sectionKey}`;
      const section = scheme.getSection(sectionKey);
      if (!section || !section.meta.owned) {
        return null;
      }
      if (section.meta.ownerVariant === 'draft') {
        changeSet = deleteItem(changeSet, 'sections', sectionKey);
        return this.refresh();
      }
      patch = restoreDeleteItemPatch(path, true);
      return this.applyPatch(patch, true);
    },
    refresh() {
      return applyChangeSet(true);
    },
    applyPatch(suggestedPatch, rootOnly) {
      const suggestedPatchCopy = deepClone(suggestedPatch || {});

      let patch = {};
      if (rootOnly !== true && profileTypeKey) {
        set(patch, `profileTypeSchemes.${profileTypeKey}`, suggestedPatchCopy);
      } else {
        patch = suggestedPatchCopy;
      }
      changeSet = schemePatcher(changeSet, patch, {
        includeNull: true,
      });

      return applyChangeSet(false, true);
    },
  };
};
