import React, { useEffect, useMemo, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Button } from '@hogwarts/ui-components-core';
import { useTranslation } from 'react-i18next';
import { RenderFields } from '../index';
import styles from '../styles.module.css';

const behaviours = {
  cognita_risk_assessment: ({ formik, items }) => {
    const closeStatus = formik.values['ra_user_closed'];

    // TODO: Create a computed field to state if this is closed!
    const closed = closeStatus === 'yes' || closeStatus === 'yes3';

    return items.map((item, index) => {
      const readOnly = closed || index !== 0;
      return { ...item, readOnly };
    });
  },
};

const TabComponent = ({
  children,
  value,
  tabTitle,
  reverseOrder,
  behaviour,
  decorators,
  fieldHelpers,
  fieldMeta,
  ...componentProps
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  let values = value;
  if (!Array.isArray(values)) values = [];

  useEffect(() => {
    if (selectedIndex < 0 && values.length > 0) {
      setSelectedIndex(0);
    } else if (selectedIndex >= values.length) {
      setSelectedIndex(values.length - 1);
    }
  }, [values, values && values.length, selectedIndex]);

  let tabItems = useMemo(() => {
    let items = values.reduce((prev, item, index) => {
      return [...prev, { item, index }];
    }, []);

    if (reverseOrder) {
      items.reverse();
    }

    const behaviourFunction = behaviours[behaviour];
    if (behaviourFunction) {
      items = behaviourFunction({ fieldHelpers, items });
    }

    return items;
  }, [values, values?.length, behaviour, reverseOrder]);

  return (
    <>
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          setSelectedIndex(index);
        }}
      >
        <TabList className={styles.tabList}>
          {tabItems.map(({ index }) => {
            return (
              <Tab
                key={index}
                className={styles.tab}
                selectedClassName={styles.tabSelected}
              >
                {t(tabTitle || `Item {{index}}`, {
                  index: index + 1,
                })}
              </Tab>
            );
          })}
        </TabList>

        {tabItems.map(({ item, index, ...behaviourProps }) => {
          // Re-point them at the required key
          const fields = children
            .map((field) => ({
              ...field,
              key: `${fieldHelpers.key}[${index}].${field.key}`,
            }))
            .filter((field) => {
              const visible = !field.visible || field.visible[index] !== false;
              return visible;
            });

          // TODO: Only the latest Review was editable
          // in cognita.
          // TODO: It was also disabled if the Review was closed.
          // const readOnly = closed || index !== 0;
          return (
            <TabPanel
              className={styles.tabPanel}
              selectedClassName={styles.tabPanelSelected}
              key={index}
              tabId={`${index}`}
            >
              <RenderFields
                formikProps={componentProps?.formikProps}
                fields={fields}
                decorators={decorators}
                componentProps={{
                  ...componentProps,
                  ...behaviourProps,
                  arrayIndex: index,
                  // arrayHelpers,
                }}
              />
            </TabPanel>
          );
        })}
      </Tabs>
    </>
  );
};

export default TabComponent;
