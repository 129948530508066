import { assertArgs } from '@hogwarts/utils';
import {
  parseBool,
  parseDate,
  parseNumber,
  parseString,
} from '@hogwarts/validation';
import { DateTime } from 'luxon';
import { ConditionalDataError, ConditionalStructureError } from './errors';
import {
  Condition,
  ConditionGroup,
  Options,
  Variables,
  VariableValues,
} from './types';

export const VARIABLE_SUFFIX = '$';

export const isEmptyCondition = (
  c: Partial<ConditionGroup | Condition> | undefined | null
): boolean => {
  if (!c) return true;
  if (!Object.keys(c).length) return true;
  if ('conditions' in c && c.conditions.length === 0) {
    return true;
  }
  return false;
};

export const isConditionGroup = (
  item: Condition | ConditionGroup
): item is ConditionGroup => {
  if ('conditions' in item) {
    return true;
  }
  return false;
};

export const isVariable = (key: any): key is string => {
  return (
    typeof key === 'string' &&
    key.length > 0 &&
    key[key.length - 1] === VARIABLE_SUFFIX
  );
};

export const getUsedVariables = (
  root: Condition | ConditionGroup
): Record<string, any> => {
  let result: Record<string, any> = {};
  traverseCondition(root, (condition) => {
    if (isVariable(condition.when)) {
      result[condition.when] = true;
    }
    if (condition.comparison && typeof condition.comparison !== 'string') {
      const [, params] = condition.comparison;
      for (const param of Object.keys(params || {})) {
        const possibleVariable = params[param];
        if (isVariable(possibleVariable)) {
          result[possibleVariable] = true;
        }
      }
    }
    if (condition.compute && typeof condition.compute !== 'string') {
      const [, params] = condition.compute;
      for (const param of Object.keys(params || {})) {
        const possibleVariable = params[param];
        if (isVariable(possibleVariable)) {
          result[possibleVariable] = true;
        }
      }
    }
  });

  return result;
};

type PatcherFunc = (condition: Condition) => Condition | void;
export const traverseCondition = (
  condition: Condition | ConditionGroup,
  patcher: PatcherFunc
): Condition | ConditionGroup => {
  if (isConditionGroup(condition)) {
    let updatedChildren: (Condition | ConditionGroup)[] = [];
    for (const childCondition of condition.conditions) {
      updatedChildren.push(traverseCondition(childCondition, patcher));
    }
    return {
      ...condition,
      conditions: updatedChildren,
    };
  }

  const updated = patcher(condition);
  if (!updated) return condition;

  if (typeof updated !== 'object') {
    throw new Error('Patched Condition must be an Object');
  }

  return {
    ...condition,
    ...updated,
  };
};

export const getMethod = (possibleMethod: unknown): [string, any] => {
  if (possibleMethod == null) {
    return null;
  }
  if (typeof possibleMethod === 'string') {
    return [possibleMethod, {}];
  }
  if (!Array.isArray(possibleMethod)) {
    return null;
  }
  const [n, p] = possibleMethod;
  return [n, p || {}];
};

export const replaceVariable = (
  possibleVariable: unknown,
  variables: VariableValues
): string | unknown => {
  if (isVariable(possibleVariable)) {
    const variable = possibleVariable;
    if (!variables.hasOwnProperty(variable)) {
      throw new ConditionalStructureError(`Unknown variable ${variable}`);
    }
    if (typeof variables[variable] === 'undefined') {
      throw new ConditionalDataError(`Variable ${variable} is undefined`);
    }
    return variables[variable];
  }
  return possibleVariable;
};

export const replaceVariables = (
  params: Record<string, any>,
  variables: VariableValues
) => {
  let result = {};
  for (const param of Object.keys(params || {})) {
    result[param] = replaceVariable(params[param], variables);
  }
  return result;
};

export function convertVariables(
  variables: Variables,
  values: VariableValues,
  options: Options
) {
  assertArgs({ variables, values, options });

  let result = {};
  for (const key of Object.keys(variables)) {
    const variable = variables[key];
    let type: string;
    let defaultValue: unknown;
    if (typeof variable === 'string') {
      type = variable;
    } else {
      type = variable.type;
      defaultValue = variable.value;
    }
    let nullable = true;
    if (type[type.length] === '!') {
      nullable = false;
      type = type.substring(0, type.length - 1);
    }

    if (
      typeof values[key] === 'undefined' &&
      typeof defaultValue === 'undefined'
    ) {
      // packages/scheme-profiles/src/conditionals/mappers.js
      // This will find any values, if they are undefined, it should be null
      // If the result ends up as undefined, then it is not a valid condition
      // and will be ignored
      continue;
    }

    if (type === 'date' && defaultValue === 'now') {
      if (options.timezone) {
        defaultValue = DateTime.utc()
          .setZone(options.timezone)
          .setZone('utc', { keepLocalTime: true });
      } else {
        defaultValue = DateTime.utc();
      }
    }

    if (values[key] == null) {
      values[key] = defaultValue;
    }

    if (values[key] == null && !nullable) {
      return [false, `Null not valid for [${key}]`];
    }

    switch (type) {
      case 'string': {
        result[key] = parseString(values[key]);
        break;
      }
      case 'date': {
        let parsed = null;
        if (values[key] != null) {
          parsed = parseDate(values[key], options.timezone);
          if (!parsed.isValid) {
            if (typeof defaultValue !== 'undefined') {
              parsed = defaultValue;
            } else {
              return [
                false,
                `Invalid date [${values[key]}] specified for [${key}]`,
              ];
            }
          }
        }
        result[key] = parsed;
        break;
      }
      case 'number': {
        result[key] = parseNumber(values[key]);
        break;
      }
      case 'boolean': {
        result[key] = parseBool(values[key]);
        break;
      }
      default: {
        result[key] = values[key];
        break;
      }
    }
  }
  return [true, result];
}
