import React from 'react';
import ReactJson from 'react-json-view';

const ObjectComponent = ({ value, readOnly }) => {
  return (
    <ReactJson className="form-control" src={value || {}} disabled={readOnly} />
  );
};

export default ObjectComponent;
