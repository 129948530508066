import { evaluateGroup } from '@hogwarts/conditionals';

export const calculateProfileTags = (scheme, profileData, timezone) => {
  const tags = [];
  for (const tag of scheme.tags) {
    if (!tag.condition) continue;

    const values = {};
    for (const variable of Object.keys(tag.condition.variables)) {
      const field = variable.substring(0, variable.length - 1);
      values[variable] = profileData[field] || null;
    }

    const result = evaluateGroup(tag.condition, values, {
      timezone,
    });

    if (result) {
      tags.push(tag.key);
    }
  }

  return tags;
};
