import createPermission from './create';
import resourceTypes from './resourceTypes';

const create = (key: string, description: string) =>
  createPermission(key, description, [resourceTypes.FEDERATION]);

const permissions = {
  FEDERATION_USER_GROUP_CREATE: create(
    'FEDERATION_USER_GROUP_CREATE',
    'Allow Creation of User Groups'
  ),
  FEDERATION_USER_GROUP_UPDATE: create(
    'FEDERATION_USER_GROUP_UPDATE',
    'Allow Updating of User Groups'
  ),
  FEDERATION_USER_GROUP_DELETE: create(
    'FEDERATION_USER_GROUP_DELETE',
    'Allow Deletion of User Groups'
  ),

  FEDERATION_ORGANISATION_GROUP_CREATE: create(
    'FEDERATION_ORGANISATION_GROUP_CREATE',
    'Allow Creation of Organisation Groups'
  ),
  FEDERATION_ORGANISATION_GROUP_UPDATE: create(
    'FEDERATION_ORGANISATION_GROUP_UPDATE',
    'Allow Updating of Organisation Groups'
  ),
  FEDERATION_ORGANISATION_GROUP_DELETE: create(
    'FEDERATION_ORGANISATION_GROUP_DELETE',
    'Allow Deletion of Organisation Groups'
  ),

  FEDERATION_USER_READ: create(
    'FEDERATION_USER_READ',
    'Allow retrieval of all Users in a Federation'
  ),
  FEDERATION_USER_CREATE: create(
    'FEDERATION_USER_CREATE',
    'Allow Creation of Users'
  ),
  FEDERATION_USER_UPDATE: create(
    'FEDERATION_USER_UPDATE',
    'Allow Updating of Users'
  ),
  FEDERATION_USER_DELETE: create(
    'FEDERATION_USER_DELETE',
    'Allow Deletion of Users'
  ),

  FEDERATION_ASSIGN_GROUP_PERMISSIONS: create(
    'FEDERATION_ASSIGN_GROUP_PERMISSIONS',
    'Allow assigning/unassigning of Group Permissions'
  ),

  COGNITA_RA_SIGNOFF: create(
    'COGNITA_RA_SIGNOFF',
    'Allow updating of the Risk Assessment Signoff Attributes'
  ),
};

export default permissions;
