import React from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

const ButtonComponent = ({
  label,
  intent,
  onClickHandler,
  readOnly,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Button
      disabled={readOnly}
      onClick={() => {
        if (typeof onClickHandler === 'function') {
          onClickHandler(rest);
        }
      }}
      intent={intent || Intent.PRIMARY}
    >
      {t(label || '')}
    </Button>
  );
};

export default {
  name: 'Button',
  options: [
    {
      key: 'onClickHandler',
    },
    {
      key: 'intent',
    },
  ],
  decorators: {
    label: false,
  },
  Component: ButtonComponent,
};
