import { get } from 'lodash';

export default {
  key: 'copyAttributeValue',
  label: 'Takes an attribute value and pulls it into the data',
  output: 'string',
  fields: [
    {
      key: 'name',
      label: 'Attribute Name',
      type: 'string',
      dataType: 'string',
    },
  ],
  execute: ({ attributes }, params) => {
    if (!attributes) return null;
    return get(attributes, params.name);
  },
};
