import { emptyValue } from '../comparers';

export const parseNumber = (val?: any) => {
  if (val === true || val === false) {
    return null;
  }

  if (emptyValue(val)) {
    return null;
  }

  if (val == null) {
    return null;
  }

  const result = Number.parseFloat(val);

  if (Number.isNaN(result)) {
    return null;
  }

  return result;
};
