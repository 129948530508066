// import './styles.css';
import { Button, MenuItem } from '@blueprintjs/core';
import { MultiSelect as BPMultiSelect } from '@blueprintjs/select';
import React, { useEffect, useMemo, useState } from 'react';
import { highlightText } from '../../utils';

const tagRenderer = (valueField, textField) => (value) => {
  if (!value) return null;
  return value[textField];
};

const itemPredicate = (valueField) => (query, item, _index, exactMatch) => {
  const normalizedValue = item[valueField] && item[valueField].toLowerCase();
  const normalizedQuery = query && query.toLowerCase();
  if (exactMatch) {
    return normalizedValue === normalizedQuery;
  }

  return normalizedValue && normalizedValue.indexOf(normalizedQuery) >= 0;
};

const MultiSelect = ({
  valueField,
  textField,
  items,
  selectedItems: initialSelectedItems,
  onChange,
  placeholder,
  onBlur = () => {},
  disabled = false,
  onQueryChange = () => {},
  resetOnSelect = false,
  onClear = null,
  // disabled,
  // large,
  // fill
}) => {
  const lookup = useMemo(() => {
    return items.reduce(
      (prev, cur) => ({ ...prev, [cur[valueField]]: cur }),
      {}
    );
  }, [items, valueField]);

  const [selectedItems, setSelectedItems] = useState(() => {
    if (!Array.isArray(initialSelectedItems)) return [];
    return initialSelectedItems.reduce((prev, cur) => {
      const item = lookup[cur];
      if (item) return [...prev, item];
      return prev;
    }, []);
  });

  useEffect(() => {
    if (selectedItems.length === 0 && onClear) {
      onClear();
    }
  }, [selectedItems]);

  const changeSelectedItems = (newSelectedItems) => {
    const selectedItemsLookup = newSelectedItems.reduce(
      (prev, cur) => ({ ...prev, [cur[valueField]]: cur }),
      {}
    );

    const orderedItems = items.reduce((prev, cur) => {
      if (selectedItemsLookup[cur[valueField]]) {
        return [...prev, cur];
      }
      return prev;
    }, []);
    setSelectedItems(orderedItems);
    if (onChange) {
      onChange(orderedItems.map((s) => s[valueField]));
    }
  };

  const isItemSelected = (item) => {
    return !!selectedItems.find((s) => s[valueField] === item[valueField]);
  };

  const deselectItem = (item) => {
    changeSelectedItems(
      selectedItems.filter((s) => s[valueField] !== item[valueField])
    );
  };

  const selectItem = (item) => {
    changeSelectedItems([...selectedItems, item]);
  };

  const deselectItemFromTag = (_node, index) => {
    deselectItem(selectedItems[index]);
  };

  const itemSelected = (item) => {
    if (isItemSelected(item)) {
      deselectItem(item);
    } else {
      selectItem(item);
    }
  };

  const clearAllItems = () => {
    changeSelectedItems([]);
  };

  const itemRenderer =
    (valueField, textField) =>
    (item, { handleClick, modifiers, query, index }) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }
      const text = item[textField];
      return (
        <MenuItem
          key={item[valueField] || index}
          active={modifiers.active}
          icon={isItemSelected(item) ? 'tick' : 'blank'}
          disabled={modifiers.disabled || item.disabled}
          text={highlightText(text, query)}
          // label={item.name} // appears on the right
          onClick={handleClick}
          labelClassName={'ml-5'}
        />
      );
    };

  const clearButton =
    selectedItems.length > 0 ? (
      <Button
        icon="cross"
        disabled={disabled}
        minimal={true}
        onClick={clearAllItems}
      />
    ) : undefined;

  return (
    <BPMultiSelect
      items={items}
      onBlur={onBlur}
      fill
      large
      onItemSelect={itemSelected}
      itemPredicate={itemPredicate(valueField)}
      // onItemsPaste
      popoverProps={{ minimal: true }}
      tagRenderer={tagRenderer(valueField, textField)}
      tagInputProps={{
        onRemove: deselectItemFromTag,
        rightElement: clearButton,
        disabled,
        tagProps: {
          minimal: true,
        },
      }}
      itemRenderer={itemRenderer(valueField, textField)}
      noResults={<MenuItem disabled={true} text="No results." />}
      selectedItems={selectedItems}
      placeholder={placeholder}
      onQueryChange={onQueryChange}
      resetOnSelect={resetOnSelect}
    />
  );
};

export default MultiSelect;
