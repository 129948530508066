import createPermission from './create';
import resourceTypes from './resourceTypes';

const create = (key: string, description: string) =>
  createPermission(key, description, [resourceTypes.ORGANISATION]);

const permissions = {
  BACKUP_READ: create(
    'BACKUP_READ',
    'Allow Read permissions to the Backup system for an Organisation'
  ),
  BACKUP_ADMIN: create(
    'BACKUP_ADMIN',
    'Allow Administration of Reports on the Backup system for an Organisation'
  ),

  STORE_RATING_HISTORY: create(
    'STORE_RATING_HISTORY',
    'Allow Storing of Ratings History'
  ),

  PROFILE_READ: create('PROFILE_READ', 'Allow reading of Profiles'),
  PROFILE_VERSION_READ: create(
    'PROFILE_VERSION_READ',
    'Allow reading of Profile Versions'
  ),

  AUDITLOG_READ: create(
    'AUDITLOG_READ',
    'Allow access to the Audit Log Records'
  ),

  // User Administrator
  USER_READ: create('USER_READ', 'Allow retrieval of all Users'),

  // Organisation User Administration
  USER_ROLE_CREATE: create(
    'USER_ROLE_CREATE',
    'Allow adding of a User to an Organisation'
  ),
  USER_ROLE_UPDATE: create(
    'USER_ROLE_UPDATE',
    'Change the Role of an Organisation User'
  ),
  USER_ROLE_DELETE: create(
    'USER_ROLE_DELETE',
    'Allow removing of a User from an Organisation'
  ),

  // Profile Editor
  PROFILE_CREATE: create('PROFILE_CREATE', 'Allow creation of new Profiles'),
  PROFILE_UPDATE: create('PROFILE_UPDATE', 'Allow updating of Profiles'),

  PROFILE_UPDATE_FIELD_META_READONLY: create(
    'PROFILE_UPDATE_FIELD_META_READONLY',
    'Allow changing of Profile Field Meta Data Property - ReadOnly'
  ),
  PROFILE_UPDATE_FIELD_META_ENABLED: create(
    'PROFILE_UPDATE_FIELD_META_ENABLED',
    'Allow changing of Profile Field Meta Data Property - Enabled'
  ),

  PROFILE_MERGE: create('PROFILE_MERGE', 'Allow merging of Profiles'),

  PROFILE_ROLLBACK: create(
    'PROFILE_ROLLBACK',
    'Allow rolling back of Profile Versions'
  ),
  PROFILE_RESTORE: create(
    'PROFILE_RESTORE',
    'Allow restoring of Profile Versions'
  ),
  // PROFILE_ROLLBACK: create('PROFILE_ROLLBACK', 'Allow rollback of Profile Versions'),
  PROFILE_DELETE: create('PROFILE_DELETE', 'Allow deletion of Profiles'),
  PROFILE_COPY: create('PROFILE_COPY', 'Allow copying of Profiles'),

  PROFILE_PRINT: create('PROFILE_PRINT', 'Allow exporting of Reports'),

  PROFILE_DELETE_MULTIPLE: create(
    'PROFILE_DELETE_MULTIPLE',
    'Allow deletion of Multiple Profiles in one call'
  ),

  PROFILE_FILE_LIST: create(
    'PROFILE_FILE_LIST',
    'Allow listing of Documents on a Profile'
  ),
  PROFILE_FILE_READ: create(
    'PROFILE_FILE_READ',
    'Allow opening of Documents on a Profile'
  ),
  PROFILE_FILE_CREATE: create(
    'PROFILE_FILE_CREATE',
    'Allow uploading and editing of Documents in a Profile'
  ),
  PROFILE_FILE_DELETE: create(
    'PROFILE_FILE_DELETE',
    'Allow deletion of Documents on a Profile'
  ),

  // Eventually can be replaced with the new selector mechanism
  PROFILE_FILE_READ_CONFIDENTIAL: create(
    'PROFILE_FILE_READ_CONFIDENTIAL',
    'Allow opening of Confidential Files on a Profile'
  ),

  PROFILE_TRANSFER: create(
    'PROFILE_TRANSFER',
    'Allow a transfer of a profile to another school'
  ),
  PROFILE_ACCEPT_TRANSFER: create(
    'PROFILE_ACCEPT_TRANSFER',
    'Accept the transfer of a Profile to the school'
  ),

  // Data Importing
  BULK_IMPORT_READ: create(
    'BULK_IMPORT_READ',
    'Allow Reading of Bulk Import Data'
  ),
  BULK_IMPORT_UPDATE: create(
    'BULK_IMPORT_UPDATE',
    'Allow Editing of Bulk Import Data'
  ),

  BULK_IMPORT_DELETE_SOME: create(
    'BULK_IMPORT_DELETE_SOME',
    'Allow deleting of selected bulk import rows'
  ),

  BULK_IMPORT_DELETE_ALL: create(
    'BULK_IMPORT_DELETE_ALL',
    'Allow Purging of All Bulk Import Data'
  ),
  BULK_IMPORT_EXECUTE: create(
    'BULK_IMPORT_EXECUTE',
    'Allow Bulk importing of data'
  ),

  EXCEL_BULK_LOADER: create(
    'EXCEL_BULK_LOADER',
    'Allow use of the Excel Bulk Loader'
  ),

  CHECK_ORDER_READ: create(
    'CHECK_ORDER_READ',
    'Allow listing of existing profile orders'
  ),
  CHECK_PLACE_ORDER: create('CHECK_PLACE_ORDER', 'Allow ordering of checks'),
  CHECK_CONFIRM_IDENTITY: create(
    'CHECK_CONFIRM_IDENTITY',
    'Allow confirming of identity'
  ),
  CHECK_CANCEL_ORDER: create(
    'CHECK_CANCEL_ORDER',
    'Allow cancelling of check orders'
  ),
  CHECK_UPDATE_ORDER: create(
    'CHECK_UPDATE_ORDER',
    'Allow updating of check orders'
  ),

  SIGN_OFF: create('SIGN_OFF', 'Allow Signing Off an Organisation'),

  ORGANISATION_FILE_CREATE: create(
    'ORGANISATION_FILE_CREATE',
    'Allow uploading of Documents on an Organisation'
  ),

  ORGANISATION_FILE_DELETE: create(
    'ORGANISATION_FILE_DELETE',
    'Allow deleting of Documents on an Organisation'
  ),

  //ACTIVITIES
  PROFILE_ACTIVITY_READ: create(
    'PROFILE_ACTIVITY_READ',
    'Allow listing of Activities on a Profile'
  ),
  PROFILE_ACTIVITY_DELETE: create(
    'PROFILE_ACTIVITY_DELETE',
    'Allow deleting of Activities on a Profile'
  ),
  PROFILE_ACTIVITY_CREATE: create(
    'PROFILE_ACTIVITY_CREATE',
    'Allow creating of Activities on a Profile'
  ),
  PROFILE_ACTIVITY_UPDATE: create(
    'PROFILE_ACTIVITY_UPDATE',
    'Allow updating of Activities on a Profile'
  ),

  ORGANISATION_ACTIVITY_METADATA_CREATE: create(
    'ORGANISATION_ACTIVITY_METADATA_CREATE',
    'Allow creating of Activity metadata on an Organisation'
  ),
  ORGANISATION_ACTIVITY_METADATA_UPDATE: create(
    'ORGANISATION_ACTIVITY_METADATA_UPDATE',
    'Allow updating of Activity metadata on an Organisation'
  ),
  ORGANISATION_ACTIVITY_METADATA_DELETE: create(
    'ORGANISATION_ACTIVITY_METADATA_DELETE',
    'Allow deleting of Activity metadata on an Organisation'
  ),
  ORGANISATION_ACTIVITY_METADATA_READ: create(
    'ORGANISATION_ACTIVITY_METADATA_READ',
    'Allow listing of Activity metadata on an Organisation'
  ),
};

export default permissions;
