import './styles.css';

import { Alignment, Button, MenuItem } from '@blueprintjs/core';
import {
  Select as BPSelect,
  IItemRendererProps,
  ISelectProps,
} from '@blueprintjs/select';
import React, { useMemo } from 'react';

import { get } from 'lodash';
import { highlightText } from '../../utils';

// (item: T, itemProps: IItemRendererProps) => JSX.Element | null

const itemRenderer =
  <T,>(valueField: keyof T, textField: keyof T) =>
  (
    item: T & Record<string, any> & { group?: boolean },
    { handleClick, query, modifiers, index }: IItemRendererProps
  ) => {
    if (item.group === true) {
      if (query) {
        return null;
      }
      return (
        <MenuItem
          key={index}
          active={modifiers.active}
          disabled={true}
          text={item[textField]}
        />
      );
    }
    const text = item[textField];
    return (
      <MenuItem
        key={index}
        active={modifiers.active}
        disabled={modifiers.disabled || item.disabled}
        text={highlightText(text, query)}
        // label={item.name} // appears on the right
        onClick={handleClick}
        icon={item.icon}
        labelClassName={'ml-5'}
      />
    );
  };

// ItemPredicate<T> = (query: string, item: T, index?: number, exactMatch?: boolean) => boolean;

const itemPredicate =
  <T,>(valueField: keyof T) =>
  (
    query: string,
    item: T & Record<string, any>,
    _index?: number,
    exactMatch?: boolean
  ): boolean => {
    const normalizedValue = item[valueField] && item[valueField].toLowerCase();
    const normalizedQuery = query && query.toLowerCase();
    if (exactMatch) {
      return normalizedValue === normalizedQuery;
    }

    return normalizedValue && normalizedValue.indexOf(normalizedQuery) >= 0;
  };

// const itemListRenderer = (valueField, textField) => ({
//   items,
//   itemsParentRef,
//   query,
//   renderItem,
// }) => {
//   const renderedItems = items.map(renderItem).filter((item) => item != null);
//   return (
//     <Menu ulRef={itemsParentRef}>
//       <MenuItem
//         disabled={true}
//         text={`Found ${renderedItems.length} items matching "${query}"`}
//       />
//       {renderedItems}
//     </Menu>
//   );
// };

type SelectItemGroup<T> = Record<keyof T, string>;

interface SelectProps<T>
  extends Omit<ISelectProps<T>, 'itemRenderer' | 'onItemSelect'> {
  value?: string | number;
  showEmpty?: boolean;
  itemsDescription?: string;

  valueField: keyof T;
  textField: keyof T;

  large?: boolean;
  fill?: boolean;
  groupKey?: string;
  displayedValue?: string;

  groups?: SelectItemGroup<T>[];

  // passthrough to onItemSelect
  onChange?: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void;

  onBlur?: any;
  icon?: any;
  intent?: any;
}

const Select = <T,>({
  className,
  value,
  showEmpty,
  itemsEqual,
  filterable,
  disabled,
  onChange,
  onBlur,
  items: initialItems,
  itemsDescription,
  valueField,
  textField,
  groupKey,
  groups: initialGroups,
  large,
  fill,
  icon,
  intent,
  popoverProps,
  displayedValue,
}: SelectProps<T>) => {
  const items = useMemo(() => {
    let result = initialItems || [];

    if (result.length > 200) {
      console.warn(
        'Large list. May cause slowdowns. Consider virtualizing this list'
      );
    }
    if (groupKey) {
      let grouped: Record<string, any[]> = {};
      for (const item of initialItems) {
        const groupKeyValue = get(item, groupKey);
        if (groupKeyValue) {
          if (!grouped[groupKeyValue]) {
            grouped[groupKeyValue] = [item];
          } else {
            grouped[groupKeyValue].push(item);
          }
        }
      }

      let groups = initialGroups;
      if (!groups) {
        groups = [];
        for (const key of Object.keys(grouped)) {
          groups.push({
            [valueField]: key,
            [textField]: key,
          });
        }
      }

      let result = [];
      for (const group of groups) {
        const groupKeyValue = group[valueField];
        if (!groupKeyValue) {
          throw new Error('Group must have a valuefield');
        }
        const items = grouped[groupKeyValue];
        if (items?.length) {
          result.push({
            ...group,
            group: true,
          });
          result.push(...items);
        }
      }
      return result;
    }
    if (showEmpty) {
      result = [{ [valueField]: null, [textField]: '-' }, ...result];
    }
    return result;
  }, [initialItems, groupKey, showEmpty, initialGroups, valueField, textField]);

  const selectedValue = value && items.find((v) => v[valueField] === value);

  const itemRenderer2 = useMemo(
    () => itemRenderer(valueField, textField),
    [valueField, textField]
  );

  const itemPredicate2 = useMemo(() => itemPredicate(textField), [textField]);

  return (
    <BPSelect
      disabled={disabled}
      items={items}
      onBlur={onBlur}
      filterable={filterable}
      itemsEqual={itemsEqual || valueField}
      activeItem={selectedValue || null}
      // itemListRenderer={itemListRenderer(valueField, textField)}
      itemRenderer={itemRenderer2}
      itemPredicate={itemPredicate2}
      popoverProps={{
        fill: !!fill,
        portalClassName: 'selector',
        ...popoverProps,
      }}
      onItemSelect={onChange}
      noResults={<MenuItem disabled={true} text="No results." />}
      fill={fill}
    >
      <Button
        icon={icon || selectedValue?.icon}
        className={className}
        alignText={Alignment.LEFT}
        fill={fill}
        large={large}
        intent={intent}
        disabled={disabled}
        // This was to blend in with other controls
        // leaving it here for reference for the sec
        minimal={!intent || intent === 'none'}
        style={
          !intent || intent === 'none'
            ? {
                borderWidth: '1px',
                backgroundColor: 'white',
                borderColor: !intent ? 'lightgrey' : undefined,
                borderStyle: 'solid',
                borderRadius: '4px',
                outline: 'none',
              }
            : { outline: 'none' }
        }
        rightIcon="caret-down"
        text={
          displayedValue ||
          (selectedValue
            ? `${selectedValue[textField]}`
            : itemsDescription || '(Please select an option)')
        }
      />
    </BPSelect>
  );
};

export default Select;
