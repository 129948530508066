import React from 'react';
import ReactMarkdown from 'react-markdown';

// @ts-ignore
import styles from './styles.module.css';

interface MarkdownProps {
  content: string;
}
const Markdown = ({ content }: MarkdownProps) => {
  return (
    <div className={styles.block}>
      <ReactMarkdown
        linkTarget={'_blank'}
        components={{
          // root: React.Fragment,
          a: ({ node, children, ...props }) => {
            const linkProps = props;
            if (props.target === '_blank') {
              linkProps['rel'] = 'noopener noreferrer';
            }
            return <a {...linkProps}>{children}</a>;
          },
        }}
        children={content}
      />
    </div>
  );
};

export default Markdown;
