import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { DatePicker } from '../../DatePicker';
import { getErrorIntentClass } from './utils';

const DateComponent = ({
  value: currentValue,
  readOnly,
  fieldMeta,
  large,
  placeholder,
  fieldHelpers,
}) => {
  return (
    <DatePicker
      inputProps={{
        className: cn(
          large && 'bp3-large',
          getErrorIntentClass(fieldMeta),
        ),
      }}
      large
      highlightCurrentDay
      showActionsBar
      fill
      shortcuts
      onChange={(date) => {
        let text = null;
        if (date) {
          text = DateTime.fromJSDate(date)
            .setZone('utc', { keepLocalTime: true })
            .startOf('day')
            .toJSDate()
            .toISOString()
            .substring(0, 10);
        }
        fieldHelpers.setValue(text);
      }}
      placeholder={placeholder}
      disabled={readOnly}
      currentDay={currentValue}
    />
  );
};

export default DateComponent;
