import { IconName, MaybeElement, NonIdealState } from '@blueprintjs/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import styles from './styles.module.css';

// interface ErrorProps {
//   title?: React.ReactNode;
//   description?: React.ReactChild;

interface ErrorProps {
  icon?: IconName | MaybeElement;
  title?: string;
  description?: string | JSX.Element;
  action?: JSX.Element;
}
const Error = ({ icon, title, description, action }: ErrorProps) => {
  const { t } = useTranslation();

  return (
    <NonIdealState
      icon={icon || 'warning-sign'}
      title={title ? title : t('Error Loading Data')}
      description={
        description || (
          <span>
            Try reloading the page, or contact us at{' '}
            <a className={styles.helpLink} href="mailto:help@scrtracker.com">
              help@scrtracker.com
            </a>{' '}
            if the problem persists
          </span>
        )
      }
      action={action}
    />
  );
};

export default Error;
