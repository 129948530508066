export const reservedKey = (key) => {
  return [
    undefined,
    null,
    '',
    'key',
    'owned',
    'owner',
    'lockable',
    'meta',
    'lock',
    'locked',
    'count',
  ].includes(key);
};

export const removeReservedKeys = (key) => {
  return !reservedKey(key);
};
