import { emptyValue } from '../comparers';
import { parseDate } from '../parsers';

export interface Field {
  key: string;
  dataType: string;
  arrayFields?: {
    key: string;
    dataType: string;
  }[];
}

export interface Difference {
  action: 'added' | 'deleted' | 'changed';
  to?: any;
  from?: any;
}

export type FieldDifference = Difference & {
  field: string;
};

const isDate = (date: unknown): boolean => {
  return date instanceof Date && !isNaN(date.valueOf());
};

const defaultComparer = (left: unknown, right: unknown): boolean => {
  return left === right;
};

const dateComparer = (left: any, right: any) => {
  if (isDate(left) || isDate(right)) {
    left = parseDate(left);
    right = parseDate(right);
    if (left.isValid && right.isValid) {
      return +left === +right;
    }
  }
  return false;
};

const dataTypeComparers = {
  string: defaultComparer,
  boolean: defaultComparer,
  number: defaultComparer,
  date: dateComparer,
};

function compareValue(dataType: string, left: any, right: any): Difference {
  if (left === right) {
    return null;
  }

  if (emptyValue(left) && emptyValue(right)) {
    return null;
  }

  if (!emptyValue(left) && emptyValue(right)) {
    // deleted!
    return {
      action: 'deleted',
      from: left,
    };
  }

  if (emptyValue(left) && !emptyValue(right)) {
    return {
      action: 'added',
      to: right,
    };
  }

  const comparer = dataTypeComparers[dataType];
  if (!comparer(left, right)) {
    return {
      action: 'changed',
      from: left,
      to: right,
    };
  }
}

function compareArrayFields(
  fields: Field[],
  before: any,
  after: any
): Difference[] {
  const changes: Difference[] = [];

  for (let i = 0; i < Math.max(before?.length || 0, after?.length || 0); i++) {
    let left = before ? before[i] || {} : {};
    let right = after ? after[i] || {} : {};

    // eslint-disable-next-line no-use-before-define
    const diffs = compareDifferences(fields, left, right);

    for (const key of Object.keys(diffs)) {
      let diff: Difference = {
        ...diffs[key],
        index: i,
      };

      changes.push(diff);
    }
  }

  return changes;
}

export function compareDifferences(
  fields: Field[],
  before: any,
  after: any
): FieldDifference[] {
  if (!Array.isArray(fields)) {
    throw new Error('Fields must be an Array');
  }

  let changes: FieldDifference[] = [];

  if (before == null || typeof before !== 'object') {
    before = {};
  }
  if (after == null || typeof after !== 'object') {
    after = {};
  }

  for (const field of fields) {
    const left = before[field.key];
    const right = after[field.key];

    switch (field.dataType) {
      case 'none': {
        continue;
      }
      case 'array': {
        const diffs = compareArrayFields(field.arrayFields, left, right).map(
          (c) => ({
            field: field.key,
            ...c,
          })
        );
        changes.push(...diffs);
        break;
      }
      default: {
        const x = compareValue(field.dataType, left, right);
        if (x) {
          changes.push({
            field: field.key,
            ...x,
          });
        }
        break;
      }
    }
  }
  return changes;
}
