const DEVELOPMENT = 'development';
const STAGING = 'staging';
const PRODUCTION = 'production';

function getEnvironment() {
  const { protocol, host } = window.location;
  switch (host) {
    // Storybook
    case 'localhost:6006': {
      return {
        environment: DEVELOPMENT,
        serverUri: `${protocol}//localhost:3000`,
      };
    }
    case 'scrtracker-storybook.eu.ngrok.io': {
      return {
        environment: DEVELOPMENT,
        serverUri: `${protocol}//scrtracker-api.eu.ngrok.io`,
      };
    }
    // Development
    case 'localhost:3001':
    case 'localhost:3002': {
      return {
        environment: DEVELOPMENT,
        serverUri: `${protocol}//localhost:3000`,
      };
    }
    case 'localhost.charlesproxy.com:3001': {
      return {
        environment: DEVELOPMENT,
        serverUri: `${protocol}//localhost.charlesproxy.com:3000`,
      };
    }
    case 'scrtracker.ngrok.io':
    case 'development.scrtracker.com':
    case 'cognita.development.scrtracker.com':
    case 'oneschoolglobal.development.scrtracker.com': {
      return {
        environment: DEVELOPMENT,
        serverUri: `${protocol}//scrtracker-api.eu.ngrok.io`,
      };
    }
    case 'ben-linux:3001': {
      return {
        environment: DEVELOPMENT,
        serverUri: `${protocol}//ben-linux:3000`,
      };
    }
    // Staging
    case 'staging-web.scrtracker.com':
    case 'staging-admin.scrtracker.com': {
      return {
        environment: STAGING,
        serverUri: `${protocol}//staging-api.scrtracker.com`,
      };
    }
    default: {
      return {
        environment: PRODUCTION,
        serverUri: `${protocol}//api.scrtracker.com`,
      };
    }
  }
}

export const environmentTypes = {
  DEVELOPMENT,
  STAGING,
  PRODUCTION,
};
export default getEnvironment;
