import React from 'react';
import events from '../components/Analytics/events';
import { Federation, Organisation, Profile, User } from '../types';

const OrganisationContext = React.createContext<Organisation>(
  null as unknown as Organisation
);

const ProfileContext = React.createContext<Profile>(null as unknown as Profile);
const UserContext = React.createContext<User>(null as unknown as User);

const FederationContext = React.createContext<Federation>(
  null as unknown as Federation
);

interface Analytics {
  events: typeof events;
  reset: () => void;
}

const AnalyticsContext = React.createContext<Analytics>(
  null as unknown as Analytics
);

const ConfigContext = React.createContext<any>(null);

export {
  ConfigContext,
  UserContext,
  OrganisationContext,
  FederationContext,
  AnalyticsContext,
  ProfileContext,
};
