import registryFactory from './registryFactory';
import calculateFactory from './calculateFactory';

import * as functions from './functions';

const registry = registryFactory();
const calculate = calculateFactory(registry);

/*
{
  calculation: {
    function: 'addDuration',
    source: 'fieldname',
    durationType: 'days',
    duration: 5
  }
}
*/

Object.keys(functions).forEach((key) => {
  registry.add(key, functions[key]);
});

// registry.add('cognitaRiskAssessment', {
//   output: 'boolean',
//   // Start Date Ready Scoring - If assesment is open and not signed off,
//   // and review date is missing or in the past then not start date ready
//   fields: [],
// });

export { registry, calculate };
