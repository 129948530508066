// import log from '@scrtracker/logging';

export function isSupportAdminDomain(email: string): boolean {
  if (typeof email !== 'string') {
    return false;
  }

  return (
    email.endsWith('@scrtracker.com') ||
    email.endsWith('@signinapp.com') ||
    email.endsWith('@signinsolutions.com')
  );
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const getDefault = (b: any, d?: boolean) => {
  return b == null ? d : !!b;
};

export function isObject(item: any): boolean {
  return (
    item &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    typeof item !== 'function'
  );
}

export const isObjectLike = (parameter: any): boolean => {
  if (typeof parameter === 'object' && parameter !== null) {
    return true;
  }
  return false;
};

export const traverseObject = (
  object,
  handler: (value: any, path: string[], object: any) => any,
  options?: {
    invokeHandlerForObjects: boolean;
  },
  path = []
) => {
  for (const key of Object.keys(object)) {
    path.push(key);
    const value = object[key];
    if (isObject(value)) {
      let continueTraverse = true;
      if (options?.invokeHandlerForObjects) {
        continueTraverse = handler(value, path, object) !== false;
      }
      if (continueTraverse) {
        traverseObject(value, handler, options, path);
      }
    } else {
      handler(value, path, object);
    }
    path.pop();
  }
};

export const keys = <T>(
  obj: { [key: string]: T },
  options: {
    outputKeyName?: string;
    includeNulls?: boolean;
  } = {}
): (T & { key: string })[] => {
  const { outputKeyName = 'key', includeNulls = true } = options;
  if (obj == null) return [];
  if (!isObjectLike(obj)) return [];
  return Object.keys(obj).reduce((prev, key) => {
    let value = obj[key];
    if (value == null) {
      if (includeNulls) {
        prev.push({ [outputKeyName]: key });
      }
    } else if (!isObjectLike(value)) {
      prev.push({ value, [outputKeyName]: key });
    } else if (Array.isArray(value)) {
      prev.push({ value, [outputKeyName]: key });
    } else {
      prev.push({ ...value, [outputKeyName]: key });
    }
    return prev;
  }, []);
};

export const lookup = <T>(list: T[], key = 'key'): { [key: string]: T } => {
  if (!Array.isArray(list)) return {};
  return list.reduce((prev, item) => {
    prev[item[key]] = item;
    return prev;
  }, {});
};

export function cleanObject<T>(object: T): Partial<T> {
  const result = { ...object };
  Object.keys(result).forEach((key) => {
    if (typeof result[key] === 'undefined') {
      delete result[key];
    }
  });
  return result;
}

export function veryCleanObject<T extends object = any>(
  object: T | T[],
  options?: {
    removeNull?: boolean;
    recursive?: boolean;
  }
): T {
  if (Array.isArray(object)) {
    // @ts-ignore
    return object.map((o) => veryCleanObject<T>(o, options));
  }

  if (typeof object !== 'object') {
    return object;
  }

  const result = {};
  for (const key of Object.keys(object || {})) {
    if (options?.removeNull && object[key] === null) {
      continue;
    }
    if (typeof object[key] === 'undefined') {
      continue;
    } else if (typeof object[key] === 'function') {
      continue;
    } else if (Array.isArray(object[key]) && object[key].length === 0) {
      continue;
    } else if (
      typeof object[key] === 'object' &&
      object[key] !== null &&
      Object.keys(object[key]).length === 0
    ) {
      continue;
    } else if (options?.recursive && Array.isArray(object[key])) {
      result[key] = object[key].map((o) => veryCleanObject(o, options));
    } else {
      result[key] = object[key];
    }
  }
  return result as T;
}

export function assertArgs(args: any, debugInfo?: string) {
  if (!args) return;
  Object.keys(args).forEach((key) => {
    if (args[key] == null) {
      throw new Error(
        `${debugInfo ? `${debugInfo}: ` : ''}Argument not Specified: ${key}`
      );
    }
  });
}

export const toArray = (object: any): any[] =>
  Object.keys(object || {}).reduce((prev, key) => [...prev, object[key]], []);

export const toArrayKeyed = (object) =>
  Object.keys(object || {}).reduce(
    (prev, key) => [
      ...prev,
      {
        key,
        ...object[key],
      },
    ],
    []
  );

// interface OrderComparable {
//   order: number
// }
// const orderComparer = <T extends OrderComparable>(
//   input1: T,
//   input2: T,
// ): number => {
//   const v1 = input1.order || 0;
//   const v2 = input2.order || 0;
//   return v1 - v2;
// };
// interface OrderComparable {
//   order: number
// }
const orderComparer = (input1, input2) => {
  const v1 = input1.order || 0;
  const v2 = input2.order || 0;
  return v1 - v2;
};

export const sortByOrder = (array) => {
  if (!Array.isArray(array)) {
    return array;
  }
  const sortme = [...array];
  sortme.sort(orderComparer);
  return sortme;
};
// export const sortByOrder = <T extends OrderComparable>(array: T[]): T[] => {
//   if (!Array.isArray(array)) {
//     return array;
//   }
//   const sortme = [...array];
//   sortme.sort(orderComparer);
//   return sortme;
// };

export const arraySame = <T>(previous: T[], compare: T[]): boolean => {
  if (!previous || !compare) return false;
  return (
    previous
      .filter((x) => !compare.includes(x))
      .concat(compare.filter((x) => !previous.includes(x))).length == 0
  );
};

export const formatBytes = (bytes: number): string => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const kiloByte = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(kiloByte));
  const convertedBytes = parseFloat((bytes / Math.pow(kiloByte, i)).toFixed(0));

  return `${convertedBytes} ${
    convertedBytes === 1 && i < 1 ? 'Byte' : sizes[i]
  }`;
};

interface MongoDuration {
  unit: string;
  amount: number;
}

export function parseMongoDuration(duration: string): MongoDuration {
  if (!duration) return null;

  let [unit, amountStr] = duration.split(':');

  switch (unit) {
    case 'immediate': {
      return {
        unit: 'day',
        amount: 0,
      };
    }
    case 'days':
    case 'weeks':
    case 'months':
    case 'years': {
      // remove the 's'
      unit = unit.substring(0, unit.length - 1);
      break;
    }
    default: {
      return null;
    }
  }

  if (!amountStr) {
    return null;
  }

  const amount = Number.parseInt(amountStr);
  if (Number.isNaN(amount)) {
    return null;
  }

  return {
    unit,
    amount,
  };
}

export function isPureObject(obj: any): obj is object {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

/** Remove any non-printable characters from a string */
export const cleanString = (
  input: string,
  options?: {
    allowNewLine?: boolean;
    allowTabs?: boolean;
  }
): string => {
  if (typeof input !== 'string') {
    return input;
  }
  return input.replace(
    new RegExp(
      `[^\x20-\x7E${options?.allowTabs ? '\t' : ''}${
        options?.allowNewLine ? '\n\r' : ''
      }]`,
      'g'
    ),
    ''
  );
};

export const cleanObjectValues = (
  input: any,
  options?: {
    allowNewLine?: boolean;
    allowTabs?: boolean;
  }
): any => {
  if (typeof input === 'string') {
    return cleanString(input, options);
  }
  if (Array.isArray(input)) {
    return input.map((i) => cleanObjectValues(i, options));
  }
  if (!isPureObject(input)) {
    return input;
  }
  return Object.keys(input).reduce((prev, key) => {
    if (Array.isArray(input[key])) {
      prev[key] = input[key].map((i) => cleanString(i, options));
      return prev;
    }
    // Do not recusively clean objects
    prev[key] = cleanString(input[key], options);
    return prev;
  }, {});
};
