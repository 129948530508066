import React, { useContext } from 'react';
import { getEndpoint, createClient } from '@prismicio/client';
import { PrismicProvider } from '@prismicio/react';
import { ConfigContext } from '@/context';

const endpoint = getEndpoint('scrtracker-app');

export const getClient = (accessToken) =>
  createClient(endpoint, { accessToken });

const PrismicProviderWrapper = ({ children }) => {
  const config = useContext(ConfigContext);

  return (
    <PrismicProvider client={getClient(config.PRISMIC_ACCESS_TOKEN)}>
      {children}
    </PrismicProvider>
  );
};

export default PrismicProviderWrapper;
