import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Tag from '../../Tag';
//@ts-ignore
import styles from './styles.module.css';

interface TagItem {
  id: string;
  label: string;
  color?: string;
}
interface SelectedTagsProps {
  selectedTags: TagItem[];
  multiline?: boolean;
  onRemoveTag?: (id: string) => void;
}
const SelectedTags = ({
  selectedTags,
  multiline,
  onRemoveTag,
}: SelectedTagsProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  const [hiddenItems, setHiddenItems] = useState(0);
  const [visibleItems, setVisibleItems] = useState(0);

  useEffect(() => {
    if (!multiline) {
      itemsRef.current = itemsRef.current.slice(0, selectedTags.length);
      const measurements = itemsRef.current.reduce(
        (prev, itemRef) => {
          const currentItemWidth = itemRef?.clientWidth || 0;
          const remainingWidth = prev.remainingWidth - currentItemWidth;
          let hiddenItems = prev.hiddenItems;
          let visibleItems = prev.visibleItems;
          if (remainingWidth < 0) {
            hiddenItems += 1;
          } else {
            visibleItems += 1;
          }
          return { remainingWidth, hiddenItems, visibleItems };
        },
        {
          remainingWidth: containerRef.current?.clientWidth || 0,
          hiddenItems: 0,
          visibleItems: 0,
        }
      );
      setHiddenItems(measurements.hiddenItems);
      setVisibleItems(measurements.visibleItems);
    }
  }, [multiline, selectedTags]);

  return (
    <>
      <div
        ref={containerRef}
        className={cn(styles.tagContainer, {
          [styles.wrappedContainer]: !!multiline,
        })}
      >
        {selectedTags &&
          selectedTags.map((tag, idx) => (
            <div
              ref={(el) => (itemsRef.current[idx] = el)}
              key={tag.id}
              style={{
                visibility:
                  multiline || idx < visibleItems ? 'visible' : 'hidden',
              }}
            >
              <Tag
                label={tag.label}
                color={tag.color}
                onRemove={onRemoveTag ? () => onRemoveTag(tag.id) : undefined}
              />
            </div>
          ))}
      </div>
      {!!hiddenItems && (
        <p className={styles.hiddenCount}>{`+${hiddenItems}`}</p>
      )}
    </>
  );
};

export default SelectedTags;
