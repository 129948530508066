import { ConditionGroup } from './types';
import { isEmptyCondition } from './utils';

export const mergeConditions = (
  condition1: Partial<ConditionGroup>,
  condition2: Partial<ConditionGroup>
): ConditionGroup => {
  if (!condition1) {
    return {
      conditions: [],
      enabled: true,
      operator: 'and',
      variables: {},
      ...condition2,
    };
  }
  const { variables: variables1, ...condition1rest } = condition1;
  const { variables: variables2, ...condition2rest } = condition2;

  if (isEmptyCondition(condition1rest)) {
    return {
      conditions: [],
      variables: {
        ...variables1,
        ...variables2,
      },
      ...condition2rest,
    };
  }

  if (isEmptyCondition(condition2rest)) {
    return {
      conditions: [],
      variables: {
        ...variables1,
        ...variables2,
      },
      ...condition1rest,
    };
  }

  return {
    variables: {
      ...variables1,
      ...variables2,
    },
    conditions: [
      {
        conditions: [],
        ...condition1rest,
      },
      {
        conditions: [],
        ...condition2rest,
      },
    ],
    operator: 'and',
    enabled: true,
  };
};

export const mergeAllConditions = (
  conditions?: ConditionGroup[]
): ConditionGroup => {
  if (!conditions) return null;
  const conditions2 = conditions.filter((c) => {
    if (!c) return false;
    if (!c.conditions || c.conditions.length === 0) return false;
    return true;
  });
  if (conditions2.length === 0) {
    return null;
  }
  if (conditions2.length === 1) {
    return conditions2[0];
  }
  return conditions2.reduce((prev, condition) => {
    return mergeConditions(prev, condition);
  }, null);
};
