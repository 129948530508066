const invert = (original) => {
  if (typeof original === 'function') {
    throw new Error('Deprecated');
  }
  return {
    parameters: { ...original.parameters },
    method: (value?: any, params?: any) => !original.method(value, params),
  };
};

export const oneOf = {
  parameters: {
    values: 'array|string',
  },
  method: (value?: any, params?: any) => {
    let { values } = params || {};

    // if values is a string, split it by comma
    if (typeof values === 'string') {
      if (values.trim().length === 0) {
        return false;
      }
      values = values.split(',');
    }

    if (Number.isInteger(value)) {
      values = values.map(Number);
    }

    if (!Array.isArray(values)) {
      return false;
    }

    if (Array.isArray(value)) {
      return !!value.find((v) => values.includes(v));
    }

    return values.includes(value);
  },
};
export const notOneOf = invert(oneOf);

export const isTrue = (value) => value === true;
export const isFalse = (value) => value !== true;

export const equals = {
  parameters: {
    value: 'any',
  },
  method: function equals(left, params) {
    return left == params.value;
  },
};
export const notEqual = invert(equals);

export const isNull = (left) => {
  if (typeof left === 'string') {
    return left.trim() === '';
  }
  return left === '' || left == null;
};
export const isNotNull = (left) => !isNull(left);

export const contains = {
  parameters: {
    value: 'string',
  },
  method: function contains(left, params) {
    let { value } = params || {};

    if (typeof left !== 'string') {
      return false;
    }

    if (typeof value !== 'string') {
      return false;
    }

    if (value.length === 0) {
      return false;
    }

    if (left === value) {
      return true;
    }

    return left.toLowerCase().includes(value.toLowerCase());
  },
};
export const notContains = invert(contains);

export const startsWith = {
  parameters: {
    value: 'string',
  },
  method: function startsWith(left, params) {
    let { value } = params || {};

    if (typeof left !== 'string') {
      return false;
    }

    if (typeof value !== 'string') {
      return false;
    }

    if (value.length === 0) {
      return false;
    }

    return left.toLowerCase().startsWith(value.toLowerCase());
  },
};

export const endsWith = {
  parameters: {
    value: 'string',
  },
  method: function endsWith(left, params) {
    let { value } = params || {};

    if (typeof left !== 'string') {
      return false;
    }

    if (typeof value !== 'string') {
      return false;
    }

    if (value.length === 0) {
      return false;
    }

    return left.toLowerCase().endsWith(value.toLowerCase());
  },
};

// function isValid(left, params, data) {
//   const { field } = params || {};
//   if (!data.valid) {
//     log.warn('No Validated Fields', {
//       params,
//       data,
//     });
//     return undefined;
//   }
//   return !!data.valid[field];
// }

export const lessThan = {
  parameters: {
    value: 'any',
  },
  method: (left, params) => {
    return left < params.value;
  },
};

export const greaterThan = {
  parameters: {
    value: 'any',
  },
  method: (left, params) => {
    return left > params.value;
  },
};

export const lessThanOrEqual = {
  parameters: {
    value: 'any',
  },
  method: (left, params) => {
    return left <= params.value;
  },
};

export const greaterThanOrEqual = {
  parameters: {
    value: 'any',
  },
  method: (left, params) => {
    return left >= params.value;
  },
};
