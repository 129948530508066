import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const SSOPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Spinner className={styles.loginSpinner} intent="primary" />
      <p className={styles.loggingInMessage}>
        {t('Redirecting to Single Sign On...')}
      </p>
    </>
  );
};

export default SSOPage;
