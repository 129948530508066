import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

const UnknownComponent = ({ type }) => {
  const { t } = useTranslation();
  return (
    <Callout className={'mt-3 mb-3'} intent={Intent.DANGER} title={t(`Error`)}>
      {t(`Unknown Component [${type}]`)}
    </Callout>
  );
};

export default UnknownComponent;
