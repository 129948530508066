import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const TabBar = ({ brandColor, tabs }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column justify-content-end h-100">
      {tabs && Array.isArray(tabs) && (
        <ul className="list-unstyled d-flex justify-content-center m-0 mt-2">
          {tabs.map((tab, idx) => (
            <li key={idx}>
              <NavLink
                className={cn(styles.app_nav__link)}
                activeClassName={styles.app_nav__link_active}
                activeStyle={{ color: brandColor }}
                to={tab.link}
                exact={tab.exact}
                onClick={tab.onClick}
                isActive={tab.isActive}
              >
                <span>{t(tab.title)}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TabBar;
