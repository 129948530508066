import { Intent, Menu, MenuItem, Popover, Tooltip } from '@blueprintjs/core';
import React, { useMemo, useRef, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid/v4';
import Button from '../Button';
import styles from './styles.module.css';

const isAllowed = (value: any) => {
  if (typeof value === 'undefined') return true;
  return !!value;
};

const useRefresh = () => {
  let counter = useRef(0);
  const [, setAnything] = useState(0);
  return () => {
    setAnything(++counter.current);
  };
};

export interface Action {
  text: string;
  onClick: any;
  caret?: boolean;
  spin?: boolean;
  minimal?: boolean;
  intent?: Intent;
  allowed?: boolean;
  icon?: IconProp;
  tooltip?: string;
  disabled?: boolean;
  component?: JSX.Element;
  rightIcon?: IconProp;
  confirm?: boolean;
}

export interface ActionWithChildren extends Action {
  actions?: Action[];
}

type ActionWithId = Action & {
  id: string;
  actions?: (Action & { id: string })[];
};

interface ActionBarProps {
  actions: (ActionWithChildren | undefined | boolean | null)[];
  large?: boolean;
}

export const ActionBar = ({
  actions: initialActions,
  large = true,
}: ActionBarProps) => {
  const { t } = useTranslation();

  // Tracks the state of actions (cannot use state)
  const executingActions = useRef<Record<string, boolean>>({});
  // Used to cause a refresh of the page
  const refresh = useRefresh();

  const wrapOnClick =
    (id: string, action: Action) =>
    async (...args: any[]) => {
      if (executingActions.current[id]) {
        console.log('Action is already executing');
        return;
      }
      try {
        executingActions.current[id] = true;
        refresh();
        if (action.onClick) {
          await action.onClick(...args);
        }
      } catch (e) {
      } finally {
        executingActions.current[id] = false;
        refresh();
      }
    };

  const actions = useMemo<ActionWithId[]>(() => {
    if (!Array.isArray(initialActions)) return [];
    return initialActions.filter(Boolean).map((action) => {
      let childActions: ActionWithId[];
      childActions =
        action.actions?.filter(Boolean).map((action) => {
          const id = uuid();
          return {
            ...action,
            onClick: action.onClick && wrapOnClick(id, action),
            id,
          } as ActionWithId;
        }) || [];

      const id = uuid();
      return {
        ...action,
        id,
        onClick: action.onClick && wrapOnClick(id, action),
        actions: childActions,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialActions]);

  if (actions.length === 0) return null;

  return (
    <>
      {actions
        .map((action, idx) => {
          if (action.actions?.length) {
            if (!Array.isArray(action.actions)) return null;
            const childActions = action.actions.filter(Boolean);
            if (childActions.length === 0) return null;
            return (
              <Popover
                key={`ctxButtons-${idx}`}
                content={
                  <Menu large={large}>
                    {childActions.map((subAction, childidx) => {
                      const isExecuting =
                        executingActions.current[subAction.id];
                      const disabled =
                        !isAllowed(subAction.allowed) || subAction.disabled;
                      return (
                        <MenuItem
                          key={`menu-${idx}-${childidx}`}
                          text={t(subAction.text)}
                          intent={subAction.intent}
                          disabled={disabled}
                          icon={
                            <FontAwesomeIcon
                              className="mt-1"
                              spin={isExecuting || action.spin}
                              icon={
                                isExecuting
                                  ? 'spinner'
                                  : isAllowed(subAction.allowed)
                                  ? subAction.icon
                                  : 'lock'
                              }
                            />
                          }
                          onClick={subAction.onClick}
                        />
                      );
                    })}
                  </Menu>
                }
                position="bottom"
              >
                <Button
                  large={large}
                  minimal={action.minimal}
                  intent={action.intent}
                  allowed={action.allowed}
                  icon={action.icon}
                  rightIcon={action.caret !== false ? 'caret-down' : null}
                  className={cn(idx === 0 ? 'mr-1 mb-1 mt-1' : 'm-1')}
                >
                  {action.text && (
                    <span className="d-none d-lg-inline">{t(action.text)}</span>
                  )}
                </Button>
              </Popover>
            );
          }

          const isExecuting = executingActions.current[action.id];

          return (
            <Tooltip
              key={`ctxButtons-${idx}`}
              disabled={!action.tooltip || action.disabled}
              content={t<string>(action.tooltip || '')}
              popoverClassName={styles.tooltip}
            >
              {action.component ? (
                <div className="m-1">{action.component}</div>
              ) : (
                <Button
                  large={large}
                  className={cn(idx === 0 ? 'mr-1 mb-1 mt-1' : 'm-1')}
                  intent={action.intent}
                  confirm={action.confirm}
                  disabled={!isAllowed(action.allowed) || action.disabled}
                  onClick={action.onClick}
                  rightIcon={action.rightIcon}
                >
                  <FontAwesomeIcon
                    className={cn({ 'mr-lg-0': !!action.text })}
                    spin={isExecuting || action.spin}
                    icon={
                      isExecuting
                        ? 'spinner'
                        : isAllowed(action.allowed)
                        ? action.icon || ''
                        : 'lock'
                    }
                  />
                  <span className="d-none d-xl-inline ml-lg-1">
                    {t(action.text)}
                  </span>
                </Button>
              )}
            </Tooltip>
          );
        })
        .filter(Boolean)}
    </>
  );
};
