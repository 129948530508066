import { ConditionalStructureError, isValid } from '@hogwarts/conditionals';
import { isObjectLike } from '@hogwarts/utils';

const getConditionalReferences = (source, scheme, conditional): any[] => {
  const fields = {};
  const sections = {};
  const errors = [];
  if (!conditional || conditional.enabled === false) {
    return [fields, sections, errors];
  }

  if (process.env.NODE_ENV === 'test') {
    const [valid, reasons] = isValid(conditional);
    if (!valid) {
      throw new ConditionalStructureError(reasons);
    }
  }

  for (const key of Object.keys(conditional.variables || {})) {
    if (!isObjectLike(conditional.variables[key])) {
      continue;
    }

    if (!conditional.variables[key].source) {
      continue;
    }

    const [sourceType, sourceTarget] = conditional.variables[key].source;

    switch (sourceType) {
      case 'field':
      case 'fieldValid': {
        if (sourceTarget) {
          const sourceTargetField = scheme.data.fields[sourceTarget];
          if (!sourceTargetField) {
            errors.push({
              source,
              target: sourceTarget,
              message: `Invalid field referenced in condition`,
            });
          } else if (sourceTargetField.deleted) {
            errors.push({
              source,
              target: sourceTarget,
              message: `Deleted field referenced in condition`,
            });
          } else {
            fields[sourceTarget] = true;
          }
        } else {
          errors.push({
            source,
            message: `No field specified in condition`,
          });
        }
        break;
      }
      case 'section': {
        if (sourceTarget) {
          const sourceTargetSection = scheme.data.sections[sourceTarget];
          if (!sourceTargetSection) {
            errors.push({
              source,
              target: sourceTarget,
              message: `Invalid section referenced in condition`,
            });
          } else if (sourceTargetSection.deleted) {
            errors.push({
              source,
              target: sourceTarget,
              message: `Deleted section referenced in condition`,
            });
          } else {
            sections[sourceTarget] = true;
          }
        } else {
          errors.push({
            source,
            message: `No section specified in condition`,
          });
        }
        break;
      }
    }
  }

  return [fields, sections, errors];
};

const resultsToArray = (result) => {
  const { fields, sections, errors } = result;
  return {
    errors,
    fields: Object.keys(fields).reduce((prev, key) => {
      prev.push(key);
      return prev;
    }, []),
    sections: Object.keys(sections).reduce((prev, key) => {
      prev.push(key);
      return prev;
    }, []),
  };
};

const getAllConditionalReferences = (key, scheme, conditions) => {
  let fields = {};
  let sections = {};
  let errors = [];

  for (const condition of conditions) {
    const [fields2, sections2, errors2] = getConditionalReferences(
      key,
      scheme,
      condition
    );

    fields = { ...fields, ...fields2 };
    sections = { ...sections, ...sections2 };
    errors = [...errors, ...errors2];
  }

  return resultsToArray({ fields, sections, errors });
};

const getFieldGraph = (field, scheme) => {
  let conditions = [field.visibility];

  if (field.validation) {
    for (const vKey of Object.keys(field.validation)) {
      const v = field.validation[vKey];
      if (v && v.type === 'rule') {
        const rule = scheme.getValidationRule(v.rule);
        if (rule) {
          conditions.push(rule.condition);
        }
      }
    }
  }

  return getAllConditionalReferences(field.key, scheme, conditions);
};

const getSectionGraph = (section, scheme) => {
  return getAllConditionalReferences(section.key, scheme, [section.visibility]);
};

export const buildReferenceGraph = (scheme) => {
  let sections: any = {};
  let fields: any = {};

  // iterate through every section, field and arrayfield
  // building a flat list of sections and fields
  if (scheme.sections) {
    for (const section of scheme.sections) {
      sections[section.key] = {
        section,
        graph: getSectionGraph(section, scheme),
      };

      if (section.fields) {
        for (const field of section.fields) {
          fields[field.key] = {
            field,
            section,
            graph: getFieldGraph(field, scheme),
          };

          if (field.dataType === 'array' && Array.isArray(field.arrayFields)) {
            // if field is an array type
            // go through the sub fields
            // see if they reference anything
            for (const child of field.arrayFields) {
              fields[child.key] = {
                field: child,
                section,
                graph: getFieldGraph(child, scheme),
              };
            }
          }
        }
      }
    }
  }

  return {
    sections,
    fields,
  };
};
