import React from 'react';
import logo from './assets/logo/SICR-White-Background@2x.png';
import InvalidResetPage from './pages/invalid-reset';
import LoadingPage from './pages/loading';
import LoggingInPage from './pages/logging-in';
import LoginPage from './pages/login';
import MFAPage from './pages/mfa';
import PasswordResetPage from './pages/reset';
import PasswordResetRequestPage from './pages/reset-request';
import SSOPage from './pages/sso';

import styles from './styles.module.css';

const Login = ({
  state,
  error,
  isNewUser,
  lastCredentials,
  onLogin,
  onResetPassword,
  onResetEnd,
  onResetPasswordRequest,
  onResetPasswordRequestSubmitted,
  onResetPasswordRequestComplete,
  onVerifyToken,
  onLogout,
  children,
}) => {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.leftPanelContainer}>
        <img className={styles.leftPanelLogo} src={logo} alt="" />
        <div className={styles.loginForm}>
          {state === 'LOG-IN' && (
            <LoginPage
              lastCredentials={lastCredentials}
              loginError={error}
              onLogin={onLogin}
              onResetPassword={onResetPasswordRequest}
            />
          )}
          {state === 'PASSWORD-RESET-REQUEST' && (
            <PasswordResetRequestPage
              resetError={error}
              onResetPasswordSubmitted={onResetPasswordRequestSubmitted}
              onResetPasswordComplete={onResetPasswordRequestComplete}
            />
          )}
          {state === 'PASSWORD-RESET' && (
            <PasswordResetPage
              isNewUser={isNewUser}
              resetError={error}
              onResetPassword={onResetPassword}
              onResetEnd={onResetEnd}
            />
          )}
          {state === 'MFA' && (
            <MFAPage
              verifyError={error}
              onVerifyToken={onVerifyToken}
              onLogout={onLogout}
            />
          )}
          {state === 'SSO' && <SSOPage />}
          {state === 'LOGGING-IN' && <LoggingInPage />}
          {state === 'LOADING' && <LoadingPage />}
          {state === 'PASSWORD-RESET-REQUEST-ERROR' && (
            <InvalidResetPage
              resetError={error}
              onResetEnd={onResetEnd}
              onResetPassword={onResetPasswordRequest}
            />
          )}
        </div>
      </div>
      <div className={styles.rightPanelContainer}>{children}</div>
    </div>
  );
};

export default Login;
