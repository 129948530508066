import { validationSchema as conditionalSchema } from '@hogwarts/conditionals';
import { Structure } from '@hogwarts/utils-schemes';

const dataTypes = ['none', 'string', 'number', 'date', 'boolean', 'array'];
const ruleTypes = ['condition', 'regex'];
const ruleSeverity = ['none', 'information', 'warning', 'error'];

const isUsable = (item) => {
  if (item.deleted) return false;
  return !(
    item.enabled === false &&
    (item.lock.enabled === true || item.meta.enabled.locked === true)
  );
};

export const validationRules: Structure = {
  key: 'validationRules',
  type: 'objects-dynamic',
  options: {
    objectsMustBeOwned: false,
  },
  structure: [
    {
      key: 'type',
      type: 'string',
      oneOf: ruleTypes,
      required: true,
    },
    {
      key: 'dataType',
      locked: true,
      oneOf: dataTypes,
      type: 'string',
      required: true,
    },

    {
      key: 'pattern',
      type: 'string',
      runtime: (item: { type: string }) => {
        if (item.type === 'regex') {
          return { required: true };
        }
      },
    },

    // These are additional fields (keyed object) that will map to
    // the form builder parameters
    { key: 'params', type: 'object' },

    { key: 'condition', type: 'object', validation: conditionalSchema },

    { key: 'label', type: 'string', required: true },

    { key: 'failInput', type: 'boolean', defaultValue: false },
    { key: 'failRatings', type: 'boolean', defaultValue: false },
    { key: 'dismissable', type: 'boolean', defaultValue: true },
    { key: 'message', type: 'string', required: true },
    {
      key: 'severity',
      type: 'string',
      oneOf: ruleSeverity,
      defaultValue: 'warning',
    },

    { key: 'whenValid', type: 'object' },
    { key: 'ignoreEmpty', type: 'boolean', defaultValue: false },
  ],
};

export const tags: Structure = {
  key: 'tags',
  type: 'objects-dynamic',
  sort: 'order',
  structure: [
    {
      key: 'condition',
      type: 'object',
      validation: conditionalSchema,
    },
    { key: 'label', type: 'string', required: true },
    { key: 'color', type: 'string' },
    { key: 'order', type: 'number', lockable: false },
    { key: 'enabled', type: 'boolean', required: true, defaultValue: true },
  ],
};

export const terms: Structure = {
  key: 'terms',
  type: 'objects-dynamic',
  options: {
    objectsMustBeOwned: false,
  },
  structure: [
    {
      key: 'singular',
      type: 'string',
    },
    {
      key: 'plural',
      type: 'string',
    },
  ],
};

export const profileTypes: Structure = {
  key: 'profileTypes',
  type: 'objects-dynamic',
  sort: 'order',
  structure: [
    { key: 'parent', type: 'string', required: false },
    { key: 'label', type: 'string', required: true },
    { key: 'avatar', type: 'string', required: true },
    { key: 'description', type: 'string' },
    { key: 'enabled', type: 'boolean', required: true, defaultValue: true },
    { key: 'color', type: 'string', editor: 'colorpicker' },
    { key: 'order', type: 'number', lockable: false },
  ],
  postProcess(
    item: { usable: boolean; parent: string; parentItem: unknown },
    keyedData
  ) {
    item.usable = isUsable(item);
    if (item.parent) {
      const parentItem = keyedData.profileTypes[item.parent];
      if (!parentItem) {
        item.usable = false;
      } else {
        item.parentItem = parentItem;
        if (!isUsable(parentItem)) {
          item.usable = false;
        }
      }
    }
  },
};

export const ratingSystems: Structure = {
  key: 'ratingSystems',
  type: 'objects-dynamic',
  sort: 'order',
  structure: [
    { key: 'label', type: 'string', required: true },
    { key: 'description', type: 'string' },
    {
      key: 'mandatory',
      type: 'boolean',
      defaultValue: false,
      required: true,
    },
    { key: 'readyColor', type: 'string', editor: 'colorpicker' },
    { key: 'notReadyColor', type: 'string', editor: 'colorpicker' },
    { key: 'order', type: 'number', defaultValue: 0, lockable: false },
    { key: 'enabled', type: 'boolean', required: true, defaultValue: true },
  ],
};

export const sections: Structure = {
  key: 'sections',
  type: 'objects-dynamic',
  structure: [
    // {
    //   key: 'required',
    //   type: 'string',
    //   required: true,
    //   defaultValue: 'optional',
    // },
    { key: 'label', type: 'string', required: true },
    { key: 'icon', type: 'string', required: true },
    { key: 'order', type: 'number', required: true, lockable: false },
    { key: 'color', type: 'string', required: true },

    { key: 'enabled', type: 'boolean', required: true, defaultValue: true },

    // Required, Optional, OptionalWithVerify

    // if its required, then its just on (So no need for the extra field?)
    // if its optional, then show the "Are checks required?"

    {
      key: 'visibility',
      type: 'object',
      validation: conditionalSchema,
    },
    {
      key: 'features',
      type: 'objects-known',
      structure: [
        {
          key: 'sectionPrint',
          type: 'object',
          structure: [{ key: 'enabled', type: 'boolean', defaultValue: false }],
        },
      ],
    },
  ],
  sort: 'order',
  lookups: [{ key: 'fields', ref: 'fields', fk: 'section', sort: 'order' }],
  postProcess(item: { usable: boolean; required: string }) {
    item.usable = isUsable(item);

    switch (item.required) {
      case 'required': {
        break;
      }
      case 'optional':
      case 'optionalWithVerify': {
        // insert an 'Are checks required' field
        // on calculating visibility, it would need to look at this field

        // item.fields;

        break;
      }
    }
  },
};

export const fields: Structure = {
  key: 'fields',
  type: 'objects-dynamic',
  sort: 'order',
  structure: [
    {
      // only applies to array fields
      key: 'parent',
      type: 'string',
      ref: 'fields',
      locked: true,
      runtime: (item: { section: string }) => {
        if (item.section) {
          return { required: false };
        }
      },
    },
    {
      // does not apply to array fields
      key: 'section',
      type: 'string',
      ref: 'sections',
      locked: true,
      runtime: (item: { parent: unknown }) => {
        if (item.parent) {
          return { required: false };
        }
      },
    },
    {
      key: 'dataType',
      locked: true,
      type: 'string',
      oneOf: dataTypes,
      required: true,
    },
    {
      key: 'calculation',
      type: 'object',
    },
    {
      key: 'function',
      type: 'string',
      runtime: (item: { calculation: unknown }) => {
        if (item.calculation) {
          return { required: true };
        }
      },
    },
    { key: 'defaultValue', type: 'any' },
    { key: 'forcedValue', type: 'any' },
    { key: 'readOnly', type: 'boolean' },
    { key: 'enabled', type: 'boolean', required: true, defaultValue: true },
    { key: 'inputType', type: 'string' },
    {
      key: 'label',
      type: 'string',
      required: true,
      runtime: (item: { inputType: string }) => {
        if (item.inputType === 'hidden') {
          return { required: false };
        }
      },
    },
    { key: 'preview', type: 'boolean' },

    {
      key: 'inputMeta',
      type: 'object',
    },
    { key: 'order', type: 'number', required: true },
    {
      key: 'visibility',
      type: 'object',
      validation: conditionalSchema,
    },

    // In dev
    {
      key: 'help',
      type: 'object',
      required: false,
    },

    {
      key: 'ratings',
      type: 'objects-join',
      joinRef: 'ratingSystems',
      structure: [
        { key: 'condition', type: 'object', validation: conditionalSchema },
        { key: 'enabled', type: 'boolean', defaultValue: false },
      ],
    },

    {
      key: 'validation',
      type: 'object',
      // validation: ruleSchema,
      // structure: [
      //   { key: 'type', type: 'string', required: true },
      //   { key: 'rule', type: 'string', ref: 'validationRules' },
      // ],
    },

    // only applies to date types
    // {
    //   key: 'expiration',
    //   type: 'object',
    //   // validation: ruleSchema,
    // },

    {
      key: 'features',
      type: 'objects-known',
      structure: [
        {
          key: 'profileMerge',
          type: 'object',
          structure: [
            { key: 'enabled', type: 'boolean', defaultValue: false },
            { key: 'order', type: 'number', required: true },
          ],
        },
        {
          key: 'profileAdd',
          type: 'object',
          structure: [
            { key: 'order', type: 'number', required: true },
            { key: 'enabled', type: 'boolean', defaultValue: false },
            { key: 'required', type: 'boolean', defaultValue: false },
          ],
        },
        {
          key: 'bulkImporter',
          type: 'object',
          structure: [{ key: 'enabled', type: 'boolean', defaultValue: true }],
        },
        {
          key: 'advancedEditor',
          type: 'object',
          structure: [{ key: 'enabled', type: 'boolean', defaultValue: true }],
        },
        {
          key: 'excelExport',
          type: 'object',
          structure: [{ key: 'enabled', type: 'boolean', defaultValue: true }],
        },
        {
          key: 'profileOverview',
          type: 'object',
          structure: [
            { key: 'enabled', type: 'boolean', defaultValue: false },
            { key: 'order', type: 'number', defaultValue: 0 },
          ],
        },
        {
          key: 'faker',
          type: 'object',
          structure: [
            { key: 'method', type: 'string' },
            { key: 'trueChange', type: 'number' },
            { key: 'enabled', type: 'boolean', defaultValue: true },
            { key: 'chance', type: 'number' },
            { key: 'minDate', type: 'date' },
            { key: 'maxDate', type: 'date' },
          ],
        },
      ],
    },
  ],
  lookups: [
    {
      key: 'arrayFields',
      ref: 'fields',
      fk: 'parent',
      // This ensures that only Array type fields
      // will have an arrayFields lookup property
      parentFilter: (item: { dataType: string }) => {
        return item.dataType === 'array';
      },
      ignoreArrayFilter: true,
      sort: 'order',
    },
  ],
  postProcess(
    item: {
      usable: boolean;
      section: string;
      deleted: boolean;
      sectionItem: unknown;
      parent: string;
      parentItem: unknown;
    },
    keyedData
  ) {
    let usable;

    if (item.parent) {
      const parent = keyedData.fields[item.parent];
      if (!parent || !isUsable(parent)) {
        usable = false;
      } else {
        usable = isUsable(item);
      }
      item.parentItem = parent;
      item.usable = usable;
    } else if (item.section) {
      const section = keyedData.sections[item.section];

      if (section?.deleted) {
        usable = false;
        item.deleted = true;
      } else if (!section || !section.usable) {
        usable = false;
      } else {
        usable = isUsable(item);
      }

      item.sectionItem = section;
      item.usable = usable;

      // const usage = {
      //   inUse: null,
      //   canUse: null,
      // };
      // Object.defineProperties(item, {
      //   inUse: {
      //     get() {
      //       return usage.inUse;
      //     },
      //     set(value) {
      //       usage.inUse = value;
      //     },
      //   },
      //   canUse: {
      //     get() {
      //       return usage.canUse;
      //     },
      //     set(value) {
      //       usage.canUse = value;
      //     },
      //   },
      // });
    }
  },
  // postFilter: (item: { section: string }) => {
  //   return !!item.section;
  // },
};

export const schemeStructure: Structure[] = [
  validationRules,
  terms,
  tags,
  profileTypes,
  ratingSystems,
  sections,
  fields,
];

export const profileTypeSchemeStructure: Structure = {
  key: 'profileTypeSchemes',
  type: 'objects-join',
  joinRef: 'profileTypes',
  structure: [validationRules, sections, fields],
};
