// import log from '@scrtracker/logging';
import common from './common';
import create from './create';
import federation from './federation';
import identityTypes from './identityTypes';
import organisation from './organisation';
import resourceTypes from './resourceTypes';
import system from './system';
import * as types from './types';

const builtInPermissions = {
  ...common,
  ...organisation,
  ...system,
  ...federation,
};

for (const key of Object.keys(builtInPermissions)) {
  if (key.toUpperCase() !== key) {
    throw new Error('Permission key should be uppercase');
  }
  if (
    builtInPermissions[key].key.toUpperCase() !== builtInPermissions[key].key
  ) {
    throw new Error('Permission key should be uppercase');
  }
  if (builtInPermissions[key].key !== key) {
    throw new Error(
      `Keys do not match ${builtInPermissions[key].key} <> ${key}`
    );
  }
}

export const normalizePermissions = (
  permission: string | string[] | types.Permission | types.Permission[]
): types.Permission[] => {
  if (!permission) {
    return [];
  }
  const permissions = Array.isArray(permission) ? permission : [permission];
  return permissions
    .map((p) => {
      if (p == null) {
        throw new Error(`Invalid Permission Passed [${p}]`);
      }
      if (typeof p === 'string') {
        return builtInPermissions[p];
      }
      return p;
    })
    .filter(Boolean);
};

export { types, create, resourceTypes, identityTypes };

export default builtInPermissions;
