export const parseBool = (val?: any) => {
  return (
    val === true ||
    (typeof val === 'string' &&
      (val.toLowerCase().trim() === 'true' ||
        val.toLowerCase().trim() === 'yes' ||
        val.toLowerCase().trim() === 'y'))
  );
};

export const validBool = (val?: any) => {
  if (val === true || val === false) return true;

  if (typeof val === 'string') {
    let sval = val.toLowerCase().trim();
    return (
      sval === 'true' ||
      sval === 'yes' ||
      sval === 'y' ||
      sval === 'false' ||
      sval === 'no' ||
      sval === 'n'
    );
  }

  return false;
};
