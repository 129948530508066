import { Popover, PopoverPosition } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

interface InfoPopoverProps {
  className?: string;
  monoButton?: boolean;
  type: string;
  content: string;
  popoverPosition?: PopoverPosition;
  modifiers?: any;
}
const InfoPopover = ({
  className,
  type = 'info',
  content,
  monoButton,
  popoverPosition = 'bottom',
  modifiers,
}: InfoPopoverProps) => {
  const { t } = useTranslation();

  let icon = 'info-circle';
  let color = '#118abb';
  if (type === 'warning') {
    icon = 'exclamation-triangle';
    color = '#bf7226';
  } else if (type === 'danger') {
    icon = 'exclamation-circle';
    color = '#c23030';
  }

  return (
    <Popover
      popoverClassName={styles.infoPopoverContainer}
      position={popoverPosition}
      modifiers={modifiers}
    >
      <FontAwesomeIcon
        // @ts-ignore
        icon={icon}
        className={cn(styles.popoverButton, className)}
        color={monoButton ? undefined : color}
      />
      <div className={styles.contentContainer}>
        <FontAwesomeIcon
          className={styles.infoIcon}
          size="2x"
          // @ts-ignore
          icon={['far', icon]}
          color={color}
        />
        {typeof content === 'string' ? (
          <p className={styles.content}>{t(content)}</p>
        ) : (
          <div className={styles.content}>{content}</div>
        )}
      </div>
    </Popover>
  );
};

export default InfoPopover;
