import Login from '@hogwarts/ui-components-login';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import authenticationService from '../../services/authentication';

const LoginCallbackContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const [loginState, setLoginState] = useState('LOADING');
  const [error, setError] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);

  async function checkRequest() {
    let requestId;
    if (location.search) {
      const query = qs.parse(location.search.substring(1));
      requestId = query.id;
      const newUser = !!query.newUser;
      if (!requestId) {
        setError('INVALID-URL');
        setLoginState('PASSWORD-RESET-REQUEST-ERROR');
        return;
      } else {
        setRequestId(requestId);
        setIsNewUser(newUser);
      }
    }

    const result = await authenticationService.checkResetRequest(requestId);
    if (result.status === 1) {
      setLoginState('PASSWORD-RESET');
    } else {
      setError('INVALID-ID');
      setLoginState('PASSWORD-RESET-REQUEST-ERROR');
    }
  }

  async function resetPassword(newPassword) {
    setError(null);
    const response = await authenticationService.resetPassword(
      requestId,
      newPassword
    );
    if (response.status !== 1) {
      setError('SET-ERROR');
      setLoginState('PASSWORD-RESET-REQUEST-ERROR');
    }
  }

  useEffect(() => {
    checkRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Login
      state={loginState}
      error={error}
      isNewUser={isNewUser}
      onResetPassword={(newPassword) => resetPassword(newPassword)}
      onResetEnd={() => {
        history.push('/');
      }}
      onResetPasswordRequest={() => {
        setLoginState('PASSWORD-RESET-REQUEST');
        setError(null);
      }}
      onResetPasswordRequestSubmitted={async (email) => {
        setError(null);
        const resetSuccess = await authenticationService.resetPasswordRequest(
          email
        );
        if (!resetSuccess) {
          setError('Unable to reset password.');
        }
      }}
      onResetPasswordRequestComplete={() => {
        history.push('/');
      }}
    />
  );
};

export default LoginCallbackContainer;
