import React, { useState } from 'react';
import { Label, InputGroup, Button, Callout } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const LoginPage = ({
  lastCredentials,
  loginError,
  onLogin,
  onResetPassword,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState(
    lastCredentials ?? { email: '', password: '' }
  );

  return (
    <>
      {loginError ? (
        <Callout
          className={styles.errorMessage}
          intent="danger"
          title="Error Logging In"
        >
          {loginError}
        </Callout>
      ) : (
        <p className={styles.headerMessage}>{t('Log In To Your Account')}</p>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onLogin(credentials);
        }}
      >
        <Label>
          <p className={styles.formLabel}>{t('Email Address')}</p>
          <InputGroup
            large
            type="email"
            autoComplete="email"
            autoFocus
            value={credentials.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
          />
        </Label>
        <Label>
          <p className={styles.formLabel}>{t('Password')}</p>
          <InputGroup
            large
            type={showPassword ? 'text' : 'password'}
            className="login__password"
            rightElement={
              <Button
                minimal
                icon={showPassword ? 'unlock' : 'lock'}
                intent="warning"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            }
            autoComplete="current-password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
          />
        </Label>
        <div className={styles.fullButton}>
          <Button
            large
            intent="primary"
            type="submit"
            disabled={!credentials.email || !credentials.password}
          >
            {t('Log In')}
          </Button>
        </div>
      </form>
      <p className={styles.forgottenPasswordHeader}>Forgotten your password?</p>
      <p>
        {t(
          "If you can't remember your password or are having trouble logging in, please click below and we'll reset your password."
        )}
      </p>
      <div className={styles.rightButton}>
        <Button
          intent={loginError ? 'warning' : 'none'}
          onClick={() => onResetPassword()}
        >
          {t('Reset Password')}
        </Button>
      </div>
    </>
  );
};

export default LoginPage;
