import { validBool, parseDate, parseNumber } from '@hogwarts/validation';

export const dataTypeValidation = {
  boolean: {
    type: 'function',
    execute: (value) => {
      if (value == null) return true;
      return validBool(value);
    },
    severity: 'error',
    message: 'Value must be a valid boolean',
    failInput: true,
    failRatings: true,
  },
  date: {
    type: 'function',
    execute: (value) => {
      if (value == null) return true;
      const date = parseDate(value);
      return date.isValid;
    },
    severity: 'error',
    message: 'Value must be a valid date',
    failInput: true,
    failRatings: true,
  },
  string: {
    type: 'function',
    execute: (value) => {
      if (value == null) return true;
      if (typeof value === 'string') {
        return true;
      }
      return false;
    },
    severity: 'error',
    message: 'Value must be a valid string',
    failInput: true,
    failRatings: true,
  },
  number: {
    type: 'function',
    execute: (value) => {
      if (value == null) return true;
      const parse = parseNumber(value);
      return parse != null;
    },
    message: 'Value must be a valid number',
    severity: 'error',
    failInput: true,
    failRatings: true,
  },
  array: {
    type: 'function',
    execute: (value) => {
      if (value == null) return true;
      return Array.isArray(value);
    },
    message: 'Value must be a valid Array',
    severity: 'error',
    failInput: true,
    failRatings: true,
  },
};
