import { isObject } from './utils';

const cloneObject = (object, options) => {
  const result = {};
  for (const key of Object.keys(object)) {
    const value = object[key];
    // eslint-disable-next-line no-use-before-define
    result[key] = deepClone(value, options);
  }
  return result;
};

const cloneString = (string) => {
  return (' ' + string).slice(1);
};

const tryCloneSomething = (object, options) => {
  const name = Object.getPrototypeOf(object).constructor.name;
  switch (name) {
    case 'Object': {
      return cloneObject(object, options);
    }
    case 'Date': {
      return new Date(+object);
    }
    default: {
      return object;
    }
  }
};

export const deepClone = (
  object,
  options = {
    cloneString: false,
  }
) => {
  if (object == null) return object;
  if (Array.isArray(object)) {
    return object.map((o) => deepClone(o, options));
  } else if (typeof object === 'function') {
    if (options.cloneString) {
      return cloneString(object);
    }
    return object;
  } else if (typeof object === 'function') {
    return object;
  } else if (isObject(object)) {
    return tryCloneSomething(object, options);
  } else {
    return object;
  }
};
