// import util from 'util';
import { Field, Scheme } from '@hogwarts/utils-schemes';

import { buildReferenceGraph } from './buildReferenceGraph';
import { calculateFieldValidity } from './calculateFieldValidity';
import { evaluateGroup } from '../conditionals';
import log from '@scrtracker/logging';
import { reduceProfile } from './reduceProfile';

const calcFieldVisible = (field: Field, data) => {
  let visible;
  if (field.section && data.visible[field.section] === false) {
    visible = false;
  } else if (field.enabled === false) {
    visible = false;
  } else if (field.visibility) {
    visible = !!evaluateGroup(field.visibility, {}, data, {
      timezone: data.timezone,
    });
  } else {
    visible = true;
  }
  return visible;
};

const calculateFieldData = (scheme: Scheme, field: Field, data: any) => {
  // if it has a parent,
  // then we're part of an arrayField
  // each block needs to be iterated through.

  const { section, parent } = field;
  let values;
  if (parent) {
    const parentItem = scheme.getField(parent);

    const itemsVisible: boolean[] = [];
    const itemsValid: boolean[] = [];
    const itemsReasons = [];
    if (Array.isArray(data.values[parent])) {
      for (let index = 0; index < data.values[parent].length; index++) {
        let siblingVisibility = {};
        for (const siblingField of parentItem.arrayFields) {
          // should be an array
          let siblingFieldVisibility = data.visible[siblingField.key];
          if (siblingFieldVisibility) {
            siblingVisibility[siblingField.key] = siblingFieldVisibility[index];
          }
        }

        const arrayItem = data.values[parent][index];
        // flatten it out so that the field will feel like
        // its just level like anything else.
        values = { ...data.values, ...arrayItem };
        delete values[parent];
        let data2 = {
          ...data,
          values,
          visible: {
            ...data.visible,
            ...siblingVisibility,
          },
        };

        const visible = calcFieldVisible(field, data2);
        if (visible) {
          // TODO: if its dismissable, check the meta data to see if its been dismissed already?
          const [valid, reasons] = calculateFieldValidity(
            scheme,
            field,
            values[field.key],
            data2,
            data.timezone
          );
          itemsValid.push(valid);
          itemsReasons.push(reasons || []);
        } else {
          itemsValid.push(false);
          itemsReasons.push([]);
        }
        itemsVisible.push(visible);
      }
    }
    data.visible[field.key] = itemsVisible;
    data.valid[field.key] = itemsValid;
    data.reasons[field.key] = itemsReasons;

    // store the visible/valid/reasons as arrays on the key
    // so its one for each item in the array
  } else if (section) {
    values = data.values;

    // TODO: if the section isn't visible or enabled
    // then the bloody field shouldn't be either
    const visible = calcFieldVisible(field, data);
    if (visible) {
      // TODO: if its dismissable, check the meta data to see if its been dismissed already?
      const [valid, reasons] = calculateFieldValidity(
        scheme,
        field,
        values[field.key],
        {
          ...data,
          values,
        },
        data.timezone
      );
      data.visible[field.key] = true;
      data.valid[field.key] = valid;

      const expiryFieldKey = `${field.key}_expiry`;
      if (data.values[expiryFieldKey]) {
        data.visible[expiryFieldKey] = true;
        data.valid[expiryFieldKey] = valid;
      }

      // @ts-ignore not sure what its on about
      if (reasons?.length) {
        data.reasons[field.key] = reasons;
      }
    } else {
      data.visible[field.key] = false;
      data.valid[field.key] = false;
    }
  }
};

const calculateSectionData = (scheme, section, data) => {
  let visible;
  if (section.enabled === false) {
    visible = false;
  } else if (section.visibility) {
    visible = !!evaluateGroup(section.visibility, {}, data, {
      timezone: data.timezone,
    });
  } else {
    visible = true;
  }
  data.visible[section.key] = visible;
};

const traverseGraph = (scheme, values, timezone) => {
  const rootGraph = buildReferenceGraph(scheme);
  if (!rootGraph) {
    throw new Error('No graph?');
  }

  const data = {
    values,
    valid: {},
    visible: {},
    errors: {},
    reasons: {},
    timezone: timezone || 'utc',
  };

  const addError = (key, error) => {
    if (!data.errors[key]) {
      data.errors[key] = [];
    }
    data.errors[key].push(error);
  };

  const visited = {};

  const traverseGraphItem = (item, graph, isField, path) => {
    if (visited[item.key]) {
      addError(item.key, {
        message: `Circular Reference detected`,
        path: path.join('.'),
      });
      return;
    }

    visited[item.key] = true;

    for (const sectionKey of graph.sections) {
      if (!rootGraph.sections[sectionKey]) {
        log.warn(`Invalid section referenced in filter [${sectionKey}]`);
        addError(sectionKey, {
          message: `Invalid section referenced in condition`,
          target: sectionKey,
          source: item.key,
        });
        visited[item.key] = false;
        continue;
      }
      const { section, graph: childGraph } = rootGraph.sections[sectionKey];
      traverseGraphItem(section, childGraph, false, [...path, sectionKey]);
    }

    for (const fieldKey of graph.fields) {
      if (!rootGraph.fields[fieldKey]) {
        log.warn(`Invalid field referenced in filter [${fieldKey}]`);
        addError(fieldKey, {
          message: `Invalid field referenced in condition`,
          target: fieldKey,
          source: item.key,
        });
        visited[item.key] = false;
        continue;
      }
      const { field, graph: childGraph } = rootGraph.fields[fieldKey];
      traverseGraphItem(field, childGraph, true, [...path, fieldKey]);
    }

    visited[item.key] = false;

    // once finished drilling down, deal directly with the item
    if (isField) {
      calculateFieldData(scheme, item, data);
    } else {
      calculateSectionData(scheme, item, data);
    }
  };

  for (const sectionKey of Object.keys(rootGraph.sections)) {
    const { section, graph } = rootGraph.sections[sectionKey];
    traverseGraphItem(section, graph, false, [sectionKey]);
  }
  for (const fieldKey of Object.keys(rootGraph.fields)) {
    const { field, graph } = rootGraph.fields[fieldKey];
    traverseGraphItem(field, graph, true, [fieldKey]);
  }

  delete data.timezone;

  return data;
};

export function calculateProfileSchemeValidity(scheme, profile, timezone) {
  if (!scheme) throw new Error(`Invalid Scheme`);
  if (!profile) throw new Error(`Invalid Profile`);
  if (typeof timezone !== 'string') throw new Error('Invalid Timezone');

  const values = reduceProfile(scheme, profile, {
    dateFormat: 'luxon',
  });

  return traverseGraph(scheme, values, timezone);
}
