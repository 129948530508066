import { Button, EditableText } from '@blueprintjs/core';
import React, { useState } from 'react';
import uuid from 'uuid/v4';
import DeletePopover from '../Popovers/DeletePopover';
import { ReorderableList } from './ReorderableList';
import styles from './styles.module.css';

const TextEditComponent = ({ item, onEdit, onDelete, disabled }) => {
  return (
    <div
      className={'d-flex flex-col justify-content-between align-items-center'}
    >
      <EditableText
        className="w-100 mr-2"
        placeholder={item.placeHolder || '(Blank)'}
        defaultValue={item.value}
        confirmOnEnterKey
        maxLines={3}
        multiline
        maxLength={120}
        onChange={(value) => onEdit(item.id, value.trim())}
        disabled={disabled}
      />
      {!item.new && (
        <DeletePopover
          disabled={disabled}
          onRemove={onDelete}
          buttonComponent={
            <Button minimal small icon="trash" intent={'danger'} />
          }
        />
      )}
    </div>
  );
};

const removeNew = (items) => items.filter((i) => !i.new);

const EditableList = ({ items: initialItems, disabled, onChange }) => {
  const addNew = (items) =>
    disabled
      ? items
      : [
          ...items,
          {
            id: uuid(),
            new: true,
            value: '',
            placeHolder: 'Click to Add a new Item',
            disableSort: true,
          },
        ];

  const [items, setItems] = useState(() => {
    let result = initialItems || [];
    if (typeof result === 'string') {
      // This is some bs but it basically deals with the 2 types
      // of lists I've managed to create in the app
      const byLineEnd = result.split('\n');
      const byComma = result.split(',');
      if (byLineEnd.length > byComma.length) {
        result = byLineEnd;
      } else {
        result = byComma;
      }
    }
    const items2 = result.map((value, index) => {
      if (typeof value === 'string') {
        return { id: uuid(), value };
      }
      if (!value.id) {
        throw new Error('Id not specified');
      }
      return value;
    });

    return addNew(items2);
  }, [initialItems]);

  const updateItems = (items) => {
    if (disabled) return;
    setItems(items);
    if (onChange) {
      onChange(removeNew(items));
    }
  };

  return (
    <ReorderableList
      containerClassName={styles.parentContainer}
      className={styles.container}
      items={items}
      disabled={disabled}
      onChanged={updateItems}
      componentProps={{
        onEdit: (id, value) => {
          if (disabled) return;
          const item = items.find((i) => i.id === id);
          if (item.new) {
            // add item!
            const changed = addNew([
              ...removeNew(items),
              // add the item with same id
              {
                id,
                value,
              },
            ]);
            updateItems(changed);
            return;
          }
          const changed = items.map((item) => {
            if (item.id === id) {
              return {
                id,
                value,
              };
            }
            return item;
          });
          updateItems(changed);
        },
      }}
      Component={TextEditComponent}
    />
  );
};

export default EditableList;
