import { parseDate } from '@hogwarts/validation';
import { DateTime } from 'luxon';

export default {
  key: 'currentAge',
  label: 'Calculates a persons age',
  output: 'date',
  fields: [
    {
      key: 'dateofbirth',
      label: 'Date of Birth',
      type: 'field',
      dataType: 'date',
    },
  ],
  execute: ({ data }, params) => {
    if (!data) return null;

    let dateOfBirth = parseDate(data[params.dateofbirth]);
    if (!dateOfBirth.isValid) {
      return null;
    }

    let today = DateTime.utc().startOf('day');
    dateOfBirth = dateOfBirth.startOf('day');

    const age = Math.trunc(today.diff(dateOfBirth, 'year').years);

    if (age < 0 || age === -0) return 0;

    return age;
  },
};
