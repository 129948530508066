import {
  afterToday,
  beforeToday,
  getDaysDiff,
  parseDate,
  today,
} from '@hogwarts/validation';

// import log from '@scrtracker/logging';
import { DateTime } from 'luxon';

export const reverse = (text) => {
  return text.split('').reverse().join('');
};

export const timestamp = (left, __, options) => {
  const date = parseDate(left, options.timezone);
  if (!date.isValid) {
    return 0;
  }
  return date.toMillis(); // +date;
};

export const regex = {
  parameters: {
    regex: 'string',
  },
  method: (left, data) => {
    const re = new RegExp(data.regex, 'gi');
    return re.exec(left) !== null;
  },
};

export const daysDifference = (left, __, options) => {
  return getDaysDiff(left, options.timezone);
};

export const monthsDifference = (left, __, options) => {
  const date = parseDate(left, options.timezone);
  if (!date.isValid) {
    return null;
  }
  const compare = DateTime.utc().setZone(options.timezone);
  return date.month - compare.month + (date.year - compare.year) * 12;
};

// TODO: Can we replace these with range operator?
export const isBeforeToday = (left, __, options) => {
  return beforeToday(left, options.timezone);
};

export const isToday = (left, __, options) => {
  return today(left, options.timezone);
};

export const isAfterToday = (left, __, options) => {
  return afterToday(left, options.timezone);
};
