import {
  AllCommunityModules,
  ModuleRegistry,
} from '@ag-grid-community/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
import { AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ApolloProvider } from '@apollo/client';
import { FocusStyleManager } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import * as Sentry from '@sentry/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'date-time-format-timezone';
import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import 'react-toggle/style.css';
import 'react-widgets/dist/css/react-widgets.css';
import 'setimmediate';
// import registerServiceWorker from './registerServiceWorker';
import { QueryParamProvider } from 'use-query-params';
import Config from './config';
import App from './containers';
import initialiseFontAwesome from './fontAwesome';
import initialiseCustomFormComponents from './formComponents';
import './i18n.js';
import './index.css';
import './logging';
import PrismicProvider from './prismic';
import { apolloClientFactory, authenticationService } from './services';
import { AppToaster } from './utils/toaster';

const packageJson = require('../package.json');

ModuleRegistry.registerModules(AllCommunityModules);
ModuleRegistry.registerModules(AllEnterpriseModules);

initialiseFontAwesome();
initialiseCustomFormComponents();

// https://blueprintjs.com/docs/#core/accessibility.focus-management
FocusStyleManager.onlyShowFocusOnTabs();

const Root = () => {
  const client = useMemo(
    () =>
      apolloClientFactory(
        authenticationService.getAuthorisationHeaders,
        (exception, customData) => {
          const { message } = customData;
          if (message?.includes('Not Authorised')) {
            if (process.env.NODE_ENV !== 'development') {
              authenticationService.logout();
              window?.location.reload();
            } else {
              AppToaster.show({
                icon: 'error',
                intent: 'danger',
                message,
              });
            }
          } else {
            if (process.env.NODE_ENV !== 'development') {
              Sentry.captureException(exception);
              return;
            }
            AppToaster.show(
              {
                icon: 'error',
                intent: 'danger',
                message: 'Error retrieving data',
              },
              'apollo_error'
            );
          }
        },
        'web',
        packageJson.version
      ),
    []
  );

  const onConfigLoad = useCallback((config) => {
    const result = {
      ...config,
      WEB_VERSION: packageJson.version,
    };
    LicenseManager.setLicenseKey(result.AGGRID_LICENSE_KEY);
    authenticationService.initialise(result);
    authenticationService.checkSSO();
    return result;
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<>{'An error has occurred'}</>}>
      <Config onConfigLoad={onConfigLoad}>
        <ApolloProvider client={client}>
          <PrismicProvider>
            <Router>
              <QueryParamProvider ReactRouterRoute={Route}>
                <App />
              </QueryParamProvider>
            </Router>
          </PrismicProvider>
        </ApolloProvider>
      </Config>
    </Sentry.ErrorBoundary>
  );
};

const WrappedRoot = Sentry.withProfiler(Root);
ReactDOM.render(<WrappedRoot />, document.getElementById('root'));
