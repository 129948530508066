import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const IconBar = ({ icons }) => {
  const { t } = useTranslation();
  const [activeIcon, setActiveIcon] = useState(null);

  return (
    <div className={styles.iconContainer}>
      {icons &&
        Array.isArray(icons) &&
        icons.filter(Boolean).map((icon) => (
          <icon.component
            id={icon.id}
            key={icon.name}
            setActive={(isActive) => {
              if (isActive && activeIcon !== icon.name) {
                setActiveIcon(icon.name);
              } else if (!isActive && activeIcon === icon.name) {
                setActiveIcon(null);
              }
            }}
            {...icon.componentProps}
          >
            <Button
              minimal
              className={styles.iconButton}
              icon={
                activeIcon === icon.name ? (
                  <FontAwesomeIcon
                    icon={icon.activeIcon || icon.icon}
                    size="2x"
                    title={t(icon.title)}
                    color={icon.color}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={icon.icon}
                    size="2x"
                    title={t(icon.title)}
                    color={icon.color}
                  />
                )
              }
            />
          </icon.component>
        ))}
    </div>
  );
};

export default IconBar;
