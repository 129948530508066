import cn from 'classnames';
import { partition, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import Toggle from 'react-toggle';
import styles from './styles.module.css';

type Selected = Record<string, boolean>;
interface Role {
  id: string;
  name: string;
  order: number;
  category?: string;
  permissions: { key: string }[];
}
type RoleWithSelected = Role & { forceSelected: boolean };
interface RoleSelectionProps {
  roles: Role[];
  selected: Selected;
  onChange?: (selected: Selected) => void;
}
export const RoleSelection = ({
  roles: unsortedRoles,
  onChange,
  selected = {},
}: RoleSelectionProps) => {
  const roles = useMemo(() => {
    const [noCategory, withCategory] = partition(
      unsortedRoles,
      (r) => r.category == null
    );

    let groupedByCategory: Record<
      string,
      {
        categoryOrder: number;
        roles: Role[];
      }
    > = {};
    for (const roleWithCategory of sortBy(withCategory, 'order')) {
      if (!groupedByCategory[roleWithCategory.category as string]) {
        groupedByCategory[roleWithCategory.category as string] = {
          categoryOrder: roleWithCategory.order,
          roles: [],
        };
      }

      groupedByCategory[roleWithCategory.category as string].roles.push(
        roleWithCategory
      );
    }

    let groups = [];
    for (const key in groupedByCategory) {
      groups.push(groupedByCategory[key]);
    }
    groups = sortBy(groups, (g) => g.categoryOrder);

    const groups2 = groups.reduce<Role[]>(
      (prev, group) => [...prev, ...group.roles],
      []
    );

    return [...sortBy(noCategory, ['order']), ...groups2];
  }, [unsortedRoles]);

  const selectedRoles = useMemo<RoleWithSelected[]>(() => {
    // calculate which roles are overriden by the others
    let permissions: Record<string, boolean> = {};
    // go through all the selected roles, get the total permissions
    for (const role of roles) {
      if (selected[role.id]) {
        for (const permission of role.permissions) {
          permissions[permission.key] = true;
        }
      }
    }
    const roles2: RoleWithSelected[] = [];
    // go through all the roles and find which ones are effectively selected?
    for (const role of roles) {
      // ignore roles with no permissions
      if (!role.permissions?.length) {
        continue;
      }

      if (!selected[role.id]) {
        let forceSelected = true;
        for (const permission of role.permissions) {
          if (!permissions[permission.key]) {
            forceSelected = false;
            break;
          }
        }
        roles2.push({
          ...role,
          forceSelected,
        });
      } else {
        roles2.push({
          ...role,
          forceSelected: false,
        });
      }
    }
    return roles2;
  }, [roles, selected]);

  return selectedRoles.reduce<JSX.Element[]>((prev, role, index) => {
    let lastItem = index > 0 ? selectedRoles[index - 1] : null;

    if (role.category && lastItem?.category !== role.category) {
      prev.push(
        <div
          className={cn(
            'text-uppercase',
            'font-weight-bold',
            styles.categoryText,
            'mb-2',
            'mt-3'
          )}
        >
          {role.category}
        </div>
      );
    }

    prev.push(
      <div key={role.id} className={'row'}>
        <p className={'col-10'}>{role.name}</p>
        <Toggle
          className={'col-2'}
          checked={role.forceSelected || !!selected[role.id]}
          disabled={role.forceSelected}
          onChange={(event: any) => {
            if (role.forceSelected) {
              return;
            }
            const roleSelected = event.target.checked;
            const updatedSelected = {
              ...selected,
              [role.id]: roleSelected,
            };
            if (typeof onChange === 'function') {
              onChange(updatedSelected);
            }
          }}
          icons={false}
        />
      </div>
    );
    return prev;
  }, []);
};

export default RoleSelection;
