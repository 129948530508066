import { schemePatchFactory } from '@hogwarts/utils-schemes';
import {
  profileTypeSchemeStructure,
  schemeStructure,
} from '../schemeBuilder/schemeStructure';

export { schemePatchFactory };

export const schemePatcher = schemePatchFactory(
  [
    ...schemeStructure,
    profileTypeSchemeStructure,
    {
      key: 'version',
      type: 'number',
    },
  ],
  {
    safeMode: true,
  }
);

export default schemePatcher;
