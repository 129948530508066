// import ReactJson from 'react-json-view';
import { Callout } from '@blueprintjs/core';
import { get, set } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import componentRegistry from './components';

interface Field {
  key: string;
  type: string;
  label: string;
  valueField: string;
  textField: string;
  values: { id: string; label: string };
  readOnly?: boolean;
  meta?: any;
  visible?: boolean;
  defaultValue?: unknown;
}
interface FieldHelpers {
  key: string;
  values: Record<string, unknown>;
  setValue(value: any): void;
  setFieldValue(fieldKey: string, value: any): void;
}

const getInitialValues = (
  initialValues: Record<string, any>,
  fields: Field[]
) => {
  const result =
    fields &&
    fields.filter(Boolean).reduce((prev, field) => {
      if (!field.key) return prev;
      let value = get(initialValues, field.key);
      if (typeof value !== 'undefined') {
        set(prev, field.key, value);
      } else if (typeof field.defaultValue !== 'undefined') {
        set(prev, field.key, field.defaultValue);
      }
      return prev;
    }, {});
  return result;
};

const checkForDuplicateFieldKeys = (fields: Field[]) => {
  // Check for duplicate keys. Throw an error if discovered.
  fields.reduce<Record<string, boolean>>((prev, field) => {
    if (!field.key) return prev;
    if (prev[field.key]) {
      throw new Error(`Duplicate Key ${field.key}`);
    }
    return { ...prev, [field.key]: true };
  }, {});
};

const filterVisibleFields = (fields: Field[]) => {
  return fields.filter(
    (field) => field && field.visible !== false && field.type !== 'hidden'
  );
};

interface FieldProps {
  fieldKey: string;
  label: string;
  type: string;
  fieldMeta: any;
  fieldHelpers: FieldHelpers;
  readOnly?: boolean;
  componentProps: Record<string, any>;
}
const FieldComponent = ({
  fieldKey,
  label,
  type,
  fieldMeta,
  fieldHelpers,
  componentProps,
  readOnly,
}: FieldProps) => {
  const Component = useMemo(() => {
    return componentRegistry[type || 'textbox'];
  }, [type]);

  if (!Component) {
    return <Callout>{`Unknown Component [${type}]`}</Callout>;
  }

  return (
    <Component
      key={fieldKey}
      {...componentProps}
      label={label}
      value={fieldMeta.value}
      fieldMeta={fieldMeta}
      fieldHelpers={fieldHelpers}
      readOnly={readOnly}
    />
  );
};

interface FieldContextProps {
  touched: Record<string, boolean>;
  errors: Record<string, any>;
  valid: Record<string, boolean>;
  setFieldValue(key: string, value: any): void;
  values: Record<string, any>;
}
const FieldContext = React.createContext<FieldContextProps | undefined>(
  undefined
);

interface RenderFieldsProps {
  fields: Field[];
  readOnly?: boolean;
}
export const RenderFields = ({
  fields,
  readOnly: globalReadOnly,
}: RenderFieldsProps) => {
  const { setFieldValue, values, errors, touched, valid } =
    useContext(FieldContext)!;

  return (
    <>
      {fields.map((field, index) => {
        let { key, type, label, meta, readOnly, ...rest } = field;
        key = field.key || `generated_index_${index}`;

        const fieldMeta = {
          value: get(values, key),
          errors: get(errors, key),
          touched: get(touched, key),
          valid: get(valid, key),
        };
        const fieldHelpers: FieldHelpers = {
          get key() {
            return key;
          },
          get values() {
            return { ...values };
          },
          setValue(value) {
            // TODO: Could we add an interceptor here
            // for parsing?
            // I want to set other keys too...
            return setFieldValue(key, value);
          },
          setFieldValue(fieldKey, value) {
            return setFieldValue(fieldKey, value);
          },
        };

        return (
          <FieldComponent
            key={key}
            fieldKey={key}
            type={type}
            label={label}
            readOnly={globalReadOnly || readOnly}
            fieldMeta={fieldMeta}
            fieldHelpers={fieldHelpers}
            componentProps={{
              ...meta,
              ...rest,
            }}
          />
        );
      })}
    </>
  );
};

interface ControlBuilderProps {
  fields: Field[];
  readOnly?: boolean;
  initialValues: Record<string, any>;
  NoVisibleFieldsComponent?: React.FC;
  onValuesChanged: (values: Record<string, unknown>) => void;
}
export const ControlBuilder = ({
  fields,
  initialValues,
  NoVisibleFieldsComponent,
  onValuesChanged,
  readOnly,
}: ControlBuilderProps) => {
  const initialSetup = useRef(true);

  const [values, setValues] = useState(getInitialValues(initialValues, fields));

  const [touched, setTouched] = useState({});

  // TODO: Run these through some functions to determine errors!
  const [errors, setErrors] = useState({});
  const [valid, setValid] = useState({});

  useEffect(() => {
    if (initialSetup.current === true) return;
    if (onValuesChanged) {
      const changedValues = fields.reduce((prev, field) => {
        set(prev, field.key, get(values, field.key));
        return prev;
      }, {});
      onValuesChanged(changedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => checkForDuplicateFieldKeys(fields), [fields]);

  const visibleFields = useMemo(() => filterVisibleFields(fields), [fields]);

  useEffect(() => {
    if (initialSetup.current === true) {
      initialSetup.current = false;
    }
  });

  const setFieldValue = useCallback(
    (key: string, value: unknown) => {
      const updatedValues = { ...values };
      const updatedTouched = { ...touched };
      set(updatedValues, key, value);
      set(updatedTouched, key, true);
      setValues(updatedValues);
      setTouched(updatedTouched);
    },
    [values, touched, setValues, setTouched]
  );

  if (visibleFields.length === 0) {
    if (NoVisibleFieldsComponent) {
      return <NoVisibleFieldsComponent />;
    }
    return null;
  }

  return (
    <FieldContext.Provider
      value={{
        values,
        errors,
        touched,
        valid,
        setFieldValue,
      }}
    >
      <RenderFields readOnly={readOnly} fields={visibleFields} />
    </FieldContext.Provider>
  );
};
