import { DateTime } from 'luxon';
import { parseDate } from '../parsers';

export function getDaysDiff(date, timezone) {
  let now = DateTime.utc()
    .setZone(timezone || 'utc')
    .startOf('day');
  let parsed = parseDate(date, timezone);
  if (parsed.isValid) {
    const diff = parsed.startOf('day').diff(now, ['days', 'hours']);
    return diff.days;
  }
  return null;
}

export function beforeToday(date, timezone) {
  const days = getDaysDiff(date, timezone);
  if (days != null) {
    return days < 0;
  }
  return null;
}

export function todayOrBefore(date, timezone) {
  const days = getDaysDiff(date, timezone);
  if (days != null) {
    return days <= 0;
  }
  return null;
}

export function today(date, timezone) {
  const days = getDaysDiff(date, timezone);
  if (days != null) {
    return days === 0;
  }
  return null;
}

export function todayOrLater(date, timezone) {
  const days = getDaysDiff(date, timezone);
  if (days != null) {
    return days >= 0;
  }
  return null;
}

export function afterToday(date, timezone) {
  const days = getDaysDiff(date, timezone);
  if (days != null) {
    return days > 0;
  }
  return null;
}
