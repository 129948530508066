import { toArrayKeyed } from '@hogwarts/utils';

export default () => {
  const registry = {};
  return {
    add(key, value) {
      if (registry[key]) {
        throw new Error(`Function [${key}] is already registered`);
      }
      registry[key] = value;
    },
    get(key) {
      if (!registry[key]) {
        return null;
      }
      return {
        ...registry[key],
      };
    },
    getAll() {
      return toArrayKeyed(registry);
    },
  };
};
