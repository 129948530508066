import { parseBool, parseDate, parseNumber } from '@hogwarts/validation';

export const ratingsValidation = {
  boolean: {
    type: 'function',
    execute: (value) => {
      return parseBool(value) === true;
    },
    severity: 'error',
    message: 'Value must be selected',
    failRatings: true,
  },
  date: {
    type: 'function',
    execute: (value) => {
      const date = parseDate(value);
      return date.isValid;
    },
    severity: 'error',
    message: 'Date must be specified',
    failRatings: true,
  },
  string: {
    type: 'function',
    execute: (value) => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      return false;
    },
    severity: 'error',
    message: 'Text must be specified',
    failRatings: true,
  },
  number: {
    type: 'function',
    execute: (value) => {
      const parse = parseNumber(value);
      return parse != null;
    },
    severity: 'error',
    message: 'Number is not valid',
    failRatings: true,
  },
  array: {
    type: 'function',
    execute: () => {
      return true;
    },
    severity: 'error',
    failRatings: true,
  },
};
