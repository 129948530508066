import winston from 'winston';
import * as apm from './apm';
import levels from './levels';
import createLoggerInterface from './loggerInterface';
import * as transports from './transports';
import { Logger, LoggerOptions, LogLevel, PlainObject } from './types';

const defaultOptions: LoggerOptions = {
  level: LogLevel.TRACE,
  transports: [transports.standardConsole(false)],
};

let defaultLogger: {
  logger: winston.Logger;
  options: LoggerOptions;
} = {
  logger: winston.createLogger({
    levels,
    level: defaultOptions.level,
    transports: defaultOptions.transports,
  }),
  options: defaultOptions,
};
let defaultLoggerInterface: Logger = createLoggerInterface(defaultLogger);
let defaultOverridenAlready = false;

export const createLogger = (options: LoggerOptions): Logger => {
  if (defaultOverridenAlready) {
    defaultLoggerInterface.debug('Default Logger already registered');
    return defaultLoggerInterface;
  }

  const extendedLogger = winston.createLogger({
    levels,
    level: options.level,
    transports: options.transports,
  });

  defaultLogger.logger?.close();
  defaultLogger = {
    logger: extendedLogger,
    options,
  };
  defaultLoggerInterface = createLoggerInterface(defaultLogger);
  defaultOverridenAlready = true;

  return defaultLoggerInterface;
};

export const createChild = (
  parentOptions: LoggerOptions,
  metadata: PlainObject
): Logger => {
  return createLoggerInterface({
    logger: defaultLogger.logger,
    options: {
      ...parentOptions,
      metadata: {
        ...parentOptions.metadata,
        ...metadata,
      },
    },
  });
};

export const closeAll = () => {
  defaultLoggerInterface?.close();
  defaultLoggerInterface = null;
};

export { apm, transports, Logger, LogLevel, LoggerOptions };

export default <Logger>{
  trace: (message: string, metadata?: PlainObject) => {
    defaultLoggerInterface?.trace(message, metadata);
  },
  debug: (message: string, metadata?: PlainObject) => {
    defaultLoggerInterface?.debug(message, metadata);
  },
  info: (message: string, metadata?: PlainObject) => {
    defaultLoggerInterface?.info(message, metadata);
  },
  warn: (message: string, metadata?: PlainObject) => {
    defaultLoggerInterface?.warn(message, metadata);
  },
  error: (message: string, error?: Error | null, metadata?: PlainObject) => {
    defaultLoggerInterface?.error(message, error, metadata);
  },
  fatal: (message: string, error?: Error | null, metadata?: PlainObject) => {
    defaultLoggerInterface?.fatal(message, error, metadata);
  },
  child: (metadata?: PlainObject): Logger => {
    return createChild(defaultLogger.options, metadata);
  },
  close: () => {
    defaultLoggerInterface?.close();
  },
};
