import { getDateRange } from '../getDateRange';
import log from '@scrtracker/logging';

/*
is
is not
is later than
is earlier than
is exactly or later than
is exactly or earlier than
*/

const parameters = (values) => {
  if (values.range === 'exact') {
    return {
      range: 'string',
      value: 'date',
    };
  }

  if (values.range === 'relative-minus' || values.range === 'relative-plus') {
    return {
      range: 'string',
      from: 'date',
      duration: 'string',
    };
  }

  return {
    range: 'string',
  };
};

export const isDuring = {
  parameters,
  method: (
    left: any,
    { range, ...params }: { range?: string },
    options?: any
  ) => {
    if (!left?.isValid) {
      log.debug(`IsDuring: Invalid Left Value [${left}]`);
      return false;
    }
    const [start, end] = getDateRange(range, {
      ...params,
      timezone: options.timezone,
    });
    return left >= start && left <= end;
  },
};

export const isNotDuring = {
  parameters,
  method: (left: any, { range, ...params }: { range?: string }, options) => {
    if (!left?.isValid) {
      log.debug(`IsNotDuring: Invalid Left Value [${left}]`);
      return false;
    }
    const [start, end] = getDateRange(range, {
      ...params,
      timezone: options.timezone,
    });
    return left < start || left > end;
  },
};

export const earlierThan = {
  parameters,
  method: (left: any, { range, ...params }: { range?: string }, options) => {
    if (!left?.isValid) {
      log.debug(`EarlierThan: Invalid Left Value [${left}]`);
      return false;
    }
    const [start] = getDateRange(range, {
      ...params,
      timezone: options.timezone,
    });
    return left < start;
  },
};

export const laterThan = {
  parameters,
  method: (left: any, { range, ...params }: { range?: string }, options) => {
    if (!left?.isValid) {
      log.debug(`LaterThan: Invalid Left Value [${left}]`);
      return false;
    }
    // eslint-disable-next-line
    const [start, end] = getDateRange(range, {
      ...params,
      timezone: options.timezone,
    });
    return left > end;
  },
};

export const exactlyOrEarlierThan = {
  parameters,
  method: (left: any, { range, ...params }: { range?: string }, options) => {
    if (!left?.isValid) {
      log.debug(`ExactlyOrEarlierThan: Invalid Left Value [${left}]`);
      return false;
    }
    // eslint-disable-next-line
    const [start, end] = getDateRange(range, {
      ...params,
      timezone: options.timezone,
    });
    return left <= end;
  },
};

export const exactlyOrLaterThan = {
  parameters,
  method: (left: any, { range, ...params }: { range?: string }, options) => {
    if (!left?.isValid) {
      log.debug(`ExactlyOrLaterThan: Invalid Left Value [${left}]`);
      return false;
    }
    // eslint-disable-next-line
    const [start, end] = getDateRange(range, {
      ...params,
      timezone: options.timezone,
    });
    return left >= start;
  },
};
