import React from 'react';

// this is our distinct toggle control throughout the app
// can we skin the blueprint one to match?
import Toggle from 'react-toggle';
import { parseBool } from '@hogwarts/validation';

const ToggleComponent = ({
  fieldHelpers,
  value: rawValue,
  fieldMeta,
  readOnly,
}) => {
  const value = parseBool(rawValue);
  if (fieldMeta.error) {
    // show this in red?
  }
  return (
    <Toggle
      onChange={async (event) => {
        const checked = event.target.checked;
        fieldHelpers.setValue(checked);
      }}
      checked={!!value}
      icons={true}
      disabled={readOnly}
    />
  );
};

export default ToggleComponent;
