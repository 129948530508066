import React from 'react';
import { DateTime } from 'luxon';
import { TimePicker } from '@blueprintjs/datetime';

// https://github.com/moment/luxon/issues/675
// https://support.google.com/chrome/thread/29828561/chrome-80-shows-timestamp-24-xx-instead-of-00-00?hl=en
const TIME_ISO = {
  hour: '2-digit',
  minute: '2-digit',
  // hour12: false,
  hourCycle: 'h23',
};

const TimeComponent = ({ value, fieldHelpers, readOnly }) => {
  const parsed = value
    ? DateTime.fromISO(value, {
        zone: 'utc',
      })
    : null;

  return (
    <TimePicker
      selectAllOnFocus
      useAmPm
      value={parsed && parsed.isValid ? parsed.toJSDate() : null}
      onChange={async (dateObject) => {
        const date = DateTime.fromJSDate(dateObject);
        const value = date && date.toLocaleString(TIME_ISO);
        await fieldHelpers.setValue(value);
      }}
      disabled={readOnly}
    />
  );
};

export default TimeComponent;
