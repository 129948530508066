import React from 'react';
import callout from './callout';
import checkbox from './checkbox';
import checklist from './checklist';
import datepicker from './datepicker';
import duration from './duration';
import label from './label';
import multiselect from './multiselect';
import numeric from './numeric';
import object from './object';
import relativedate from './relativedate';
import singleselect from './singleselect';
import tabs from './tabs';
import textarea from './textarea';
import textbox from './textbox';
import time from './time';
import toggle from './toggle';

const componentRegistry: Record<string, React.FC<any>> = {
  duration,
  label,
  numeric,
  textbox,
  textarea,
  toggle,
  checkbox,
  callout,
  singleselect,
  multiselect,
  object,
  checklist,
  datepicker,
  time,
  tabs,
  relativedate,
};

export default componentRegistry;
