export default {
  key: 'getProfileName',
  label: 'Creates a name by combining first and last names',
  output: 'string',
  fields: [
    {
      key: 'firstname',
      label: 'First Name Field',
      type: 'field',
      dataType: 'string',
    },
    {
      key: 'lastname',
      label: 'Last Name Field',
      type: 'field',
      dataType: 'string',
    },
  ],
  execute: ({ data }, params) => {
    if (!data) return null;
    const firstname = (data[params.firstname] || '').trim();
    const lastname = (data[params.lastname] || '').trim();
    const fullname = `${firstname} ${lastname}`.trim();
    if (fullname === '') return null;
    return fullname;
  },
};
