export const min = (dates: Date[]): Date => {
  if (!dates?.length) return null;
  let result = dates[0];
  for (const date of dates) {
    if (date < result) {
      result = date;
    }
  }
  return result;
};

export const max = (dates: Date[]): Date => {
  if (!dates?.length) return null;
  let result = dates[0];
  for (const date of dates) {
    if (date > result) {
      result = date;
    }
  }
  return result;
};
