import { keys } from '@hogwarts/utils';
import { buildReferenceGraph } from './buildReferenceGraph';

const circularReferenceCheck2 = ({ items, refs, visited, errors, path }) => {
  for (const ref of refs) {
    path.push(ref);

    if (visited[ref]) {
      errors.push({
        path: [...path].join('.'),
        error: 'Circular reference',
      });
      continue;
    }

    const refItem = items[ref];
    visited[ref] = true;
    circularReferenceCheck2({
      items,
      refs: refItem,
      visited,
      path,
      errors,
    });
    visited[ref] = false;
  }
};

export const flatGraph = (graph) => {
  if (!graph) return null;
  const { sections, fields } = graph;
  const items: { [key: string]: any } = {};
  for (const section of Object.keys(sections || {})) {
    items[section] = [
      ...(sections[section].graph.sections || []),
      ...(sections[section].graph.fields || []),
    ];
  }
  for (const field of Object.keys(fields || {})) {
    items[field] = [
      ...(fields[field].graph.sections || []),
      ...(fields[field].graph.fields || []),
    ];
  }
  return items;
};

export const checkCircularReferences = (scheme) => {
  if (!scheme.isSchemeHelper) {
    // this is to capture mistakes from devs
    throw new Error('Expected scheme to be in a schemeHelper');
  }

  const graph = buildReferenceGraph(scheme);

  const allErrors = [];

  const items = flatGraph(graph);

  for (const item of keys(items)) {
    const visited = {};
    visited[item.key] = true;

    circularReferenceCheck2({
      items,
      refs: item.value,
      visited,
      path: [item.key],
      errors: allErrors,
    });

    visited[item.key] = false;
  }
  return allErrors;
};
