import { parseDate } from '@hogwarts/validation';
import { DateTime } from 'luxon';

export default {
  key: 'nextBirthday',
  label: 'Calculates when the next Birthday is',
  output: 'date',
  fields: [
    {
      key: 'dateofbirth',
      label: 'Date of Birth',
      type: 'field',
      dataType: 'date',
    },
  ],
  execute: ({ data }, params) => {
    if (!data) return null;

    let nextBirthday = parseDate(data[params.dateofbirth]);
    if (!nextBirthday.isValid) {
      return null;
    }

    let today = DateTime.utc().startOf('day');
    nextBirthday = nextBirthday.startOf('day');

    nextBirthday = nextBirthday.set({ year: today.year });

    if (nextBirthday < today) {
      nextBirthday = nextBirthday.set({ year: today.year + 1 });
    }

    return nextBirthday;
  },
};
