import {
  checkSchemeType,
  CollectionUsage,
  Scheme,
} from '@hogwarts/utils-schemes';
import { set, uniq, unset } from 'lodash';
import { schemeStructure, structureBuilderFactory } from '../schemeBuilder';
import { schemeProfileFactory } from './schemeProfileFactory';
import { weavePatchSchemes } from './weavePatchSchemes';

const deleteField = (schemeData: any, key: string) => {
  unset(schemeData, `fields.${key}`);
};

const deletableItems = {
  tags: (schemeData: any, key: string) => {
    unset(schemeData, `tag.${key}`);
  },
  sections: (schemeData: any, key: string) => {
    unset(schemeData, `sections.${key}`);

    for (const fieldKey of Object.keys(schemeData.fields || {})) {
      // remove any fields that belong to this section
      if (schemeData.fields[fieldKey].section === key) {
        deleteField(schemeData, fieldKey);
      }
    }
  },
  fields: deleteField,
  profileTypes: (schemeData: any, key: string) => {
    unset(schemeData, `profileTypes.${key}`);
    unset(schemeData, `profileTypeSchemes.${key}`);
  },
  ratingSystems: (schemeData: any, key: string) => {
    unset(schemeData, `ratingSystems.${key}`);
  },
};

export const deleteItem = (
  schemeData: any,
  itemType: string,
  itemKey: string
) => {
  const deleteFunction = deletableItems[itemType];
  if (!deleteFunction) {
    throw new Error(`Undeletable ItemType [${itemType}`);
  }
  if (typeof deleteFunction === 'function') {
    deleteFunction(schemeData, itemKey);
  }
  return { ...schemeData };
};

const getVersion = (scheme): string => {
  if (!scheme.version) {
    throw new Error('No version specified on the scheme');
  }
  if (typeof scheme.version === 'string') {
    return scheme.version;
  } else if (typeof scheme.version === 'object') {
    return scheme.version.id;
  }
  return scheme.version;
};

type Options = {
  profileTypeKey?: string;
  timezone?: string;
  includeDeleted?: boolean;
  skipAsterix?: boolean;
  calculateUsage?: boolean;
};

export const schemeHelperFactory = (
  schemes: any[] | any,
  options?: Options
): Scheme => {
  if (!schemes) return null;

  if (schemes.isSchemeHelper) {
    return schemes;
  }

  options = {
    profileTypeKey: null,
    timezone: null,
    includeDeleted: false,
    ...options,
  };

  if (!Array.isArray(schemes)) {
    throw new Error(
      'SchemeHelper only takes Array type of schemes for composition'
    );
  }

  for (const scheme of schemes) {
    checkSchemeType(scheme);
  }

  const layers = weavePatchSchemes(schemes, options.profileTypeKey);

  const composedScheme = structureBuilderFactory(schemeStructure, {
    includeDeleted: options.includeDeleted,
  })(layers);

  const profileTypeCache = {};

  const tree = layers.map((layer) => ({
    id: layer.id,
    version: getVersion(layer),
    variant: layer.variant,
    patch: layer.patch,
  }));

  const result = Object.assign(composedScheme, {
    get id(): string {
      return schemes[schemes.length - 1].id;
    },
    get tree() {
      return tree;
    },
    get versionTree() {
      return uniq(tree.map((item) => item.version).filter(Boolean));
    },

    get parts() {
      return [...schemes];
    },
    get isSchemeHelper(): boolean {
      return true;
    },

    get selectedProfileTypeKey(): string {
      return options.profileTypeKey || null;
    },

    // testValidity() {
    //   //return isSchemeValid(composedScheme.data, false);
    // },

    switchProfileType(profileTypeKey: string) {
      if (!profileTypeKey) {
        return this;
      }
      if (typeof profileTypeKey !== 'string') {
        throw new Error(`Must specify ProfileTypeKey as string`);
      }
      let result = profileTypeCache[profileTypeKey];
      if (!result) {
        profileTypeCache[profileTypeKey] = result = schemeHelperFactory(
          schemes,
          {
            ...options,
            profileTypeKey,
          }
        );
      }
      return result;
    },

    getUsedSectionsAndFields(): CollectionUsage {
      const collections: CollectionUsage = {};

      // canUse: not locked everywhere and not deleted.
      // inUse: enabled somewhere

      const calculateCollectionUsage = (scheme, collectionKey: string) => {
        for (const itemKey of Object.keys(scheme.data[collectionKey])) {
          const item = scheme.data[collectionKey][itemKey];

          if (scheme.selectedProfileTypeKey) {
            set(
              collections,
              [
                collectionKey,
                itemKey,
                'profileTypeSchemes',
                scheme.selectedProfileTypeKey,
              ],
              item
            );
          }

          if (item.deleted) {
            continue;
          }

          if (item.enabled) {
            set(collections, [collectionKey, itemKey, 'inUse'], true);
            set(collections, [collectionKey, itemKey, 'canUse'], true);
          } else if (!item.meta.enabled.locked) {
            set(collections, [collectionKey, itemKey, 'canUse'], true);
          }

          switch (collectionKey) {
            case 'fields': {
              // Check Ratings
              for (const ratingSystemKey of Object.keys(item.ratings)) {
                if (item.ratings[ratingSystemKey].enabled) {
                  set(
                    collections,
                    [collectionKey, itemKey, 'isRated', ratingSystemKey],
                    true
                  );
                }
              }
              break;
            }
          }
        }
      };

      const calculateUsage = (scheme: any) => {
        calculateCollectionUsage(scheme, 'sections');
        calculateCollectionUsage(scheme, 'fields');
        calculateCollectionUsage(scheme, 'profileTypes');
        calculateCollectionUsage(scheme, 'ratingSystems');
        calculateCollectionUsage(scheme, 'tags');
      };

      if (this.selectedProfileTypeKey) {
        calculateUsage(this);
      } else {
        for (const profileType of this.profileTypes) {
          let profileTypeScheme = this.switchProfileType(profileType.key);
          calculateUsage(profileTypeScheme);
        }
      }

      return collections;
    },

    calculateAllUsage(storeProfileTypeSchemes = true): CollectionUsage {
      const collections = this.getUsedSectionsAndFields();

      // TODO: Maybe create a version of the data with these adjustments
      // and then return a new schemeHelperFactory?

      for (const collectionKey of Object.keys(collections)) {
        const collection = collections[collectionKey];
        for (const itemKey of Object.keys(this.data[collectionKey])) {
          const item = this.data[collectionKey][itemKey];

          for (const key of Object.keys(collection[itemKey])) {
            const value = collection[itemKey][key];
            if (typeof value !== 'undefined') {
              item[key] = value;
            }
          }

          if (storeProfileTypeSchemes) {
            item.profileTypeSchemes = collection[itemKey].profileTypeSchemes;
          } else {
            item.profileTypeSchemes = null;
          }
        }
      }

      return collections;
    },

    addDfeAsterix() {
      // Note, this is a hangover from V1. We show an asterix for DFE fields
      // In V2.1+ I would like to show more info on the field label so potentially
      // have a component for a field label, listing what the field is
      // required for (maybe in a tooltip)
      for (const field of this.fields) {
        for (const profileType of this.profileTypes) {
          const dfeRequired = options.profileTypeKey
            ? field.ratings?.dfe?.enabled
            : field.profileTypeSchemes &&
              field.profileTypeSchemes[profileType.key]?.ratings?.dfe?.enabled;

          if (dfeRequired) {
            if (field.label && !field.label.trim().endsWith('*')) {
              field.label = `${field.label} *`;
            }
            // Stop processing this field
            break;
          }
        }
      }
    },

    applyProfileMeta(profileTypeKey: string, profileMeta: any) {
      return schemeProfileFactory(this, profileTypeKey, profileMeta);
    },

    updateDraft(data: any, patchOptions?: Options) {
      const schemesNoDraft = schemes.filter(
        (scheme) => !scheme.variant && !scheme.patch
      );
      const previousScheme = schemesNoDraft[schemesNoDraft.length - 1];
      schemesNoDraft.push({
        id: previousScheme.id,
        version: previousScheme.version,
        data,
        patch: true,
      });
      return schemeHelperFactory(schemesNoDraft, {
        ...options,
        ...patchOptions,
      });
    },
  });

  if (!options.profileTypeKey && options.calculateUsage) {
    result.calculateAllUsage(true);
  }

  if (options.skipAsterix !== true) {
    result.addDfeAsterix();
  }

  return result;
};
