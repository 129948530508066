import {
  CustomColumnSource,
  FormatSelector,
  RatingReportColumn,
  ReportColumn,
  ReportColumnGroup,
  ReportOptions,
  SchemeColumnSource,
  SelectorTypes,
} from './types';
import { Field, Scheme } from '@hogwarts/utils-schemes';

import { applyTemplate } from '../templating';
import { evaluateGroup } from '../conditionals';
import { get } from 'lodash';
import { keys } from '@hogwarts/utils';

function getSectionTypeColor(section: { color?: string }): string {
  const hex = get(section, 'color');
  return (hex && hex.substring(1)) || 'ecf0f1';
}

export const getColumnLabel = (template, { field, section }) => {
  return applyTemplate(
    template,
    {
      field: {
        label: field.label,
      },
      section: {
        label: section.label,
      },
    },
    {
      removeUnused: true,
    }
  );
};

export const applySelectors = (
  scheme: Scheme,
  field: Field,
  selectors: SelectorTypes[]
): [boolean, { locked?: boolean; formatSelectors?: FormatSelector[] }] => {
  if (!Array.isArray(selectors)) return [false, {}];

  for (const selector of selectors) {
    let { formatSelectors, locked = true } = selector;

    switch (selector.type) {
      case 'all': {
        return [
          true,
          {
            formatSelectors,
            locked,
          },
        ];
      }
      case 'validation_rule': {
        if (field.validation) {
          const validationRules = keys(field.validation);
          if (
            validationRules.find(
              (r) => r.type === 'rule' && r.rule === selector.rule
            )
          ) {
            return [
              true,
              {
                formatSelectors,
                locked,
              },
            ];
          }
        }
        break;
      }

      case 'rating': {
        let rated: boolean;
        if (scheme.selectedProfileTypeKey) {
          rated = get(field, `ratings.${selector.ratingSystemKey}.enabled`);
        } else {
          rated = get(field, `isRated.${selector.ratingSystemKey}`);
        }

        if (rated) {
          return [
            true,
            {
              formatSelectors,
              locked,
            },
          ];
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  return [false, {}];
};

const addAvailableColumnsForSchemeSource = (
  source: SchemeColumnSource,
  groups: ReportColumnGroup[],
  columns: ReportColumn[],
  scheme: Scheme
): void => {
  const isInUse = (item): boolean => {
    // This function is Duplicated from the UI code
    // applications/web/src/containers/profiles/getColumnsFromScheme.js:21
    if (scheme.selectedProfileTypeKey) {
      return item.enabled;
    }
    return item.inUse;
  };

  const ratingsColumns: ReportColumn[] = [];
  if (source.includeTags !== false) {
    ratingsColumns.push({
      key: 'tags',
      label: 'Tags',
      type: 'tag',
      width: 125,
    });
  }

  if (source.includeRatings !== false) {
    ratingsColumns.push(
      ...(scheme.ratingSystems.map((r) => ({
        key: `rating_${r.key}`,
        type: 'rating',
        label: r.label,
        systemKey: r.key,
        width: 100,
      })) as RatingReportColumn[])
    );
  }

  if (ratingsColumns.length) {
    groups.push({
      key: '__ratings',
      label: 'Ratings',
      columns: ratingsColumns,
    });
    columns.push(...ratingsColumns);
  }

  // Build a list of all known potential columns from the scheme
  for (const section of scheme.sections) {
    if (!isInUse(section)) continue;
    const groupColumns = [];
    for (const field of section.fields) {
      if (!isInUse(field)) continue;

      if (!field.features?.excelExport?.enabled) {
        continue;
      }

      switch (field.dataType) {
        case 'none':
        case 'array': {
          continue;
        }
      }
      switch (field.inputType) {
        // case 'hidden':
        case 'component': {
          continue;
        }
      }

      let newColumn: any = {
        key: field.key,
        type: 'field',
        dataType: field.dataType,
        inputType: field.inputType,
        label: field.label,
        order: field.order,
        // pinned: field.pinned,
        locked: false,
        required: !!field.ratings?.dfe?.enabled,
        meta: {
          key: field.key,
          path: `data.${field.key}`,
          field,
          section,
        },
      };

      groupColumns.push(newColumn);

      const [selectorVisible, { locked, formatSelectors }] = applySelectors(
        scheme,
        field,
        source?.selectors
      );
      newColumn.visible = selectorVisible;
      if (typeof locked !== 'undefined') {
        newColumn.locked = locked;
      }
      if (typeof formatSelectors !== 'undefined') {
        newColumn.formatSelectors = formatSelectors;
      }
    }

    if (groupColumns.length) {
      columns.push(...groupColumns);
      groups.push({
        key: section.key,
        label: section.label,
        order: section.order,
        columns: groupColumns,
        color: getSectionTypeColor(section),
      });
    }
  }

  if (source.includeProfileType !== false) {
    const group = groups.find((g) => g.key === 'profile');
    if (group) {
      const col: ReportColumn = {
        order: -1,
        key: 'typeKey',
        group: 'profile',
        label: 'Profile Type',
        type: 'profileType',
      };
      group.columns.push(col);
      columns.push(col);
    }
  }

  for (const col of columns) {
    if (!col.locked && !col.visible) {
      col.visible = false;
    }
  }
};

const addCustomColumnsForSource = (
  source: CustomColumnSource,
  groups: ReportColumnGroup[],
  columns: ReportColumn[]
): void => {
  for (const column of source?.items || []) {
    let groupKey = column.group;
    if (!groupKey) {
      groupKey = 'template_columns';
    }
    let group = groups.find((g) => g.key === groupKey);
    if (!group) {
      // If Group doesn't exist, just add a Template one
      group = {
        key: groupKey,
        label: 'Template Columns',
        order: -1,
      };
      groups.unshift(group);
    }

    const newTemplateColumn = {
      // Formatters, label, template etc
      ...column,
      key: column.key,
      type: column.type || 'template',
      group: groupKey,
    };
    if (!group.columns) {
      group.columns = [];
    }
    // @ts-ignore
    group.columns.push(newTemplateColumn);
    // @ts-ignore
    columns.push(newTemplateColumn);
  }
};

export const getAvailableColumns = (
  options: ReportOptions,
  scheme: Scheme
): [ReportColumnGroup[], ReportColumn[]] => {
  let groups: ReportColumnGroup[] = [];
  const columns: ReportColumn[] = [];
  for (let source of options.columnSources || []) {
    switch (source.type) {
      case 'scheme': {
        // Im not happy with this, the UI should really be in charge
        // of setting this value.
        // For now, I'll force it through until we can rejig the page
        if (options?.excel?.singleTab != null) {
          source = {
            ...source,
            includeProfileType: !!options.excel.singleTab,
          };
        }

        addAvailableColumnsForSchemeSource(source, groups, columns, scheme);
        break;
      }
      case 'custom': {
        addCustomColumnsForSource(source, groups, columns);
        break;
      }
      default: {
        // @ts-ignore
        throw new Error(`Unknown column source [${source.type}`);
      }
    }
  }

  if (Array.isArray(options.sort)) {
    let index = 0;
    for (const sort of options.sort) {
      const column = columns.find((c) => c.key === sort.field);
      if (column) {
        column.sort = sort.desc ? 'desc' : 'asc';
        column.sortIndex = index++;
      }
    }
  }

  let lastKnownOrder = 10001;
  for (const column of columns) {
    const colStateIndex = options.columns?.findIndex(
      (col) => col.key === column.key
    );

    if (colStateIndex < 0) {
      column.order = lastKnownOrder++;
      continue;
    }

    column.order = lastKnownOrder = colStateIndex * 100;

    const colState = options.columns[colStateIndex];

    for (const key of Object.keys(colState)) {
      if (typeof colState[key] === 'undefined') continue;

      switch (key) {
        case 'visible': {
          if (column.locked) continue;
          break;
        }
        default: {
          break;
        }
      }
      // order, width, formatselectors...
      column[key] = colState[key];
    }
  }

  for (const column of columns) {
    column.getCellStyle = (profile, value) => {
      if (!column?.formatSelectors) return null;

      for (const formatSelector of column.formatSelectors) {
        const { condition, format } = formatSelector;
        switch (column.type) {
          case 'field': {
            if (!profile.valid[column.key]) {
              value = null;
            }
            break;
          }
          default: {
            break;
          }
        }
        const result = evaluateGroup(
          condition,
          {
            field$: value,
          },
          {
            valid: {},
            values: profile.data,
            visible: profile.visible,
          },
          { timezone: 'utc' }
        );

        if (result === true) {
          return format;
        }
      }

      return null;
    };
  }

  columns.sort((a, b) => a.order - b.order);

  for (const group of groups) {
    group.columns.sort((a, b) => a.order - b.order);
  }

  groups.sort((a, b) => a.columns[0].order - b.columns[0].order);

  return [groups, columns];
};
