import { NumericInput } from '@blueprintjs/core';
import React from 'react';
import { getErrorIntent } from './utils';

const parseNumber = (value) => {
  if (value == null) return 0;
  if (value == '') return 0;
  let parsed = parseFloat(value);
  if (Number.isNaN(parsed)) {
    parsed = 0;
  }
  return parsed;
};

const NumericComponent = ({
  fieldKey,
  readOnly,
  placeHolder,
  value,
  fieldMeta,
  fieldHelpers,
  min,
  max,
}) => {
  const intent = getErrorIntent(fieldMeta);

  return (
    <NumericInput
      id={fieldKey}
      min={min}
      max={max}
      intent={intent}
      onBlur={(event) => {
        let value = event.target.value;
        if (value !== null) {
          fieldHelpers.setValue(parseNumber(value));
        }
      }}
      onValueChange={(value) => {
        fieldHelpers.setValue(parseNumber(value));
      }}
      placeholder={placeHolder}
      value={parseNumber(value)}
      fill
      disabled={readOnly}
    />
  );
};

export default NumericComponent;
