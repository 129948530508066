import React from 'react';
import { Callout, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

const CalloutComponent = ({ title, intent, message }) => {
  const { t } = useTranslation();
  return (
    <Callout
      className={'mb-3'}
      intent={intent || Intent.Warning}
      title={t(title || '')}
    >
      {t(message || '')}
    </Callout>
  );
};

export default CalloutComponent;
