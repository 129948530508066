import { Icon } from '@blueprintjs/core';
import cn from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './styles.module.css';

interface TagProps {
  label: string;
  color?: string;
  minimal?: boolean;
  large?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}
const Tag = ({
  label,
  color = '#5b717f',
  minimal,
  large,
  onClick,
  onRemove,
}: TagProps) => {
  return (
    <span
      className={cn(styles.tag, {
        [styles.selectableTag]: !!onClick,
      })}
      style={{
        backgroundColor: color + (minimal ? '75' : ''),
        color: minimal ? '#000' : '#fff',
        fontSize: large ? '1.1rem' : '0.9rem',
      }}
      onClick={onClick}
    >
      {label}
      {onRemove && (
        <Icon
          icon="cross"
          iconSize={12}
          className={styles.removeButton}
          onClick={onRemove}
        />
      )}
    </span>
  );
};

export default Tag;
