import { isObject } from '@hogwarts/utils';

const mergeObjects = (left: any, right: any, tree: string[]) => {
  let result: any = {};

  for (const key of Object.keys(left)) {
    const leftItem = left[key];

    if (!right.hasOwnProperty(key)) {
      // if it doesnt exist on the right, just use left
      result[key] = leftItem;
    } else {
      const rightItem = right[key];
      if (isObject(leftItem)) {
        if (typeof rightItem === 'undefined') {
          // Undefined will wipe it off.
          continue;
        } else if (rightItem === null) {
          result[key] = null;
        } else if (isObject(rightItem)) {
          result[key] = mergeObjects(leftItem, rightItem, [...tree, key]);
        } else if (rightItem === true || rightItem === false) {
          result[key] = rightItem;
        } else {
          // This doesn't merge a left Object with a value, expect a bool
          // This was deemed that its likely am upgrade, so the left
          // object has been upgraded.
          result[key] = leftItem;
        }
      } else if (typeof rightItem !== 'undefined') {
        result[key] = rightItem;
      }
    }
  }

  for (const key of Object.keys(right)) {
    const rightItem = right[key];
    if (!left.hasOwnProperty(key)) {
      if (typeof rightItem === 'undefined') {
        continue;
      }
      result[key] = rightItem;
    }
  }

  return result;
};

export const mergePatch = (left, right) => {
  return mergeObjects(left, right, []);
};
