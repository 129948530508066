export const parseString = (val) => {
  if (val == null) {
    return null;
  }

  if (typeof val === 'string') {
    return val;
  }

  if (val.toString) {
    return val.toString();
  }

  return `${val}`;
};
