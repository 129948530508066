import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export const useMemoizeArgs = <T,>(args: T): T => {
  const ref = useRef<T>();
  const prevArgs = ref.current;
  const argsAreEqual = isEqual(prevArgs, args);
  useEffect(() => {
    if (!argsAreEqual) {
      ref.current = args;
    }
  });
  return argsAreEqual ? prevArgs! : args;
};
