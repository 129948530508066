import React, { useMemo } from 'react';
// @ts-ignore
import blueLogo from '../../assets/logo/SICR-White-Background@2x.png';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

interface LogoProps {
  logoSrc?: string;
  logoOverrideSrc?: string;
  LogoOverride?: React.FC<any>;
  overrideProps?: any;
  className?: string;
  onClick?: () => void;
}
const Logo = ({
  logoSrc,
  LogoOverride,
  logoOverrideSrc,
  className,
  onClick,
  overrideProps,
}: LogoProps) => {
  const { t } = useTranslation();
  const LogoComponent = useMemo(() => {
    if (LogoOverride && logoOverrideSrc) {
      return (
        <LogoOverride
          className={styles.logo}
          src={logoOverrideSrc}
          noLoading
          alt={t('Home')}
          {...overrideProps}
        />
      );
    }
    return (
      <img className={styles.logo} src={logoSrc ?? blueLogo} alt={t('Home')} />
    );
  }, [LogoOverride, logoOverrideSrc, logoSrc, overrideProps]);

  return (
    <Link
      className={`d-flex flex-column align-items-start justify-content-center ${className}`}
      to="/"
      onClick={onClick}
    >
      {LogoComponent}
    </Link>
  );
};

export default Logo;
