import { Button } from '@blueprintjs/core';
import React from 'react';
import { ChromePicker } from 'react-color';
import styles from './styles2.module.css';

const ColorPicker = ({
  formField,
  fieldHelpers,
  readOnly,
  defaultColor = 'transparent',
  showReset,
}) => {
  return (
    <div className={styles.colorPickerContainer}>
      <div
        className={styles.colorPreview}
        style={{ backgroundColor: formField.value || defaultColor }}
      />
      {!readOnly && (
        <div className={styles.sliderPicker}>
          <ChromePicker
            disableAlpha
            color={formField.value ?? defaultColor}
            onChangeComplete={(color) => {
              fieldHelpers.setTouched(true, false);
              fieldHelpers.setValue(color?.hex);
            }}
          />
          {!readOnly && showReset && (
            <Button
              className={'mt-2'}
              onClick={() => {
                fieldHelpers.setTouched(true, false);
                fieldHelpers.setValue(null);
              }}
            >
              Reset Color
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default {
  name: 'Colour Picker',
  options: [],
  Component: ColorPicker,
};
