export const splitName = (name: string): [string, string?] => {
  if (typeof name !== 'string') {
    return [''];
  }
  const names = name.trim().split(' ');
  if (names.length > 1) {
    return [names.shift().trim(), names.join(' ').trim()];
  }
  return [name.trim()];
};

export const splitNameObj = (
  name: string
): { firstname: string; lastname: string } => {
  const [firstname, lastname] = splitName(name);
  return {
    firstname,
    lastname,
  };
};

export const joinNames = (firstname: string, lastname: string): string => {
  return `${firstname || ''} ${lastname || ''}`.trim();
};
