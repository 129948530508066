import React from 'react';

type CurrencyProps = {
  className?: string;
  currency: string;
  value: number;
};

export const getCurrencyString = (currency: string, value: number) => {
  switch (currency) {
    case 'GBP': {
      return `£${(value / 100).toFixed(2)}`;
    }
    default: {
      return `?${value}`;
    }
  }
};

export const Currency = ({ className, currency, value }: CurrencyProps) => {
  return (
    <span className={className}>{getCurrencyString(currency, value)}</span>
  );
};
