// TODO: these could be externally pluggable
// but for now its ok
import { get as getPath } from 'lodash';
import { DataPack } from './evaluateGroup';

export const field = (field: string, data: DataPack) => {
  if (typeof field !== 'string') {
    throw new Error('Field must be a string');
  }

  const value = data.values[field];
  const visible = data.visible[field];

  if (!visible) {
    return [false, `Field ${field} is not visible`];
  }

  // Its visible, so lets assume its just null if its undefined
  if (typeof value === 'undefined') {
    return [true, null];
  }

  return [true, value];
};

export const field2 = (field: string, data: DataPack) => {
  if (typeof field !== 'string') {
    throw new Error('Field must be a string');
  }
  const value = data.values[field];
  const visible = data.visible[field];
  if (visible === false) {
    return [false, `Field ${field} is not visible`];
  }
  if (typeof value === 'undefined') {
    return [true, null];
  }
  return [true, value];
};

export const section = (section: string, data: DataPack) => {
  return [true, !!data.visible[section]];
};

export const fieldValid = (field: string, data: DataPack) => {
  return [true, !!data.valid[field]];
};

export const get = (path: string, data: DataPack) => {
  return [true, getPath(data.values, path)];
};

export const typeKey = (__: unknown, data: DataPack) => {
  const profileTypeKey =
    data.values?.profileTypeKey ||
    data.values?.typeKey ||
    // @ts-ignore Cognita Report uses this.
    data.profileTypeKey ||
    // @ts-ignore Cognita Report uses this.
    data.typeKey;
  return [true, profileTypeKey];
};
