import log from '@scrtracker/logging';
import { veryCleanObject } from '@hogwarts/utils';
import { VARIABLE_SUFFIX } from '../utils';

export const migrateComparison = (condition) => {
  let comparison = condition.comparison;
  if (!comparison) comparison = 'istrue';
  switch (comparison) {
    case 'istrue': {
      comparison = 'isTrue';
      if (condition.then === 'hide') {
        comparison = 'isFalse';
      }
      break;
    }
    case 'isfalse': {
      comparison = 'isFalse';
      if (condition.then === 'show') {
        comparison = 'isTrue';
      }
      break;
    }
    case 'null':
      return 'isNull';
    case 'notnull':
      return 'isNotNull';
    case 'equals':
      if (condition.value === true) {
        return 'isTrue';
      }
      if (condition.value === false) {
        return 'isFalse';
      }
      return [
        'equals',
        {
          value: condition.value,
        },
      ];
    case 'notequal':
      return [
        'notEqual',
        {
          value: condition.value,
        },
      ];
    case 'contains':
      return [
        'contains',
        {
          value: condition.value,
        },
      ];
    case 'notcontains':
      return [
        'notContains',
        {
          value: condition.value,
        },
      ];
    case 'lessthan':
      return [
        'lessThan',
        {
          value: condition.value,
        },
      ];
    case 'greaterthan':
      return [
        'greaterThan',
        {
          value: condition.value,
        },
      ];
    case 'notoneof': {
      let values = condition.values || condition.value;

      if (typeof values === 'string') {
        values = values.split(',');
      }

      if (!Array.isArray(values)) {
        log.debug('Missing Values block for oneOf condition', {
          condition,
        });
        throw new Error(`Missing Values block for notOneOf condition`);
      }
      return [
        'notOneOf',
        {
          values,
        },
      ];
    }
    case 'oneof': {
      let values = condition.values || condition.value;

      if (typeof values === 'string') {
        values = values.split(',');
      }

      if (!Array.isArray(values)) {
        log.debug('Missing Values block for oneOf condition', {
          condition,
        });
        throw new Error(`Missing Values block for oneOf condition`);
      }
      return [
        'oneOf',
        {
          values,
        },
      ];
    }
    default: {
      throw new Error(`Unknown comparison [${comparison}]`);
    }
  }
  return comparison;
};

const getDaysComparison = (value) => {
  switch (Number.parseInt(value)) {
    case 28:
      return ['isDuring', { range: 'in1month' }];
    case 21:
      return ['isDuring', { range: 'in3weeks' }];
    case 14:
      return ['isDuring', { range: 'in2weeks' }];
    case 7:
      return ['isDuring', { range: 'in1week' }];
    case 3:
      return ['isDuring', { range: 'in3days' }];
    default:
      throw new Error(`Unsupported Day Range [${value}]`);
  }
};

export const migrateConditionGroup = (group) => {
  let filter = group;
  if (Array.isArray(filter)) {
    filter = {
      conditions: filter,
      operator: 'and',
      enabled: true,
    };
  }

  if (!filter || !filter.conditions || !filter.conditions.length) {
    return undefined;
  }

  let index = 0;
  let variables = {};
  let conditions = [];
  for (const condition of filter.conditions) {
    let varname = `var${index++}${VARIABLE_SUFFIX}`;
    let field;
    switch (condition.when) {
      case 'value': {
        field = condition.input;
        break;
      }
      case 'selectlocalfield': {
        field = condition.field;
        break;
      }
      case 'organisationType': {
        varname = `organisationType${VARIABLE_SUFFIX}`;
        break;
      }
      case 'profileType': {
        varname = `profileType${VARIABLE_SUFFIX}`;
        break;
      }
    }

    if (field) {
      let type = 'any';
      switch (field) {
        case 'startdate': {
          type = 'date';
          break;
        }

        case 'not_required_always_shown_1':
        case 'required_hides_1':

        case 'cognita_dbs_dbschecked':
        case 'cognita_dbs_barredlistchecked':
        case 'reference_firstcheckcomplete':
        case 'reference_secondcheckcomplete':
        case 'prohibition_checksrequired':
        case 'prohibition_checkcomplete':
        case 'section_128_checksrequired':
        case 'section_128_checkcomplete':
        case 'outside_uk_checksrequired':
        case 'outside_uk_checkcomplete': {
          type = 'boolean';
          break;
        }

        default: {
          log.warn(`Unknown field [${field}] (cant discover type)`);
          break;
        }
      }

      variables[varname] = veryCleanObject({
        type,
        source: ['field', field],
      });
    } else {
      variables[varname] = 'any';
    }

    const comparison = migrateComparison(condition);

    let compute = condition.compute;
    if (compute) {
      switch (compute) {
        case 'daysDifference': {
          const values = condition.values.split(',');

          conditions.push({
            operator: 'or',
            conditions: values.map((value) => ({
              when: varname,
              comparison: getDaysComparison(value),
            })),
          });

          break;
        }
        default: {
          throw new Error(`Unknown compute [${compute}]`);
        }
      }
    } else {
      conditions.push(
        veryCleanObject({
          when: varname,
          comparison,
        })
      );
    }
  }

  return {
    variables,
    conditions,
    operator: 'and',
    enabled: true,
  };
};
