export const emptyValue = (value?: any) => {
  if (value == null || value === '') {
    return true;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }
  return false;
};

export const emptyObject = (o?: any) => {
  if (!o) {
    return true;
  }
  const keys = Object.keys(o);
  if (keys.length === 0) {
    return true;
  }
  return (
    keys.filter((key) => {
      if (emptyValue(o[key])) {
        return false;
      }
      return true;
    }).length === 0
  );
};
