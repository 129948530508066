import { useCallback, useRef } from 'react';

type DebounceHandler<T> = (args: T) => void;

export function useDebounce<T>(
  callback: DebounceHandler<T>,
  delay: number
): DebounceHandler<T> {
  const timer = useRef<NodeJS.Timer>();
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  return useCallback(
    (args: T) => {
      clearTimeout(timer.current!);
      timer.current = setTimeout(() => {
        callbackRef.current(args);
      }, delay);
    },
    [delay]
  );
}
