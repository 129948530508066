import log from '@scrtracker/logging';

export default (registry) => {
  return (profile, params) => {
    if (!profile) {
      throw new Error('Profile not supplied');
    }
    if (!params) {
      throw new Error('Parameters not supplied');
    }

    const f = registry.get(params.function);
    // log.debug(params);
    if (!f) {
      throw new Error(`Invalid Function [${params.function}]`);
    }

    let result;
    try {
      result = f.execute(profile, params);
    } catch (e) {
      log.error('Error executing function', e);
    }
    return result;
  };
};
