import { assertArgs } from '@hogwarts/utils';
import {
  Field,
  ProfileFieldMeta,
  ProfileMeta,
  Scheme,
} from '@hogwarts/utils-schemes';
import { parseDate } from '@hogwarts/validation';
import { calculateProfileScheme, reduceProfile } from '../ratings';
import { calculateProfileTags } from '../tags';

const applyProfileMetaToScheme = (
  scheme: Scheme,
  profileTypeKey: string,
  profileMeta?: ProfileMeta
) => {
  if (!profileMeta) {
    return scheme;
  }

  if (typeof profileTypeKey !== 'string') {
    throw new Error('ProfileTypeKey must be a string');
  }

  const updatedScheme = Object.assign({}, scheme);

  const items: Field[] = [];
  for (const field of scheme.fields) {
    const fieldMetaData = profileMeta[field.key];
    if (!fieldMetaData) {
      items.push(field);
      continue;
    }

    let changed = false;
    let changes: ProfileFieldMeta = {};

    if (fieldMetaData.readOnly != null && !field.lock.readOnly) {
      changed = true;
      changes.readOnly = fieldMetaData.readOnly;
    }

    if (
      fieldMetaData.enabled != null &&
      !field.lock.enabled &&
      !field.meta.locked
    ) {
      changed = true;
      changes.enabled = fieldMetaData.enabled;
    }

    if (!changed) {
      items.push(field);
      continue;
    }

    items.push({
      ...field,
      ...changes,
    });
  }

  Object.defineProperty(updatedScheme, 'fields', {
    enumerable: true,
    writable: false,
    value: items,
  });

  const sections = [];
  for (const section of updatedScheme.sections) {
    sections.push({
      ...section,
      fields: items.filter((field) => field.section === section.key),
    });
  }

  Object.defineProperty(updatedScheme, 'sections', {
    enumerable: true,
    writable: false,
    value: sections,
  });

  return updatedScheme;
};

export const schemeProfileFactory = (
  scheme: Scheme,
  profileTypeKey: string,
  profileMeta: ProfileMeta
) => {
  assertArgs({ scheme, profileTypeKey });

  if (profileTypeKey !== scheme.selectedProfileTypeKey) {
    scheme = scheme.switchProfileType(profileTypeKey);
  }

  const composedScheme = applyProfileMetaToScheme(
    scheme,
    profileTypeKey,
    profileMeta
  );

  return Object.assign(composedScheme, {
    applyProfile(
      profile: any,
      timezone?: string,
      dateFormat?: 'string' | 'luxon' | 'date'
    ) {
      const profileActualTypeKey = profile.profileTypeKey || profile.typeKey;

      if (profileTypeKey !== profileActualTypeKey) {
        throw new Error('Profile Actual Type doesnt match scheme');
      }

      const timezone1 = timezone || 'utc';
      const data = reduceProfile(this, profile, {
        dateFormat: dateFormat || 'luxon',
        // timezone: timezone1,
      });
      const tags = calculateProfileTags(this, data, timezone1);
      const profile1 = {
        ...profile,
        meta: profile.meta || {},
        data: data || {},
        tags: tags || [],
      };
      let [profileScheme, validity, profileRating, sectionRatings] =
        calculateProfileScheme(composedScheme, profile1, timezone1);

      const appliedProfileScheme = {
        ...profileScheme,
        updateProfile: (profile: any) => {
          return this.applyProfile(profile, timezone);
        },
      };

      const queryData = { ...profile1.data };
      for (const fieldKey of Object.keys(queryData)) {
        const value = queryData[fieldKey];

        // Removes invalid dates
        if (value != null) {
          const field = scheme.getField(fieldKey);
          if (field?.dataType === 'date' && !parseDate(value)?.isValid) {
            delete queryData[fieldKey];
          }
        }

        if (validity.visible[fieldKey] === false) {
          delete queryData[fieldKey];
        }
      }

      return [
        appliedProfileScheme,
        validity,
        profileRating,
        sectionRatings,
        profile1,
        queryData,
      ];
    },
  });
};
