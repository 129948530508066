import { MultiSelect } from '@hogwarts/ui-components-core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { selectOptions } from './utils';

const BPMultiSelectComponent = ({
  formField,
  values: unparsedValues,
  fieldHelpers,
}) => {
  const { t } = useTranslation();
  let value = formField.value;
  const values = useMemo(() => selectOptions(unparsedValues), [unparsedValues]);

  return (
    <MultiSelect
      items={values ?? []}
      valueField="id"
      textField="value"
      onChange={(item) => {
        fieldHelpers.setTouched(true, false);
        fieldHelpers.setValue(item);
      }}
      placeholder={t('Select an option...')}
      selectedItems={value}
      resetOnSelect
    />
  );
};

export default {
  name: 'BP Multi-Select',
  options: [],
  label: true,
  Component: BPMultiSelectComponent,
};
