import { Apm } from './types';
import log from './';

let apmInstance: Apm | null = null;

export const enableApmLogging = (apm: Apm) => {
  log.info('APM Enabled');
  apmInstance = apm;
};

export const trackError = (error: Error) => {
  if (!apmInstance) {
    log.warn('Unable to track error, no APM instance registered');
    return;
  }
  apmInstance.trackError(error);
};
