import { useState } from 'react';
import { useIsMounted } from './useIsMounted';

type Handler = (...args: any[]) => Promise<void | any> | any;
export const useRunOnce = (
  handler?: Handler,
  allowedCheck?: () => boolean
): [Handler, boolean] => {
  const isMounted = useIsMounted();

  const [isExecuting, setIsExecuting] = useState(false);

  const handlerWrap = async (...args: unknown[]) => {
    if (typeof handler !== 'function') {
      return;
    }
    if (typeof allowedCheck === 'function' && !allowedCheck()) {
      return;
    }
    if (isExecuting) return false;
    setIsExecuting(true);
    let result;
    try {
      result = await handler(...args);
    } catch (e) {
      throw e;
    } finally {
      if (isMounted) {
        setIsExecuting(false);
      }
    }
    return result;
  };

  return [handlerWrap, isExecuting];
};
