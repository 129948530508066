import React from 'react';
import { Spinner } from '@blueprintjs/core';

import styles from './styles.module.css';

const LoadingPage = () => {
  return (
    <>
      <Spinner className={styles.loginSpinner} intent="primary" />
    </>
  );
};

export default LoadingPage;
