import React, { useMemo } from 'react';
import { MultiSelect } from '@hogwarts/ui-components-core';
import { getErrorIntent, selectOptions } from './utils';

const MultiSelectComponent = ({
  fieldKey,
  readOnly,
  fieldMeta,
  fieldHelpers,
  value,
  values: unparsedValues,
  ...rest
}) => {
  const textField = rest.textField || 'value';
  const valueField = rest.valueField || 'id';
  const values = useMemo(
    () => selectOptions(unparsedValues, valueField, textField),
    [unparsedValues]
  );
  return (
    <MultiSelect
      id={fieldKey}
      valueField={valueField}
      textField="value"
      intent={getErrorIntent(fieldMeta)}
      onChange={(items) => {
        fieldHelpers.setValue(items);
      }}
      items={values}
      disabled={readOnly}
      large={false}
      fill
      selectedItems={value}
      {...rest}
    />
  );
};

export default MultiSelectComponent;
