const MINIMUM_SIZE = 480;
const MOBILE_DEVICE_WIDTH = 980;

interface Size {
  size: number;
  minimum: number;
}
export const useDrawerSize = (suggestedSize?: Size | number): string => {
  // eslint-disable-next-line no-undef
  const width = window?.innerWidth;

  let size: number, suggestedMinimum: number | undefined;
  if (suggestedSize && typeof suggestedSize === 'object') {
    size = suggestedSize.size;
    suggestedMinimum = suggestedSize.minimum;
  } else if (typeof suggestedSize === 'number') {
    size = suggestedSize;
  } else {
    size = MINIMUM_SIZE;
  }

  if (!size) {
    size = MINIMUM_SIZE;
  } else if (size < 1) {
    size = width * size;
    if (suggestedMinimum && size < suggestedMinimum) {
      size = suggestedMinimum;
    }
  }

  if (size < MINIMUM_SIZE) {
    size = MINIMUM_SIZE;
  }

  let result: string = `${size}`;

  if (width < MOBILE_DEVICE_WIDTH || size > width) {
    result = '100%';
  }

  if (typeof size === 'number') {
    result = `${size}px`;
  }

  return result;
};
