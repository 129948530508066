import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import log from '@scrtracker/logging';
import { SentryLink } from 'apollo-link-sentry';

import getEnvironment from './getEnvironment';

function apolloClientFactory(
  getAuthorisationHeaders: () => Promise<Record<string, string> | null>,
  clientErrorHandler: (exception: any, customData: any) => void,
  clientName: string,
  clientVersion: string
) {
  const HOST_SERVER = getEnvironment().serverUri;
  const cache = new InMemoryCache({
    dataIdFromObject: (object) => {
      if (object.__typename && object.cacheKey) {
        return `${object.__typename}:${object.cacheKey}`;
      }
      if (object.__typename && object.id) {
        return `${object.__typename}:${object.id}`;
      }
      if (object.__typename && object.key) {
        return `${object.__typename}:${object.key}`;
      }
      return defaultDataIdFromObject(object);
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const e of graphQLErrors) {
        const {
          message,
          locations,
          path,
          // @ts-ignore
          extensions: { exception },
        } = e;
        log.debug(message, e);
        const customData = { message, locations, path };
        if (clientErrorHandler) {
          clientErrorHandler(exception, customData);
        }
      }
    }
    if (networkError) {
      log.debug('Network Error', networkError);
      const customData = { message: networkError.message, networkError };
      if (clientErrorHandler) {
        clientErrorHandler(networkError, customData);
      }
    }
  });

  const httpLink = new HttpLink({
    uri: `${HOST_SERVER}/api/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    const authorizationHeaders = await getAuthorisationHeaders();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...authorizationHeaders,
      },
    };
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 600,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error) => !!error,
    },
  });

  const defaultOptions = {
    // watchQuery: {
    //   fetchPolicy: 'no-cache',
    //   errorPolicy: 'ignore',
    // },
    // query: {
    //   fetchPolicy: 'no-cache',
    //   errorPolicy: 'all',
    // },
  };

  // const inflateLink = new ApolloLink((operation, forward) => {
  //   return forward(operation).map((response) => {
  //     return inflate(response);
  //   });
  // });

  const client = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: ApolloLink.from([
      retryLink,
      // https://www.npmjs.com/package/apollo-link-sentry
      new SentryLink({}),
      errorLink,
      authLink,
      httpLink,
    ]),
    cache,
    defaultOptions,
    name: clientName,
    version: clientVersion,
  });

  return client;
}

export default apolloClientFactory;
