export const DATE_FORMAT = 'yyyy-MM-dd';
export const formats = [DATE_FORMAT];

for (const join of ['/', '.', '-']) {
  for (const days of ['dd', 'd']) {
    for (const months of ['MM', 'M', 'MMM', 'MMMM']) {
      for (const years of ['yyyy', 'yy']) {
        formats.push([days, months, years].join(join));
      }
    }
  }
}

for (const join of [' ']) {
  for (const days of ['dd', 'd']) {
    for (const months of ['MMM', 'MMMM']) {
      for (const years of ['yyyy', 'yy']) {
        formats.push([days, months, years].join(join));
      }
    }
  }
}
